import React, { Component } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Service from "./../../../../Service";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import { InputSwitch } from "primereact/inputswitch";
import Authentication from "./../../../../session/index";
import { BasicLazyParams } from "./../../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import Authorize, {
    PERMISSIONS,
    isAuthorized,
} from "./../../../../session/authorize";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { logOut, warningDailogInit } from "./../../../../Utils";
import CreateAdminUser from "./createAdminUser";

import ServerlessService from "./../../../../Service/ServerlessService";
import LoadingComponent from "./../../../../LoadingComponent";
import { Card } from "primereact/card";
import { BreadCrumb } from "primereact/breadcrumb";

class AdminUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddAdminUser: false,
            appointments: [],
            lazyParams: {
                ...BasicLazyParams,
                sortField: "-createdAt",
                sortOrder: 1,
            },
            adminActive: "",
            globalSearch: "",
            isShowAdminUser: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            assignedMails: [],
            removeAssignEmail: null,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    showAdminUser = () => {
        this.setState({ isShowAddAdminUser: true, editAdminUser: false });
    };

    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {
                currentFilter: currentFilter,
                lazyParams: BasicLazyParams,
            };
        }, this.getAppointments);
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getAppointments);
    };

    onPage = (event) => {
        console.log("onpage", event)
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getAppointments);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getAppointments);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getAppointments);
    };

    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: [],
        });
        const url = `/admin/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: this.state.currentFilter }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        appointments: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    editAdminUser = (adminUser) => {
        this.setState({
            isShowAddAdminUser: true,
            editAdminUser: adminUser,
        });
    };

    deleteAdminUser = (rowData) => {
        let data = { ...rowData };

        this.setState({
            warningDialog: {
                adminActive: true,
                headerMsg: "Are you Sure?",
                message: `You want delete the adminUser, it can't be undone once deleted`,
            },
            editAdminUser: data,
        });
    };

    getUserAssignedMails = (userId) => {
        this.setState({
            isLoading: true,
            assignedMails: [],
        });
        const url = `/admin/assigned-mails/${userId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        assignedMails: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    assignEmail = (editAdminUser) => {
        this.setState(
            {
                isShowAssignEmail: true,
                editAdminUser: editAdminUser,
            },
            () => {
                this.getUserAssignedMails(editAdminUser._id);
            }
        );
    };

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true,
        });

        const url = `/admin/delete`;
        this.serverlessService
            .post(url, { _id: this.state.editAdminUser._id }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            editAdminUser: null,
                            isLoading: false,
                        },
                        () => {
                            this.getAppointments();
                        }
                    );

                    this.toast.show({
                        severity: "success",
                        summary: "Success",
                        detail: "AdminUsers deleted successfully.",
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    actionsTemplate = (rowData) => {
        return (
            <>
                <Authorize permId={PERMISSIONS.ADMIN_USER_EDIT}>
                    <span
                        data-pr-tooltip="Edit Admin User"
                        data-pr-position="bottom"
                        className={`cursor-pointer edit${rowData._id.substr(
                            2
                        )}`}
                        onClick={() => {
                            this.editAdminUser(rowData);
                        }}
                    >
                        <i className="pi pi-pencil" />
                    </span>
                    <Tooltip
                        className=""
                        autoHide={false}
                        target={`.edit${rowData._id.substr(2)}`}
                    />
                </Authorize>
            </>
        );
    };

    onSaveAdminUser = (isEdit) => {
        this.toast.show({
            severity: "success",
            summary: `${isEdit ? "Added" : "Modified"}`,
            detail: `AdminUser ${isEdit ? "Edited" : "Created"} Succesfully.`,
            life: 3000,
        });

        this.setState(
            {
                isShowAddAdminUser: false,
                lazyParams: BasicLazyParams,
            },
            () => {
                this.getAppointments();
            }
        );
    };

    componentDidMount() {
        this.getAppointments();
    }

    removeassign = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You want remove assigned  ${rowData.email} email. Please confirm`,
            },
            removeAssignEmail: rowData,
        });
    };

    onConfirmChange2 = () => {
        this.setState({
            isLoading: true,
            warningDialog2: warningDailogInit,
        });
        const url = `/admin/removeAssign-mail`;
        this.serverlessService
            .post(
                url,
                {
                    userId: this.state.editAdminUser._id,
                    emailId: this.state.removeAssignEmail._id,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        this.getUserAssignedMails(this.state.editAdminUser._id)
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    editUser = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true,
        });
        const url = `/admin/update`;
        this.serverlessService
            .post(url, this.state.editRoleData, true)
            .then((res) => {
                if (res && res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getAppointments();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
                this.setState({
                    isLoading: false,
                });
            });
    };

    changeStatus = (e, rowData) => {
        console.log(e, rowData, "usererererererererer");
        let data = { ...rowData };
        if (e.value == true) {
            data.isActive = true;
        } else {
            data.isActive = false;
        }

        this.setState({
            warningDialog: {
                adminActive: true,
                headerMsg: "Are you Sure?",
                message: `You are changing this User status ${e.value ? "InActive" : "Active"
                    } to ${e.value ? "Active" : "InActive"}`,
            },
            editRoleData: data,
        });
    };

    render() {
        console.log(this.state.appointments, "kjalkfkldjfkljfkljfkljalfjalj")
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Admin Users</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ADMIN_USER_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.state.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ADMIN_USER_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ADMIN_USER_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>

                {/* <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} /> */}
            </div>
        );


        const items = [
            { label: 'Admin' },
            { label: 'Admin' },
            { label: 'Admin Users' },

        ];

        const home = { icon: 'pi pi-home', url: 'http://localhost:3000/#/admin' }
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>

                        <BreadCrumb model={items} home={home} />

                        <Authorize permId={PERMISSIONS.ADMIN_USER_ADD}>
                            <Button
                                label="New"
                                icon="pi pi-plus"
                                className="m-3"
                                outlined
                                aria-label="Filter"
                                onClick={this.showAdminUser}
                            />
                        </Authorize>

                        <Authorize permId={PERMISSIONS.ADMIN_USER_LIST}>
                            <DataTable
                                header={header}
                                ref={(el) => (this.dt = el)}
                                scrollable
                                paginatorLeft
                                responsiveLayout="scroll"
                                rowHover
                                value={this.state.appointments}
                                dataKey="_id"
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                globalFilter={this.state.globalFilter}
                            >
                                <Column field="name" header="Name" />
                                <Column field="email" header="Email" />
                                <Column field="phoneNumber" header="Phone" />
                                <Column
                                    body={(rowData) => {
                                        return (
                                            <div className="">
                                                <Authorize
                                                    permId={
                                                        PERMISSIONS.ADMIN_USER_STATUS
                                                    }
                                                >
                                                    <InputSwitch
                                                        onChange={(e) => {
                                                            this.changeStatus(
                                                                e,
                                                                rowData
                                                            );
                                                        }}
                                                        checked={
                                                            rowData.isActive ==
                                                            true
                                                        }
                                                    />
                                                </Authorize>
                                            </div>
                                        );
                                    }}
                                    // field="isActive"
                                    header="Status"
                                />
                                <Column
                                    sortabl
                                    body={this.actionsTemplate}
                                    header="Actions"
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>
                </div>

                <Dialog
                    header={this.state.warningDialog.headerMsg}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className=""
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible}
                    style={{ maxWidth: "22vw", minWidth: "300px" }}
                    footer={() => {
                        return (
                            <div>
                                <Button
                                    label="No"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        this.setState({
                                            warningDialog: warningDailogInit,
                                            editAdminUser: null,
                                        });
                                    }}
                                />
                                <Button
                                    label="Yes"
                                    className=""
                                    onClick={this.onConfirmChange}
                                />
                            </div>
                        );
                    }}
                    onHide={() =>
                        this.setState({
                            warningDialog: warningDailogInit,
                            editAdminUser: null,
                        })
                    }
                >
                    {this.state.warningDialog.message}
                </Dialog>
                {/* 
                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className="sp-alert-box"
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog2.visible}
                    style={{ maxWidth: "22vw", minWidth: "300px" }}
                    footer={() => {
                        return (
                            <div>
                                <Button
                                    label="No"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        this.setState({
                                            warningDialog2: warningDailogInit,
                                            removeAssignEmail: null,
                                        });
                                    }}
                                />
                                <Button
                                    label="Yes"
                                    className="sp-m-lr10"
                                    onClick={this.onConfirmChange2}
                                />
                            </div>
                        );
                    }}
                    onHide={() =>
                        this.setState({
                            warningDialog2: warningDailogInit,
                            editAdminUser: null,
                        })
                    }
                >
                    <div></div>
                    <p className="sp-alert-head">
                        {this.state.warningDialog2.headerMsg}
                    </p>
                    <p className="sp-alert-msg">
                        {this.state.warningDialog2.message}
                    </p>
                </Dialog> */}

                <Dialog
                    header={<>{this.state.warningDialog.headerMsg}</>}
                    draggable={false}
                    closeOnEscape={true}
                    blockScroll={true}
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog.adminActive}
                    style={{ maxWidth: "22vw", minWidth: "300px" }}
                    footer={() => {
                        return (
                            <div>
                                <Button
                                    label="No"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        this.setState({
                                            warningDialog: warningDailogInit,
                                            editRoleData: null,
                                        });
                                    }}
                                />
                                <Button label="Yes" onClick={this.editUser} />
                            </div>
                        );
                    }}
                    onHide={() =>
                        this.setState({
                            warningDialog: warningDailogInit,
                            editRoleData: null,
                        })
                    }
                >
                    <div>
                        <span className="warning-icon"></span>
                    </div>
                    {this.state.warningDialog.message}
                </Dialog>

                {this.state.isShowAddAdminUser && (
                    <CreateAdminUser
                        adminUser={this.state.editAdminUser}
                        onHide={() => {
                            this.setState({
                                isShowAddAdminUser: false,
                            });
                        }}
                        onSave={this.onSaveAdminUser}
                    />
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});

export default connect(mapStateToProps, {})(Authentication(AdminUser));
