import React, { Component } from "react";

import ServerlessService from "../../../Service/ServerlessService";
import { Toast } from "primereact/toast";

import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";

import { TRACKER_ACTIONS } from "../../../Utils/constants";
import moment from "moment";
import BaseComponent from "../../common/BaseComponent";
import { Skeleton } from "primereact/skeleton";
import { logOut } from "../../../Utils";

class ConsultantTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [
                {
                    status: "Ordered",
                    date: "15/10/2020 10:30",
                    icon: "pi pi-shopping-cart",
                    color: "#9C27B0",
                    image: "game-controller.jpg",
                },
                {
                    status: "Processing",
                    date: "15/10/2020 14:00",
                    icon: "pi pi-cog",
                    color: "#673AB7",
                },
                {
                    status: "Shipped",
                    date: "15/10/2020 16:15",
                    icon: "pi pi-shopping-cart",
                    color: "#FF9800",
                },
                {
                    status: "Delivered",
                    date: "16/10/2020 10:00",
                    icon: "pi pi-check",
                    color: "#607D8B",
                },
            ],
            trackerEvents: [],
        };

        this.serverlessService = new ServerlessService();
    }

    customizedMarker = (item) => {
        return (
            <span
                className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
                style={{ backgroundColor: item.color }}
            >
                <i className={item.icon}></i>
            </span>
        );
    };

    customizedContent = (item) => {
        return (
            <Card title={item.status} subTitle={item.eventDate}>
                {item.image && (
                    <img
                        src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`}
                        alt={item.name}
                        width={200}
                        className="shadow-1"
                    />
                )}
                <p>{item.statusDescription}</p>
            </Card>
        );
    };

    getTrackerArray = (trackerEvents) => {
        let history = [];

        trackerEvents?.forEach((trackerEvent) => {
            switch (trackerEvent.action) {
                case TRACKER_ACTIONS.CONSULTANT_ADDED:
                    history.push({
                        status: "Consultant Entry Recorded",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription: "Consultant Entry created. ",
                        icon: "pi pi-user",
                        color: "#009900",
                    });
                    break;
                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_CONTENTWRITER:
                    history.push({
                        status: "Consultant Assigned to Content Writer",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Content Writer. Pending for Script Preperation.",
                        icon: "pi pi-user-edit",
                        color: "#009999",
                    });
                    break;

                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_DOCUMENTPREPERATOR:
                    history.push({
                        status: "Consultant Assigned to Document Preparer",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Document Preparer. Pending for Document Preparation.",
                        icon: "pi pi-book",
                        color: "#4CAF50",
                    });
                    break;
                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_DOC_VERIFIER:
                    history.push({
                        status: "Consultant Assigned to Document Verifier",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Document Verifier. Pending for Document Verification.",
                        icon: "pi pi-check-circle",
                        color: "#2196F3",
                    });
                    break;
                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_TECH_PREPERATOR:
                    history.push({
                        status: "Consultant Assigned to Tech Preparer",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Tech Preparer. Pending for Tech Preparation.",
                        icon: "pi pi-cog",
                        color: "#FFC107",
                    });
                    break;
                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_MARKETING_REVIEW:
                    history.push({
                        status: "Consultant Assigned to Pre-Market Review",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Pre-Market Review. Pending for Pre-Market Review.",
                        icon: "pi pi-globe",
                        color: "#FF5722",
                    });
                    break;
                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_SALES_HEAD:
                    history.push({
                        status: "Consultant Assigned to Sales Head",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Sales Head. Pending for Sales Head.",
                        icon: "pi pi-chart-bar",
                        color: "#E91E63",
                    });
                    break;
                case TRACKER_ACTIONS.CONSULTANT_ASSIGNED_TO_MARKETING:
                    history.push({
                        status: "Consultant Assigned to Marketing",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Marketing. Pending for Marketing.",
                        icon: "pi pi-briefcase",
                        color: "#673AB7",
                    });
                    break;

                case TRACKER_ACTIONS.CONSULTANT_CHANGED_STATUS:
                    history.push({
                        status: "Consultant Assigned to Changed Status",
                        eventDate: moment(trackerEvent.createdAt).format("LLL"),
                        statusDescription:
                            "Consultant assigned to Changed Status. Pending for status change.",
                        icon: "pi pi-refresh",
                        color: "#9E9E9E",
                    });
                    break;

                default:
                    break;
            }
        });

        return history;
    };

    getConsultantTracker = (consultant_id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/tracker/${consultant_id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        trackerEvents: this.getTrackerArray(res.res.data || []),
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    loadNext = () => {
        return (
            <div
                style={{ position: "relative", width: "100%", height: "100%" }}
                className="m-4 mb-3"
            >
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: 0,
                        transform: "translateX(-50%)",
                        width: "2px",
                        height: "98%",
                        backgroundColor: "#ddd",
                        zIndex: 1,
                    }}
                ></div>
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "0%",
                        transform: "translate(-50%, 0)",
                        zIndex: 2,
                    }}
                >
                    <Skeleton shape="circle" size="50px" />
                </div>
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "28%",
                        transform: "translate(-50%, 0)",
                        zIndex: 2,
                    }}
                >
                    <Skeleton shape="circle" size="50px" />
                </div>
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, 0)",
                        zIndex: 2,
                    }}
                >
                    <Skeleton shape="circle" size="50px" />
                </div>
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "76%",
                        transform: "translate(-50%, 0)",
                        zIndex: 2,
                    }}
                >
                    <Skeleton shape="circle" size="50px" />
                </div>


                <div
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Skeleton width="90%" height="30%" />
                    <div style={{ height: "30%" }}></div>
                    <Skeleton width="90%" height="30%" />
                    <div style={{ height: "30%" }}></div>
                </div>


                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div style={{ height: "50px" }}></div>
                    <div style={{ height: "30%" }}></div>
                    <Skeleton width="90%" height="30%" />
                    <div style={{ height: "30%" }}></div>
                    <Skeleton width="90%" height="30%" className="mb-3" />
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        if (this.props?.params?.id) {
            this.getConsultantTracker(this.props?.params.id);
        }
    };

    render() {
        const containerStyle = this.state.isLoading
            ? { width: "100%", height: "100vh" }
            : {};
        return (
            <div>
                <div style={containerStyle}>
                    {this.state.isLoading ? (
                        this.loadNext()
                    ) : (
                        <Timeline
                            value={this.state.trackerEvents}
                            align="alternate"
                            className="customized-timeline m-3 mb-3"
                            marker={this.customizedMarker}
                            content={this.customizedContent}
                        />
                    )}
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </div>
        );
    }
}

export default BaseComponent(ConsultantTracker);
