import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";

import {
    getFormFields,
    isFormValid,
    onTextChange,
    onDropDownChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import TableFields from "./addTable.json";

class AddTable extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(TableFields, this.props.editData || {});
        this.state = {
            table: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }
    addOrUpdate = () => {
        let table = trimObj(this.state.table);

        const formStatus = isFormValid(
            TableFields,
            this.formFields.formValidations,
            table
        );
        console.log(
            "formStatus.formValidations.isFormValid",
            formStatus.formValidations.isFormValid
        );
        console.log("formStatus.formValidations", formStatus.formValidations);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editData) {
                this.setState({
                    isLoading: true,
                });

                const url = `/co-space/tables`;

                this.serverlessService
                    .put(url, this.state.table, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    table: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onTableAddOrUpdate();

                                    // Show success toast
                                    this.toast.show({
                                        severity: "success",
                                        summary: "Success",
                                        detail: "Table updated successfully",
                                        life: 3000,
                                    });
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/co-space/tables/add`;
                this.serverlessService
                    .post(url, this.state.table, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    table: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onTableAddOrUpdate();
                                },
                                console.log("Table added successfully"),
                                // Show success toast
                                this.toast.show({
                                    severity: "success",
                                    summary: "Success",
                                    detail: "Table added successfully",
                                    life: 3000,
                                })
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className="p-button-text hover:bg-primary"
                onClick={this.props.onHide}
            />
            <Button
                label={this.props.editData ? "Update" : "Add"}
                onClick={this.addOrUpdate}
                autoFocus
                className="p-button-primary "
            />
        </div>
    );

    getFeeDetailsList = () => {
        this.setState({
            isLoading: true,
            feeDetailsList: [],
        });

        const url = `/co-space/feeDetails`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        feeDetailsList: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidMount() {
        this.getFeeDetailsList();
    }
    render() {
        const { table, formValidations } = this.state;
        return (
            <div>
                <Dialog
                    header={this.props.editData ? "Edit Table" : "Add Table"}
                    visible={true}
                    style={{ width: "65vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                    draggable={false}
                >
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Table Name
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        TableFields,
                                        table,
                                        formValidations,
                                        "table",
                                        "formValidations"
                                    );
                                }}
                                value={table.name}
                            />
                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["name"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Table Location
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "location",
                                        this,
                                        TableFields,
                                        table,
                                        formValidations,
                                        "table",
                                        "formValidations"
                                    );
                                }}
                                value={table.location}
                            />
                            {formValidations &&
                                !formValidations.fields["location"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["location"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Table Id
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "referenceId",
                                        this,
                                        TableFields,
                                        table,
                                        formValidations,
                                        "table",
                                        "formValidations"
                                    );
                                }}
                                value={table.referenceId}
                            />

                            {formValidations &&
                                !formValidations.fields["referenceId"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "referenceId"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Fee Type
                            </label>
                            <Dropdown
                                onChange={(e) => {
                                    onDropDownChange(
                                        e.value,
                                        "feeType",
                                        this,
                                        TableFields,
                                        table,
                                        formValidations,
                                        "table",
                                        "formValidations"
                                    );
                                }}
                                value={table.feeType}
                                options={this.state.feeDetailsList}
                                optionValue="_id"
                                optionLabel="type"
                                placeholder="Select a Fee Plan"
                                className="w-full md:w-14rem"
                            />

                            {formValidations &&
                                !formValidations.fields["feeType"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["feeType"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>
                </Dialog>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default AddTable;
