import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";

import moment from "moment";
import { BasicLazyParams } from "../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import BaseComponent from "../../common/BaseComponent";
import { getInterviews, resetLazyInterviews } from "../../../store/actions";
import { Card } from "primereact/card";
class Interviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowSendRequest: false,
            globalSearch: "",
            editData: null,
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }
    onGlobalSearch = (e) => {
        this.props.getInterviews({
            globalSearch: e.target.value,
            consultant: this.props.params.id,
        });
    };

    onPage = (event) => {
        this.props.getInterviews({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    onSort = (event) => {
        this.props.getInterviews({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getInterviews({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/interviews/tracker`
                                );
                            }}
                            data-pr-tooltip="Interview Tracker"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/interviews/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Interview View"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
            </div>
        );
    };

    componentDidMount() {
        if (this.props.params) {
            this.props.getInterviews({ consultant: this.props.params.id });
        }
    }

    previewBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                        <span
                            onClick={() => {
                                const urlToOpen = rowData.submissionProofURL;

                                window.open(urlToOpen, "_blank");
                            }}
                            data-pr-tooltip="Screenshot"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Interviews</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        return (
            <div className="h-screen overflow-auto ss-data-table">
                <Card>
                    {" "}
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={this.props.interviews}
                        lazy
                        scrollHeight="400px"
                        header={header}
                        onSort={this.onSort}
                        onPage={this.onPage}
                        onFilter={this.onFilter}
                        first={this.props.lazyParams.first}
                        last={this.props.totalRecords}
                        rows={this.props.lazyParams.rows}
                        totalRecords={this.props.totalRecords}
                        paginator
                        paginatorLeft
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        scrollable
                        responsiveLayout="scroll"
                        rowHover
                        dataKey="_id"
                        selectionMode="single"
                    >
                        <Column
                            field="interviewDate"
                            header="Interview"
                            body={(rowData) =>
                                moment(rowData.interviewFrom).format("LLL")
                            }
                        ></Column>
                        <Column
                            field="inteviewTimeFrom"
                            header="Interview To"
                            body={(rowData) =>
                                moment(rowData.interviewTo).format("LLL")
                            }
                        ></Column>
                        <Column
                            field="interviewRound"
                            header="Interview Round"
                        ></Column>
                        <Column
                            field="screemshotURL"
                            header="Preview Doc"
                            body={(rowData) => this.previewBody(rowData)}
                        ></Column>

                        <Column
                            header="Actions"
                            body={(rowData) => this.actionsBody(rowData)}
                        ></Column>
                    </DataTable>
                   
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    interviews: state?.interviews?.interviews,
    totalRecords: state?.interviews?.totalRecords,
    lazyParams: state?.interviews?.lazyParams,
    isLoading: state?.interviews?.isLoading,
});

export default BaseComponent(Interviews, mapStateToProps, {
    getInterviews,
    resetLazyInterviews,
});
