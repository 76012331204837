import React, { Component } from "react";
import { connect } from "react-redux";
import { getPermissionIdsOfGroup } from "../Utils";

export const PERMISSIONS = {
    // admin USER--start
    RESELLER: "fa41ed34-3f8b-4dd2-ab70-039f9aee713b",
    RESELLER_LIST: "13b97948-0129-45ac-830d-2023ea363589",
    RESELLER_ADD: "b626118c-f832-4e2a-ae30-ff7e85f6d0e1",
    RESELLER_EDIT: "f66e332b-13a6-4dce-9528-6f28aeb6db4b",
    RESELLER_DELETE: "4dbf1b8d-c153-4236-9cef-b6bc6e029e59",
    RESELLER_GENERAL_SEARCH: "bd179050-626c-412b-9ccb-865ac081a551",
    RESELLER_GENERAL_DOWNLOAD: "4dbf1b8d-c153-1111-1111-b6bc6e029e59",
    // admin USER--end

    // admin USER--start
    ADMIN_USER: "0761fb3e-2f31-45e5-a431-c3d2a745d1a3",
    ADMIN_USER_LIST: "24d85229-41a3-433b-84ff-ff8c4cec0f28",
    ADMIN_USER_ADD: "e6ea332b-03d8-4a2f-a3e4-470b95db2985",
    ADMIN_USER_EDIT: "a4533bbe-cff0-4757-a77a-328eefcc35be",
    ADMIN_USER_STATUS: "2456a844-c7de-4f2b-82fb-8570911ae208",
    ADMIN_USER_DELETE: "6f342d2b-0d3b-4de2-8a8c-23bcbcc144f6",
    ADMIN_USER_GENERAL_SEARCH: "234a2dd0-478f-4340-9d24-a425b56d23a8",
    ADMIN_USER_GENERAL_DOWNLOAD: "234a2dd0-478f-4340-9d24-a425b56d23a8",
    // admin USER--end

    //CONFIGURATIONS--start
    CONFIGURATIONS: "a7ee7332-791a-4fd5-b457-1226b40186b3",
    CONFIGURATIONS_GENERAL_SEARCH: "1579e448-f5a9-415b-b40b-f4957c10021f",
    CONFIGURATIONS_LIST: "7bf99732-521f-4fad-835b-db0f255f9e2b",
    CONFIGURATIONS_ADD: "1685cff3-1841-423b-b6e4-a7575da8c170",
    CONFIGURATIONS_EDIT: "75df3049-f24b-4b3d-8852-d0cbb9453676",
    CONFIGURATIONS_DELETE: "423e9c5e-34ee-497a-97f1-40404fe37d4c",
    //CONFIGURATIONS--end

    //SIGNATURES--start
    SIGNATURES: "7d934554-70c2-4658-99af-143fff1ea1a0",
    SIGNATURES_GENERAL_SEARCH: "63fbbc39-2886-407d-9a78-dd05611de7ba",
    SIGNATURES_LIST: "bb8ae6db-69e0-4c34-9dcb-a5f8a886fac7",
    SIGNATURES_ADD: "b29e50d9-b99d-413d-9b9d-ffc006c59156",
    SIGNATURES_VIEW: "5d0c24be-0359-4b97-9d13-eafd135a8448",
    SIGNATURES_EDIT: "cfe9273b-3e60-481d-978c-e3f714a16952",
    SIGNATURES_DELETE: "58120ad4-ed15-4d62-9fbd-340a60e7e747",
    //SIGNATURES--end

    //TEMPLATES--start
    TEMPLATES: "7f7086de-6c86-4855-985a-a25d0bbc5527",
    TEMPLATES_GENERAL_SEARCH: "d0c44bb1-93a3-443d-863f-8a2d66d55fb0",
    TEMPLATES_LIST: "b8c9ae81-7775-48b3-8ac8-d8e95a8374e5",
    TEMPLATES_ADD: "d373842d-b444-4e82-b329-261a3c76d1c1",
    TEMPLATES_VIEW: "662ddfe0-0c64-45bc-b65a-3a229d53d762",
    TEMPLATES_EDIT: "7cfea001-758e-4163-afdd-6765463532f3",
    TEMPLATES_DELETE: "0f364db7-bcae-4908-a621-f4e04f987c66",
    //TEMPLATES--end

    //EMAIL_USERS--start
    EMAIL_USERS: "f30eb241-f05f-4700-bde9-90c7c22d5e96",
    EMAIL_USERS_GENERAL_SEARCH: "e45ee36b-5314-4ee7-99e0-9836f01437a3",
    EMAIL_USERS_LIST: "46f8a8d5-a3c7-429c-acf8-601d187d8bc1",
    EMAIL_USERS_ADD: "95306d25-9889-43f7-948e-3844b2283fa2",
    EMAIL_USERS_VIEW: "5c8d87dc-d61c-4455-8a53-1244c837542d",
    EMAIL_USERS_EDIT: "c45b9af9-4e29-44f0-bd57-a192f8bc45d8",
    EMAIL_USERS_DELETE: "22af8256-b2e4-46d8-8017-93eca2070bc9",
    //EMAIL_USERS--end

    //BULK EMAIL_USERS--start
    BULK_EMAIL_USERS: "6288619d-c523-4b4b-aec7-291827e2179a",
    BULK_EMAIL_USERS_GENERAL_SEARCH: "d976c843-1e7b-40e9-afd3-be126291dd3f",
    BULK_EMAIL_USERS_GENERAL_DOWNLOAD: "308f906e-0711-40a9-b2d4-ef1ae2d91eb4",
    BULK_EMAIL_USERS_LIST: "4b63eb51-34c7-4355-ab63-c4a54fffbd3e",
    BULK_EMAIL_USERS_SHOW_DETAILS: "ff1e04ac-2fe6-4794-b894-08aaac98bfe2",
    BULK_EMAIL_USERS_NEW_UPLOAD: "b973856f-2964-463b-9a13-0db53e82ea9a",
    //BULK EMAIL_USERS--end

    //EMAIL_COMPAIGN--start
    EMAIL_COMPAIGN: "6fa2e1dc-b104-410e-ab7b-e471c8b89334",
    EMAIL_COMPAIGN_GENERAL_SEARCH: "b5df79b1-471b-42c2-bccd-c11e3f4bc7a3",
    EMAIL_COMPAIGN_LIST: "fd1676ee-db41-4dd7-afee-1500654caeaf",
    EMAIL_COMPAIGN_SHOW_DETAILS: "cdc358d6-01f2-4c74-95ec-d49ff987b441",
    EMAIL_COMPAIGN_CREATE_COMPAIGN: "b1efd9e5-65b8-4178-8f47-93425241deaf",
    EMAIL_COMPAIGN_REFRESH: "3ad9089f-87b0-414a-ba93-2f9fd854b95d",
    //BULK EMAIL_COMPAIGN--end

    //ROLE START
    ROLE: "c54bee0c-bf05-4739-b88c-eff7a1850008",
    ROLE_LIST: "32fc6f72-91a2-4827-a040-c02b0a32a44f",
    ROLE_ADD: "f84c3967-d1a8-461a-922b-a76df3b4fce3",
    ROLE_EDIT: "bcd084a9-f924-4d78-81b7-b122ebeeff0f",
    // ROLE_DELETE: '6f342d2b-0d3b-4de2-8a8c-23bcbcc144f6',
    ROLE_GENERAL_SEARCH: "3650ad78-86f5-4677-a6a4-11a1655f13e2",
    ROLE_GENERAL_DOWNLOAD: "bcd084a9-f924-4d78-81b7-12345beeff0f",
    //ROLE END

    //ROLE START
    USER_PRIVILEGE: "1158bb31-8912-4246-8ada-0cfa01a5b6d3",
    USER_PRIVILEGE_LIST: "12345769-5035-445c-b178-20b02c9a9bde",
    USER_PRIVILEGE_EDIT: "371311bc-0dde-47e2-83c3-11d00a07f534",
    USER_PRIVILEGE_GENERAL_SEARCH: "b2649041-1a04-4417-9250-411f01e12e47",
    USER_PRIVILEGE_GENERAL_DOWNLOAD: "9283e769-5035-445c-b178-20b02c9a9bde",
    //ROLE END

    //SMS_COMPAIGN--start
    SMS_COMPAIGN: "565478d0-9bb6-45f2-916a-fad0bd4d5139",
    SMS_COMPAIGN_GENERAL_SEARCH: "81458936-78ed-4064-93e5-aa6ff219d336",
    SMS_COMPAIGN_LIST: "78e453d7-d16b-4176-b388-6137a9d1ec18",
    SMS_COMPAIGN_SHOW_DETAILS: "c555e26a-2e1d-4c57-8831-6fc03156dde9",
    SMS_COMPAIGN_CREATE_COMPAIGN: "099b54c7-2db1-4ad2-853d-81b0739a1b93",
    SMS_COMPAIGN_REFRESH: "4f13cd8d-850c-42f2-a790-2f5b3f6fbe4f",
    //SMS_COMPAIGN--end

    //SMS_SENDERID--start
    SMS_SENDERID: "768c1e54-4276-4278-9570-393d8ec696c3",
    SMS_SENDERID_GENERAL_SEARCH: "fba35d81-88f0-44e4-a6ea-fbfeb4771eb3",
    SMS_SENDERID_LIST: "353f56f0-c359-49d1-9956-778759cdd626",
    SMS_SENDERID_CREATE: "fcc76ee9-a5a6-49cc-ac21-0955f13c48a1",
    SMS_SENDERID_EDIT: "a1fe0527-a2a4-457c-bee6-015d82567ce4",
    SMS_SENDERID_DELETE: "666b7ed5-0924-4943-9c5b-22ab810c87a9",
    //SMS_SENDERID--end

    //SMS_TEMPLATES--start
    SMS_TEMPLATES: "09fc1f19-e490-4368-9908-8bc3e3a8bd71",
    SMS_TEMPLATES_GENERAL_SEARCH: "908e78e0-fc19-40f3-a86e-d16c5d1d6190",
    SMS_TEMPLATES_LIST: "02817758-c1f0-4ba3-a730-bd7d464f816e",
    SMS_TEMPLATES_CREATE: "3b4bd95b-b63e-41a2-bd3a-3e08fe83c053",
    SMS_TEMPLATES_VIEW: "0fca8870-8cb6-4af1-a4d4-50df9655f135",
    SMS_TEMPLATES_EDIT: "3bc6d932-5f3d-4911-a4a5-a2b93ff8de7b",
    SMS_TEMPLATES_DELETE: "28cb79ab-7072-433c-8a83-66bb31199ba6",
    //SMS_TEMPLATES--end

    //SMS_API_DOC--start
    SMS_API_DOC: "6a063b26-e589-4d42-9cb3-c5cd7584159f",
    SMS_API_DOC_VIEW_API_KEY: "5d787125-95eb-45d3-961e-13b11c8b3a8b",
    SMS_API_DOC_GEN_API_KEY: "72f074b4-7b4f-4c0c-a11d-9f2b4084d482",
    //BULK SMS_API_DOC--end

    //SHORT_URL--start
    SHORT_URL: "8cef7027-288e-4ff6-b91c-5ea29334a2b5",
    SHORT_URL_GENERAL_SEARCH: "43810433-b361-4474-ad19-99b82524b167",
    SHORT_URL_LIST: "2e700d84-cc0a-4536-9120-e1188288caeb",
    SHORT_URL_CREATE: "021ef364-99de-49fe-a18d-16187b26957e",
    SHORT_URL_EDIT: "6979b6a5-bddf-45fc-bccc-080a4efc7df4",
    SHORT_URL_DELETE: "442bd9c1-b750-425c-be8f-3e7205e2621f",

    //SHORT_URL--end

    CONSULTANTS: "eb22e11d-bcb3-49eb-9c2a-2ed9673826f2",
    CONSULTANTS_GENERAL_SEARCH: "c55e55ed-4c9e-4df1-bbf1-f839b5c929a4",
    CONSULTANTS_GENERAL_DOWNLOAD: "2507599a-dd58-4db3-b7b9-2897bba7819d",
    CONSULTANTS_LIST: "0e04acf4-281f-4c98-89bf-6aa8a593958c",
    CONSULTANTS_CREATE: "d9febfa1-5a44-4358-b9bd-f4805e2dcb90",
    CONSULTANTS_EDIT: "2b8fa500-b259-463e-a324-1b2febd3c1c3",
    CONSULTANTS_DELETE: "51d041fb-bc70-4b1b-8c9d-b6bec536ace0",
    CONSULTANTS_SHOW_CONSULTANT: "d5fbe167-da87-4e8b-818b-8bdeb9d38b04",
    CONSULTANTS_MONITOR: "4e4a91d8-406b-41ad-b542-068940cd359b",
    CONSULTANTS_TRACKER: "15e3a174-4d44-442f-bd5e-101345db3603",
    CONSULTANTS_NOTES: "ba6db79f-c506-478c-a78f-694fbdc186f8",
    CONSULTANTS_ASSIGN_TO_CONTENT_WRITER:
        "dd2a8782-8362-40f0-9c9c-4cdfee15c38f",


    ALL_CONSULTANTS: "8146ab0b-bfdd-4369-8e0a-42e26877f81d",
    ALL_CONSULTANTS_GENERAL_SEARCH: "93b471aa-d6b7-41ad-a8bb-ffe462968e6d",
    ALL_CONSULTANTS_GENERAL_DOWNLOAD: "b0329dda-db05-4c0e-b70c-0792adebe40a",
    ALL_CONSULTANTS_LIST: "b0bd34ab-7344-44a3-8b38-d70c77610705",
    ALL_CONSULTANTS_CREATE: "a5abe221-beae-4b75-b703-f058be0fea66",
    ALL_CONSULTANTS_EDIT: "2e51f186-de7e-49d2-a4c2-bcd1ec017211",
    ALL_CONSULTANTS_SCREENINGCALLS: "ea40cf19-4ce8-4986-8027-92c8bd504d24",
    ALL_CONSULTANTS_INTERVIEWS: "4768ad5b-49e9-4fa3-85d1-56d078de1393",
    ALL_CONSULTANTS_SUBMISSIONS: "5a541622-7a21-43b4-a060-3eec19bc1875",
    ALL_CONSULTANTS_SHOW_CONSULTANT: "8a61a21f-900c-490f-aa95-85d5f30b7369",
    ALL_CONSULTANTS_MONITOR: "8194ef33-28c7-40cd-8231-3cdf42a76553",
    ALL_CONSULTANTS_TRACKER: "ea30830d-b954-49bd-a74e-f85b116cb9a8",
    ALL_CONSULTANTS_NOTES: "20722f5a-7406-4869-83ab-4b79c1384a64",
    ALL_CONSULTANTS_ASSIGN_TO_CONTENT_WRITER:
        "3ba4001a-632e-47d2-a549-2ca18d6fdf49",

    CONTENT_WRITER: "7c43cda2-632c-4d45-a1e5-31e9f07a90ad",
    CONTENT_WRITER_GENERAL_SEARCH: "8490599a-92ce-4e35-afd9-e0ce8095a71f",
    CONTENT_WRITER_GENERAL_DOWNLOAD: "87fd0196-5914-4073-b0ab-834bc39e0f57",
    CONTENT_WRITER_LIST: "d294a468-18fc-499d-b7a4-fec8ee57ef6e",
    CONTENT_WRITER_EDIT: "3f81b22e-6218-4b4b-a386-721662618acd",
    CONTENT_WRITER_DELETE: "51d041fb-bc70-4b1b-8c9d-b6bec536ace0",
    CONTENT_WRITER_SHOW_CONSULTANT: "d7ffff79-c859-4a7f-8d66-02dd3020dac4",
    CONTENT_WRITER_MONITOR: "ae0ca962-15dd-416f-8f1e-0368374cda39",
    CONTENT_WRITER_TRACKER: "40d04bda-bbcb-490a-9482-ac2670a306e5",
    CONTENT_WRITER_NOTES: "f30bc82c-d060-4103-9222-ef45408d77be",
    CONTENT_WRITER_EMAIL: "60118cf3-b9a9-4391-8fde-773be9d22795",
    CONTENT_WRITER_ASSIGN_TO_DOCUMENT_PREPERATION:
        "0971ec76-6ab4-495a-8630-3ba380f14303",

    DOCUMENT_PREPERATOR: "d458dc8a-649a-48a3-906f-0ac9d529ccee",
    DOCUMENT_PREPERATOR_GENERAL_SEARCH: "9d234919-6dba-4b1f-9d47-5def2da64991",
    DOCUMENT_PREPERATOR_GENERAL_DOWNLOAD:
        "9f0334d7-b371-4578-91fe-82ea86e6811f",
    DOCUMENT_PREPERATOR_LIST: "e9680293-77d3-449f-9e8a-0d0085e16f3d",
    DOCUMENT_PREPERATOR_SHOW_CONSULTANT: "0cd6d8e2-8e7a-4a41-99da-dbb669a4f91b",
    DOCUMENT_PREPERATOR_MONITOR: "f68951eb-3174-4602-a253-66e2380d144d",
    DOCUMENT_PREPERATOR_TRACKER: "d0f9e935-4f5b-4bd0-9222-ea9c4b159623",
    DOCUMENT_PREPERATOR_NOTES: "dcc080f6-193f-48f4-a692-49df5904cd6a",
    DOCUMENT_PREPERATOR_ASSIGN_TO_VERIFICATION_TEAM:
        "b011b79a-bec9-4bc4-a4d1-e1beead3c169",
    DOCUMENT_PREPERATOR_EDIT_DOC_WINDOW: "12345678-bec9-4bc4-a4d1-e1beead3c169",
    DOCUMENT_PREPERATOR_ASSIGN_PLAN: "2156ecb1-cc48-45bb-98b4-9265310cc9d1",
    DOCUMENT_PREPERATOR_USER_CREATE: "5ba78555-27f7-43ac-92fd-cf112c8509c3",

    DOCUMENT_EDITING: "cf92fbbf-2f62-4247-bda7-2631e4603bcc",
    DOCUMENT_EDITING_GENERAL_SEARCH: "98abf3a7-4631-4897-8b46-c823038dcd18",
    DOCUMENT_EDITING_GENERAL_DOWNLOAD: "08b6989f-b007-4218-b45b-db18523eaa58",
    DOCUMENT_EDITING_LIST: "2e3dffbf-56a0-4222-b6a7-dbe7b9810259",
    DOCUMENT_EDITING_SHOW_CONSULTANT: "01a1de60-8195-4d7e-9bec-2fc3fd34dc95",
    DOCUMENT_EDITING_EDIT_DOC_WINDOW: "12345678-8195-4d7e-9bec-2fc3fd34dc95",
    DOCUMENT_EDITING_PREVIEW: "c05db950-2712-4640-993d-9525405ad7f8",
    DOCUMENT_EDITING_NOTES: "847361db-a9e0-4c64-b4a1-5d225cb9cec0",

    DOCUMENT_VERIFICATION: "43c5d326-5d33-4cd3-8a85-bafed2caa925",
    DOCUMENT_VERIFICATION_GENERAL_SEARCH:
        "be061202-53fd-403e-90a4-e95694ac5250",
    DOCUMENT_VERIFICATION_GENERAL_DOWNLOAD:
        "4b13ce8b-aef5-4d97-af56-d5262cc548ef",
    DOCUMENT_VERIFICATION_LIST: "72e45579-4ca1-40c0-9bd3-a89a57b1b489",
    DOCUMENT_VERIFICATION_SHOW_CONSULTANT:
        "955f9240-43c3-4b41-bb4b-a13f191961e1",
    DOCUMENT_VERIFICATION_MONITOR: "187f7f4d-2a8c-4f82-8f0c-968790602c23",
    DOCUMENT_VERIFICATION_TRACKER: "e4e8d9cb-13cd-4ade-b2b9-361cedffd79b",
    DOCUMENT_VERIFICATION_NOTES: "1472cdfe-4b44-40f0-bc7a-650a7d1d9a17",
    DOCUMENT_VERIFICATION_ASSIGN_BACK_TO_PREPERATION_TEAM:
        "e552684a-c5d1-48a4-a7fb-62e977b3675f",
    DOCUMENT_VERIFICATION_ASSIGN_TO_TECH_PREP_TEAM:
        "cee6e198-2fe4-402d-b351-d738990739ae",

    TECH_PREPERATION: "18a09c8d-3ebb-43ec-aa19-dd2df01a93c4",
    TECH_PREPERATION_GENERAL_SEARCH: "b166c8b8-7aaf-429c-a426-69b991b6626c",
    TECH_PREPERATION_GENERAL_DOWNLOAD: "8426ebfc-b63e-4cb2-9b85-42d5de2b247c",
    TECH_PREPERATION_LIST: "5f011895-89a2-4ccc-a295-e0b97dc0de10",
    TECH_PREPERATION_SHOW_CONSULTANT: "bae36299-da61-4079-8ac3-b000bec8c9e3",
    TECH_PREPERATION_MONITOR: "939a6e74-44ef-42c8-b8c9-cf1364e7e30a",
    TECH_PREPERATION_TRACKER: "a0aba788-61f5-4fe0-93ea-90e4c7a18392",
    TECH_PREPERATION_NOTES: "962d3377-7f1d-4822-87d6-11108433d530",
    TECH_PREPERATION_ASSIGN_TO_MARKET_REVIEW_TEAM:
        "6027d8d9-3f9f-4929-90b4-d0c38946380a",

    PREMARKET: "2bdb703b-8f98-49d3-ba16-777b6dd9d9f7",
    PREMARKET_GENERAL_SEARCH: "272d9f1f-daac-4ba3-89f5-8b8710f61cdf",
    PREMARKET_GENERAL_DOWNLOAD: "b4a6c8f1-6ff2-47d2-80ff-c81fb4bbbf50",
    PREMARKET_LIST: "e7de83c3-8b3a-4434-9168-b9de95486645",
    PREMARKET_SHOW_CONSULTANT: "44ccc9fa-0954-4540-a08a-5ddcf94e5975",
    PREMARKET_MONITOR: "b0031b68-d215-47ec-9b3b-edd64b4b2a53",
    PREMARKET_TRACKER: "dcbb4dbd-a42e-45ca-92f5-c4ad1a29e3a5",
    PREMARKET_NOTES: "2472ac13-c058-4f9d-a612-bee3cd491d5f",
    PREMARKET_ASSIGN_BACK_TO_TECH_PREP_TEAM:
        "03169320-5694-40e9-b24f-6aeae8668ab5",
    PREMARKET_ASSIGN_TO_SALES_TEAM_HEAD: "f5e8ce54-652f-498d-a3e7-45d3373f9675",

    READY_MARKET: "20af8ae2-cbf8-448d-8c29-0e88d642b611",
    READY_MARKET_GENERAL_SEARCH: "b9db6d84-13e5-47ce-99a9-3a08534d16ae",
    READY_MARKET_GENERAL_DOWNLOAD: "0be1e18d-599a-4587-aba7-47fb98fce95d",
    READY_MARKET_LIST: "3c1669c2-ba5e-47e4-9858-639b15f87a57",
    READY_MARKET_SHOW_CONSULTANT: "c14461a1-a824-458c-9bd8-8f25b0583db3",
    READY_MARKET_MONITOR: "0d3d61d4-01a9-4d25-956a-47dd3ee6b6bc",
    READY_MARKET_TRACKER: "d3d43ec5-7802-49b5-91a2-0d2b95e05cec",
    READY_MARKET_NOTES: "e398168e-8042-4835-b9a2-e49ae32f3402",
    READY_MARKET_INTERVIEWS: "3cdfebd5-13b8-477b-a0f0-463e84dcbc43",
    READY_MARKET_SUBMISSIONS: "0ffa74ca-2f7c-4fa7-b5ae-d25c1b3596c7",
    READY_MARKET_ASSIGN_TO_BENCH_SALES: "1d690da9-8bf1-44bf-9190-331c57c1b4a6",
    READY_MARKET_FILTER: "7f37ce41-6051-4744-85a9-40a4ad211280",

    // SALES_HEAD: "a87dd9bd-204d-446b-9b8b-41dd0381af80",
    // SALES_HEAD_GENERAL_SEARCH: "caadcf3b-665b-4c2a-b417-7d93e7eddbbd",
    // SALES_HEAD_GENERAL_DOWNLOAD: "8391de43-7f81-4bd0-ac2f-89f22878f1a2",
    // SALES_HEAD_LIST: "48a9c46f-d04e-4159-833e-73c6070b695e",
    // SALES_HEAD_SHOW_CONSULTANT: "78dc1b9e-938e-45fd-99be-b2f455e0a12f",
    // SALES_HEAD_MONITOR: "54f65a86-2e5f-429d-8eaa-742e0443c89b",
    // SALES_HEAD_TRACKER: "9d01e5bf-566a-4b1f-9f91-5ad057a4bad4",
    // SALES_HEAD_NOTES: "5f8552c8-1e96-44a6-871b-63b286858bb5",
    // 
    // 

    MARKETING: "c50ac626-7a6c-4333-971a-1656389d20dc",
    MARKETING_GENERAL_SEARCH: "b0b505ce-d65a-478a-b3da-213931b9ac0e",
    MARKETING_GENERAL_DOWNLOAD: "eb6751a4-a59e-4adf-90e2-7ee63edbfabb",
    MARKETING_LIST: "217a6116-3847-4100-9a0b-d25ceec71d08",
    MARKETING_SHOW_CONSULTANT: "f4af91e3-5733-4dd8-b336-aab5c085f7c6",
    MARKETING_MONITOR: "55d09dbb-1a5a-4d51-8a57-c328246ab486",
    MARKETING_TRACKER: "971f2a1c-de2d-47fb-8519-2f327bcf09d6",
    MARKETING_NOTES: "aa310167-4cae-4b6b-978e-2867f7c0ec16",
    MARKETING_CHANGE_STATUS: "5de3f94e-77c4-4b56-bac9-5209bd370fb7",
    MARKETING_SUBMISSIONS: "ce09db87-c31c-47a8-8d33-34ef70a4053c",
    MARKETING_INTERVIEWS: "3d505296-17de-4027-bcb6-3658eb89b97e",
    MARKETING_COPYHELPER: "1b4aaaec-f695-430a-b236-7d02f5bc46be",

    QUESTION_BANK: "dc03d851-7bda-4ca5-bf1f-1a884dfc7de1",
    QUESTION_BANK_GENERAL_SEARCH: "f5fa7c68-9927-4e10-84aa-3409d1b1b937",
    QUESTION_BANK_GENERAL_DOWNLOAD: "d732730d-d81f-4db8-83d8-69c4089c4c78",
    QUESTION_BANK_LIST: "7d5629fb-61a0-4d7b-a07b-d0b8f5426a5a",
    // QUESTION_BANK_GLOBAL_SEARCH: "e16719f7-7b3a-431e-9277-d4f2f25ddb11",
    QUESTION_BANK_DELETE: "3fb9b39d-85af-463a-bf71-64f9191da29f",
    QUESTION_BANK_EDIT: "65ca39e0-6096-4382-8dc7-b2f02ca855a3",
    QUESTION_BANK_ADD: "97a61d65-50ff-466f-9f83-651d7dfedd21",
    QUESTION_BANK_APPROVE: "5e1bee2a-b258-4c16-939b-8060f770b2f4",
    QUESTION_BANK_REJECT: "1a614d5d-5f09-40d7-8f75-7003ab852753",


    APPROVED_QUESTION_BANK: "83b1abb7-362c-4cdb-aec8-047f8adb312b",
    APPROVED_QUESTION_BANK_GENERAL_SEARCH: "41933799-4012-48d8-a66c-5c2885146041",
    APPROVED_QUESTION_BANK_GENERAL_DOWNLOAD: "725cb298-91d3-43b5-a6c3-38c86151c9dc",
    APPROVED_QUESTION_BANK_LIST: "a0d698b7-ec36-4910-a085-6f77b69107c3",
    APPROVED_QUESTION_BANK_DELETE: "29d25e39-bc8a-4c07-a945-54e84f35f75d",

    USER_QUESTION_BANK: "b7887965-063d-4126-8afc-3cff8432852d",
    USER_QUESTION_BANK_GENERAL_SEARCH: "f40870a7-1def-4b44-8332-efff16026797",
    USER_QUESTION_BANK_GENERAL_DOWNLOAD: "0e8165c7-dc9e-47bc-8c83-df46701ab6c1",
    USER_QUESTION_BANK_LIST: "77bd9b05-f69f-4062-9151-610ec25837eb",
    USER_QUESTION_BANK_ADD: "670e113b-a9d2-4452-8635-e25d175b4155",
    USER_QUESTION_BANK_EDIT: "5893d31d-18fe-4cfc-949e-420a8ea85a46",
    USER_QUESTION_BANK_FILTER: "7ae13c66-a918-4ccd-b984-8788b0c0eac1",
    USER_QUESTION_BANK_DELETE: "a24b0a47-f511-4bdf-a3a7-4d092a902aa8",

    SCREENING_CALLS: "f63f6132-b72c-4f06-8d20-585ed7f320fc",
    SCREENING_CALLS_SEARCH: "37bca783-2a8c-429e-b962-baec445b76d9",
    SCREENING_CALLS_DOWNLOAD: "003c11f9-5c40-4dca-aa62-0fd66c52b335",
    SCREENING_CALLS_LIST: "ef2e92cd-ac83-4a39-8784-d960392f3cb7",
    SCREENING_CALLS_ADD: "2203a2bd-2452-4dcd-9993-67ec886fbe01",
    SCREENING_CALLS_EDIT: "40242a0e-5edc-4251-9ca9-6d96935ce281",
    SCREENING_CALLS_VIEW: "d4451ef2-108c-4cf8-a819-0cf3ed4a8f34",
    SCREENING_CALLS_DELETE: "91c80c5b-a9d8-41c4-ad60-a4e97a8d28b1",

    LEARNING_RESOURCES: "6cfc6569-eaae-47c2-89bf-b9798cd82cc3",
    LEARNING_RESOURCES_SEARCH: "47c1947f-e9dc-4f95-955d-40ad71261967",
    LEARNING_RESOURCES_DOWNLOAD: "74031c7b-7235-4daa-8966-03a76c77f6d8",
    LEARNING_RESOURCES_LIST: "b4eecafa-6c1d-4053-9c62-b5f2ded8bc04",
    LEARNING_RESOURCES_ADD: "de225142-8691-44d6-9d34-8a524112dc60",
    LEARNING_RESOURCES_EDIT: "f980f798-f41b-4ed1-b698-e903fb467b4e",
    LEARNING_RESOURCES_DELETE: "ced5837c-550b-4acf-8ac7-59b816883487",

    LEARNING_PLANS: "90e3b01a-0561-4632-a8c0-bef4c7e63fdd",
    LEARNING_PLANS_SEARCH: "3eeeb8bb-efad-41bf-8bb8-d3fc36548ce1",
    LEARNING_PLANS_DOWNLOAD: "c67d3bf9-4578-4d89-959e-4e409d9bd8dd",
    LEARNING_PLANS_LIST: "32f73e26-7393-4288-b68e-1acb08593102",
    LEARNING_PLANS_ADD: "224a320e-69e8-453b-88c6-c72ed2f1ccfb",
    LEARNING_PLANS_EDIT: "9bdefc15-f9b4-46a5-bf72-44176c826163",
    LEARNING_PLANS_RESOURCES: "227288f8-98e0-40f0-a29a-c40cfd5e5b16",

    FAQ: "eb5ae36c-dbba-4024-a88b-50f7554d2288",
    FAQ_SEARCH: "0a11767b-b6d3-48b3-b703-db2da112ce10",
    FAQ_DOWNLOAD: "17645194-feda-4465-afe2-dcf60882d613",
    FAQ_LIST: "874a6419-96e2-4b3d-81ad-f360ef69b9b0",
    FAQ_ADD: "6822fcbc-689c-4460-b151-4dc64aa9a20b",
    FAQ_EDIT: "66ecef32-3495-49b9-9537-5a364cacf3d9",
    FAQ_DELETE: "bcd70c57-ff82-47c6-afc8-1d3d5040a168",

    RECRUITER: "f8a5faaf-8d04-4e37-aaca-82dc5906dc39",
    RECRUITER_SEARCH: "d684cade-5680-498b-943d-3ef81f41a000",
    RECRUITER_DOWNLOAD: "69cafd51-92ae-4eb8-b045-ed9001b03822",
    RECRUITER_LIST: "6c246f31-33ec-4d30-a3c5-3b2a9a1aa4dd",

    Interviews: "39efe950-8adc-4a74-b3ee-5e26d1462f18",
    INTERVIEWS_SEARCH: "9a6da2e8-abb9-4567-8b65-b9cc0e0b2073",
    INTERVIEWS_DOWNLOAD: "9f8be1c6-749e-4005-99fa-aa6f8365537e",
    INTERVIEWS_LIST: "36ac6331-1fe1-4a6b-90fe-323429c82086",
    INTERVIEWS_ADD: "0d2bd2a0-45ed-467a-b140-a30c6ac3f83f",
    INTERVIEWS_VIEW: "53d9a949-cfec-4fd3-b3e7-62eaa12d618a",
    INTERVIEWS_TRACKER: "48fd1049-28ed-414b-86e6-8ba5ed34eb86",

    SUBMISSIONS: "910995ba-5663-46c0-966b-25c31e437cf7",
    SUBMISSIONS_SEARCH: "6409ef7d-e27d-49ea-935d-aab0ecef06da",
    SUBMISSIONS_DOWNLOAD: "54b5e4de-0746-4746-9fbb-609ec13a80d7",
    SUBMISSIONS_LIST: "97908318-8367-401d-80ba-88070681dd66",
    SUBMISSIONS_ADD: "f537f4bf-c073-4615-af51-6acbe5f3f738",
    SUBMISSIONS_VIEW: "aee01bd6-6e27-4e2f-a430-dbedc94f3b66",
    SUBMISSIONS_TRACKER: "552a62d8-f9d8-4cbf-a991-46eaeb9986e6",

    DASHBOARD: "cac0e027-fc68-43eb-90dd-fd9eb8877883",


};

const getIsBypassAuth = () => {
    return process.env.REACT_APP_BYPASS_AUTHORIZATION === "true";
};

export const PERM_GROUPS = {
    ORG: [
        PERMISSIONS.CREATE_ORGANIZATION,
        PERMISSIONS.EDIT_ORGANIZATION,
        PERMISSIONS.CHANGE_ORG_STATUS,
        PERMISSIONS.VIEW_ORGANIZATION,
    ],
};
class Authorize extends Component {
    constructor(props) {
        super(props);
    }

    isAuthorized = () => {
        if (getIsBypassAuth()) {
            return true;
        }

        if (this.props.userInfo && this.props.userInfo.isSuperAdmin) {
            return true;
        }

        if (this.props.isGroup) {
           
            let isHas = false;
            this.props.permIds?.forEach((permId) => {
                let allPerms = getPermissionIdsOfGroup(permId);
                allPerms?.forEach((p) => {
                    if (this.props.permissionIds?.includes(p)) {
                        isHas = true;
                    }
                });
            });
            return isHas;
        }
        return (
            this.props.permissionIds &&
            this.props.permissionIds.length &&
            this.props.permissionIds.includes(this.props.permId)
        );
    };

    render() {
        // console.log(this.props.permissionIds, 'perm')
        return (
            <React.Fragment>
                {(this.isAuthorized()) && (
                    <React.Fragment>{this.props.children}</React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export const isAuthorized = (permissionIds, permId, userInfo) => {
    if (getIsBypassAuth()) {
        return true;
    }

    if (userInfo && userInfo.isSuperAdmin) {
        return true;
    }

    return permissionIds.includes(permId);
};

export const isAuthorizedWithOnePermissions = (
    permissionIds,
    permIds,
    userInfo
) => {

    if (getIsBypassAuth()) {
        return true;
    }

    if (userInfo && userInfo.isSuperAdmin) {
        return true;
    }

    let isHas = false;
    permIds.forEach((permId) => {
        let allPerms = getPermissionIdsOfGroup(permId);
        allPerms?.forEach((p) => {
            if (permissionIds.includes(p)) {
                isHas = true;
            }
        });
    });
    return isHas;
};

export const isAuthorizedOne = (permissionIds, permIds) => {
    if (getIsBypassAuth()) {
        return true;
    }

    let isAuthorized = false;
    for (let index = 0; index < permIds.length; index++) {
        const permId = permIds[index];
        if (permissionIds.includes(permId)) {
            isAuthorized = true;
            break;
        }
    }

    return isAuthorized;
};

const mapStatesToProps = (state) => ({
    //  permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    permissionIds: state?.user?.user?.permissionIds,
    //userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});

export default connect(mapStatesToProps, {})(Authorize);


