import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import BaseComponent from "../../common/BaseComponent";

import {
    getFormFields,
    isFormValid,
    onTextChange,
    onNumberChange,
    onEmailChange,
    onChoiceChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";

class AddDocumentEditing extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }
    addOrUpdate = () => {
        let consultant = trimObj(this.state.consultant);

        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editData) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/consultant`;

                this.serverlessService
                    .put(url, this.state.consultant, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    consultant: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.push(
                                        "/admin/document-editings"
                                    );

                                    this.toast.show({
                                        severity: "success",
                                        summary: "Success",
                                        detail: "Table updated successfully",
                                        life: 3000,
                                    });
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/consultant/add`;
                this.serverlessService
                    .post(url, this.state.consultant, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    consultant: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onTableAddOrUpdate();
                                },
                                // Show success toast
                                this.toast.show({
                                    severity: "success",
                                    summary: "Success",
                                    detail: "Table added successfully",
                                    life: 3000,
                                })
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    getDocumentEditorById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                        },
                        () => { },
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getDocumentEditorById(this.props?.params.id);
            }
        }
    }
    render() {
        const { consultant, formValidations } = this.state;
        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-4">
                        <label className="font-bold block mb-2">Name</label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "name",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.name}
                        />
                        {formValidations &&
                            !formValidations.fields["name"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["name"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">Phone</label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "mobile",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.mobile}
                        />
                        {formValidations &&
                            !formValidations.fields["mobile"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["mobile"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">Email</label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "email",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.email}
                        />

                        {formValidations &&
                            !formValidations.fields["email"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["email"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Secondary Email
                        </label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "emailSecond",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.emailSecond}
                        />

                        {formValidations &&
                            !formValidations.fields["emailSecond"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["emailSecond"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Role Prefrered
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "rolePrefered",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.rolePrefered}
                        />

                        {formValidations &&
                            !formValidations.fields["rolePrefered"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["rolePrefered"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Technologies Prefrered
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "technologiesPrefered",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.technologiesPrefered}
                        />

                        {formValidations &&
                            !formValidations.fields["technologiesPrefered"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "technologiesPrefered"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Willing to Relocate
                        </label>
                        <Checkbox
                            inputId={"33"}
                            checked={consultant.willingToRelocate}
                            onChange={(e) => {
                                onChoiceChange(
                                    e.target.checked,
                                    "willingToRelocate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                        ></Checkbox>

                        {formValidations &&
                            !formValidations.fields["willingToRelocate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "willingToRelocate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Home Country Address
                        </label>
                        <InputTextarea
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "homeCountryAddress",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.homeCountryAddress}
                        />

                        {formValidations &&
                            !formValidations.fields["homeCountryAddress"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "homeCountryAddress"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            VISA Status
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "visaStatus",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.visaStatus}
                        />
                        {formValidations &&
                            !formValidations.fields["visaStatus"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["visaStatus"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Landline number
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "landLine",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.landLine}
                        />
                        {formValidations &&
                            !formValidations.fields["landLine"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["landLine"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Date of birth
                        </label>
                        <Calendar
                            value={
                                consultant.dateOfBirth
                                    ? new Date(consultant.dateOfBirth)
                                    : null
                            }
                            showIcon={true}
                            placeholder="Date of Birth"
                            className="w-full"
                            id="dateOfBirth"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "dateOfBirth",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields["dateOfBirth"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["dateOfBirth"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Social Security Number
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "last4digitSSN",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.last4digitSSN}
                        />
                        {formValidations &&
                            !formValidations.fields["last4digitSSN"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["last4digitSSN"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Emergency Contact Person Name
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "emergencyContactPerson",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.emergencyContactPerson}
                        />
                        {formValidations &&
                            !formValidations.fields["emergencyContactPerson"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "emergencyContactPerson"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Emergency Contact Person Name Email
                        </label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "emergencyContactPersonEmail",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.emergencyContactPersonEmail}
                        />

                        {formValidations &&
                            !formValidations.fields[
                                "emergencyContactPersonEmail"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "emergencyContactPersonEmail"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Emergency Contact Person Phone
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "emergencyContactPersonPhone",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.emergencyContactPersonPhone}
                        />
                        {formValidations &&
                            !formValidations.fields[
                                "emergencyContactPersonPhone"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "emergencyContactPersonPhone"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Date of Entered US
                        </label>
                        <Calendar
                            value={
                                consultant.usEnteredDate
                                    ? new Date(consultant.usEnteredDate)
                                    : null
                            }
                            showIcon={true}
                            placeholder="US Entered Date"
                            className="w-full"
                            id="usEnteredDate"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "usEnteredDate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields["usEnteredDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["usEnteredDate"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Current Location (City and State)
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "currentLocation",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.currentLocation}
                        />
                        {formValidations &&
                            !formValidations.fields["currentLocation"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "currentLocation"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Current Address
                        </label>
                        <InputTextarea
                            rows={3}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "currentAddress",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.currentAddress}
                        />
                        {formValidations &&
                            !formValidations.fields["currentAddress"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["currentAddress"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Relocation
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "relocation",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.relocation}
                        />
                        {formValidations &&
                            !formValidations.fields["relocation"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["relocation"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Mother Tongue
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "motherTongue",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.motherTongue}
                        />
                        {formValidations &&
                            !formValidations.fields["motherTongue"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["motherTongue"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Work Authorization Start Date
                        </label>
                        <Calendar
                            value={
                                consultant.workAuthorizationStartDate
                                    ? new Date(consultant.dateOfBirth)
                                    : null
                            }
                            showIcon={true}
                            placeholder="Start Date"
                            className="w-full"
                            id="workAuthorizationStartDate"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "workAuthorizationStartDate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields[
                                "workAuthorizationStartDate"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "workAuthorizationStartDate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Work Authorization End Date
                        </label>
                        <Calendar
                            value={
                                consultant.workAuthorizationEndDate
                                    ? new Date(
                                        consultant.workAuthorizationEndDate
                                    )
                                    : null
                            }
                            showIcon={true}
                            placeholder="End Date"
                            className="w-full"
                            id="workAuthorizationEndDate"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "workAuthorizationEndDate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields["workAuthorizationEndDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "workAuthorizationEndDate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Bachellors Degree
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "bachellorsDegree",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.bachellorsDegree}
                        />
                        {formValidations &&
                            !formValidations.fields["bachellorsDegree"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "bachellorsDegree"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Bachellors College Name
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "bachellersCollegeName",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.bachellersCollegeName}
                        />
                        {formValidations &&
                            !formValidations.fields["bachellersCollegeName"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "bachellersCollegeName"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Bachellers Completed Date
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "bachellersCompletedDate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.bachellersCompletedDate}
                        />
                        {formValidations &&
                            !formValidations.fields["bachellersCompletedDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "bachellersCompletedDate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Masters Degree
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "masterDegree",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.masterDegree}
                        />
                        {formValidations &&
                            !formValidations.fields["masterDegree"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["masterDegree"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Masters College Name
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "mastersCollegeName",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.mastersCollegeName}
                        />
                        {formValidations &&
                            !formValidations.fields["mastersCollegeName"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "mastersCollegeName"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Masters Completed Date
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "mastersCompletedDate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.mastersCompletedDate}
                        />
                        {formValidations &&
                            !formValidations.fields["mastersCompletedDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "mastersCompletedDate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Driving License
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "drivingLicenseNo",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.drivingLicenseNo}
                        />
                        {formValidations &&
                            !formValidations.fields["drivingLicenseNo"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "drivingLicenseNo"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">State ID</label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "stateId",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.stateId}
                        />
                        {formValidations &&
                            !formValidations.fields["stateId"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["stateId"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">Skype ID</label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "skypeId",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.skypeId}
                        />
                        {formValidations &&
                            !formValidations.fields["skypeId"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["skypeId"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Available For Web Camera Interview
                        </label>
                        <Checkbox
                            inputId={"33"}
                            checked={consultant.availableForWebCamInterview}
                            onChange={(e) => {
                                onChoiceChange(
                                    e.target.checked,
                                    "availableForWebCamInterview",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                        ></Checkbox>

                        {formValidations &&
                            !formValidations.fields[
                                "availableForWebCamInterview"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "availableForWebCamInterview"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Any Work Experience
                        </label>
                        <Checkbox
                            inputId={"33"}
                            checked={consultant.isHaveAnyWorkExperience}
                            onChange={(e) => {
                                onChoiceChange(
                                    e.target.checked,
                                    "isHaveAnyWorkExperience",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                        ></Checkbox>

                        {formValidations &&
                            !formValidations.fields["isHaveAnyWorkExperience"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "isHaveAnyWorkExperience"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Work Experience Title
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "workExperiencTitle",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.workExperiencTitle}
                        />
                        {formValidations &&
                            !formValidations.fields["workExperiencTitle"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "workExperiencTitle"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Work Experience Technologies
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "workExperiencTechnologies",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.workExperiencTechnologies}
                        />
                        {formValidations &&
                            !formValidations.fields["workExperiencTechnologies"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "workExperiencTechnologies"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Total Years Of Experience
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "totalYearsOfExperience",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.totalYearsOfExperience}
                        />
                        {formValidations &&
                            !formValidations.fields["totalYearsOfExperience"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "totalYearsOfExperience"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Previous working company
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "previousWorkingCompany",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.previousWorkingCompany}
                        />
                        {formValidations &&
                            !formValidations.fields["previousWorkingCompany"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "previousWorkingCompany"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Previous Work Experience Title
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "previousWorkExperiencTitle",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.previousWorkExperiencTitle}
                        />
                        {formValidations &&
                            !formValidations.fields[
                                "previousWorkExperiencTitle"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "previousWorkExperiencTitle"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Previous Work Experience Technologies
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "previousCompanyWorkExperiencTechnologies",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={
                                consultant.previousCompanyWorkExperiencTechnologies
                            }
                        />
                        {formValidations &&
                            !formValidations.fields[
                                "previousCompanyWorkExperiencTechnologies"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "previousCompanyWorkExperiencTechnologies"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Total Years Of Experience In Previous Company
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "previousCompanyYearsOfExperience",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.previousCompanyYearsOfExperience}
                        />
                        {formValidations &&
                            !formValidations.fields[
                                "previousCompanyYearsOfExperience"
                            ].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "previousCompanyYearsOfExperience"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Marketing Email
                        </label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "marketingMailId",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.marketingMailId}
                        />

                        {formValidations &&
                            !formValidations.fields["marketingMailId"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "marketingMailId"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Marketing Mail Password
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "marketingMailPassword",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.marketingMailPassword}
                        />
                        {formValidations &&
                            !formValidations.fields["marketingMailPassword"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "marketingMailPassword"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Marketing Phone Google Voice
                            <span className="sp-required">
                                *
                            </span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "marketingPhone",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.marketingPhone}
                        />
                        {formValidations &&
                            !formValidations.fields["marketingPhone"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["marketingPhone"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                </div>

                <hr />

                <div className="p-grid">
                    <div className="col-12">
                        <label className="font-bold block mb-2">
                            Vendor Script
                        </label>
                        <InputTextarea
                            rows={20}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "vendorScript",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.vendorScript}
                        />
                        {formValidations &&
                            !formValidations.fields["vendorScript"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["vendorScript"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                </div>
                <div>
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary"
                        onClick={() => {
                            this.props.history.push("/admin/document-editings");
                        }}
                    />
                    <Button
                        label={
                            this.props?.params?.operation == "edit"
                                ? "Update"
                                : "Add"
                        }
                        onClick={this.addOrUpdate}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default BaseComponent(AddDocumentEditing);
