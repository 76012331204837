import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import LoadingComponent from "../../../../LoadingComponent";

import { BasicLazyParams } from "../../../../Utils/constants";
import ServerlessService from "../../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";

import { Card } from "primereact/card";

import NavFooter from "../../../navFooter";
import {
    getScreeningCalls,
    resetLazyScreeningCalls,
    deleteScreeningCalls,
} from "../../../../store/actions";
import BaseComponent from "../../../common/BaseComponent";
import { logOut } from "../../../../Utils";
class ScreeningCalls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            consultantsArray: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "-createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            consultantId: "",
        };
        this.serverlessService = new ServerlessService();
    }

    getScreeningCallsByConsultantId = (id) => {
        const urlEnd = `/consultants/screeningcalls/consultantid/${id}`;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        this.serverlessService
            .get(urlEnd, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    const consultantsIds = res.res.data.map(
                        (consultant) => consultant.consultant
                    );
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });

                    consultantsIds.forEach((id) => {
                        this.getConsultantById(id);
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getConsultantById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    const consultantName = res.res.data.name;
                    this.setState(
                        (prevState) => ({
                            consultants: prevState.consultants.map(
                                (consultant) => {
                                    if (consultant.consultant === id) {
                                        return {
                                            ...consultant,
                                            consultantname: consultantName,
                                        };
                                    } else {
                                        return consultant;
                                    }
                                }
                            ),
                            isLoading: false,
                        }),
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props.params.id) {
            this.getScreeningCallsByConsultantId(this.props.params.id);
        } else {
            this.props.getScreeningCalls();
        }
    }
    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/screeningcalls");
        }
    }
    onGlobalSearch = (e) => {
        this.props.getScreeningCalls({
            globalSearch: e.target.value,
        });
    };

    onPage = (event) => {
        this.props.getScreeningCalls({
            lazyParams: event,
        });
    };

    onSort = (event) => {
        this.props.getScreeningCalls({
            lazyParams: event,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getScreeningCalls({
            lazyParams: event,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2">
                    <span
                        onClick={() => {
                            this.props.history.push(
                                `/screeningcall/view/${rowData._id}`
                            );
                        }}
                        data-pr-tooltip="Show Screening Call"
                        data-pr-position="top"
                        className={`p-text abc${rowData._id}`}
                    >
                        <i className="pi pi-eye" />
                    </span>
                    <Tooltip target={`.abc${rowData._id}`} />
                </div>
            </div>
        );
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    benchSale = (rowData) => {
        if (
            rowData &&
            rowData.consultantId &&
            rowData.consultantId.marketingAssignedTo &&
            rowData.consultantId.marketingAssignedTo.name
        ) {
            return rowData.consultantId.marketingAssignedTo.name;
        } else {
            return "N/A";
        }
    };

    render() {
        const { location } = this.props;
        const shouldRenderButton = location.pathname === "/screeningcalls";
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                Screening calls
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                        {this.state.showSearch && (
                                            <InputText
                                                placeholder="Search"
                                                className=""
                                                value={this.props.globalSearch}
                                                onChange={this.onGlobalSearch}
                                            />
                                        )}
                                    </li>

                                    <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                        <i
                                            data-pr-tooltip="Search"
                                            data-pr-position="bottom"
                                            className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                            onClick={(e) => {
                                                this.setState({
                                                    showSearch:
                                                        !this.state.showSearch,
                                                });
                                            }}
                                        ></i>
                                    </li>
                                    <Tooltip
                                        className="table-li-tooltip"
                                        autoHide={false}
                                        target=".ss-tbl-srch"
                                    />

                                    <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                        <i
                                            data-pr-tooltip="Download"
                                            data-pr-position="bottom"
                                            className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                            onClick={this.exportCSV}
                                        ></i>
                                    </li>
                                    <Tooltip
                                        className="table-li-tooltip"
                                        target=".ss-tbl-dwnld"
                                    />
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        return (
            <>
                <NavFooter>
                    <div className="h-screen overflow-auto ss-data-table">
                        <div className="font-bold block mb-2">
                            <h1>Screening Calls</h1>
                        </div>
                        <Card>
                            <Button
                                label="New"
                                icon="pi pi-plus"
                                className="m-3"
                                outlined
                                aria-label="Create"
                                onClick={() => {
                                    this.props.history.push(
                                        `/screeningcall/add`
                                    );
                                }}
                            />

                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.screeningCalls}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({
                                        selectedCustomer: e.value,
                                    })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="createdBy.name"
                                    header="Consultant Name"
                                ></Column>
                                <Column
                                    field="technology"
                                    header="Technology"
                                ></Column>

                                <Column
                                    field="consultantId.marketingAssignedTo.name"
                                    header="BenchSale Recruiter"
                                    body={(rowData) => this.benchSale(rowData)}
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Card>
                    </div>

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </NavFooter>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    screeningCalls: state?.screeningCalls?.screeningCalls,
    totalRecords: state?.screeningCalls?.totalRecords,
    lazyParams: state?.screeningCalls?.lazyParams,
    isLoading: state?.screeningCalls?.isLoading,
    consultantAddedOrUpdated: state?.screeningCalls?.consultantAddedOrUpdated,
});
export default BaseComponent(ScreeningCalls, mapStateToProps, {
    getScreeningCalls,
    resetLazyScreeningCalls,
    deleteScreeningCalls,
});
