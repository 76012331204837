import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut, trimObj } from "../../../Utils";

import {
    getFormFields,
    isFormValid,
    onEmailChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
import ContactFields from "./contact.json";
import BaseComponent from "../../common/BaseComponent";
import Required from "../../common/required";

class AddContact extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ContactFields,
            this.props.editContact || {}
        );
        this.state = {
            contact: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let contact = trimObj(this.state.contact);

        const formStatus = isFormValid(
            ContactFields,
            this.formFields.formValidations,
            contact
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        // debugger
        if (isCompleteFormValid) {
            if (this.props.params.id) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/contact/update`;
                this.serverlessService
                    .put(url, contact, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    contact: {

                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.goBack();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/contact/add`;
                this.serverlessService
                    .post(url, contact, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.goBack();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    getFaq = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/contact/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        contact: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showAssign = () => {
        this.setState({
            isShowAssign: true,
            data: this.state.contact,
        });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getFaq(this.props?.params.id);
            }
        }
    }

    render() {
        const { contact, formValidations } = this.state;

        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="font-bold block mb-2">
                    <h1>FAQ</h1>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-12">
                        <label className="font-bold block mb-2">Name
                            <Required />
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "name",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.name}
                        />
                        {formValidations &&
                            !formValidations.fields["name"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["name"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-12">
                        <label className="font-bold block mb-2">Designation
                            <Required />
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "designation",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.designation}
                        />
                        {formValidations &&
                            !formValidations.fields["designation"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["designation"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-12">
                        <label className="font-bold block mb-2">Phone Number
                            <Required />
                        </label>
                        <InputText

                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "phoneNumber",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.phoneNumber}
                        />
                        {formValidations &&
                            !formValidations.fields["phoneNumber"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["phoneNumber"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Secondary Phone</label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "secondaryPhone",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.secondaryPhone}
                        />
                        {formValidations &&
                            !formValidations.fields["secondaryPhone"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["secondaryPhone"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Department
                            <Required />
                        </label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "department",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.department}
                        />
                        {formValidations &&
                            !formValidations.fields["department"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["department"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Sub Department</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "subDepartment",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.subDepartment}
                        />
                        {formValidations &&
                            !formValidations.fields["subDepartment"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["subDepartment"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Email</label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "email",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.email}
                        />
                        {formValidations &&
                            !formValidations.fields["email"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["email"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-12">
                        <label className="font-bold block mb-2">Family Details</label>
                        <InputTextarea
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "familyDetails",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.familyDetails}
                        />
                        {formValidations &&
                            !formValidations.fields["familyDetails"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["familyDetails"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Date Of Birth</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "dateOfBirth",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.dateOfBirth}
                        />
                        {formValidations &&
                            !formValidations.fields["dateOfBirth"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["dateOfBirth"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Age</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "age",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.age}
                        />
                        {formValidations &&
                            !formValidations.fields["age"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["age"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Caste</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "caste",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.caste}
                        />
                        {formValidations &&
                            !formValidations.fields["caste"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["caste"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Marital Status</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "maritalStatus",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.maritalStatus}
                        />
                        {formValidations &&
                            !formValidations.fields["maritalStatus"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["maritalStatus"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Reference Link</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "referenceLink",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.referenceLink}
                        />
                        {formValidations &&
                            !formValidations.fields["referenceLink"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["referenceLink"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Address</label>
                        <InputTextarea
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "address",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.address}
                        />
                        {formValidations &&
                            !formValidations.fields["address"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["address"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Father Name</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "fatherName",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.fatherName}
                        />
                        {formValidations &&
                            !formValidations.fields["fatherName"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["fatherName"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Mother Name</label>
                        <InputText
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "motherName",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.motherName}
                        />
                        {formValidations &&
                            !formValidations.fields["motherName"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["motherName"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Notes</label>
                        <InputTextarea
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "notes",
                                    this,
                                    ContactFields,
                                    contact,
                                    formValidations,
                                    "contact",
                                    "formValidations"
                                );
                            }}
                            value={contact.notes}
                        />
                        {formValidations &&
                            !formValidations.fields["notes"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["notes"].errorMsg}
                                </p>
                            )}
                    </div>
                </div>
                <div className="m-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <Button
                        label={
                            this.props?.params?.operation == "edit"
                                ? "Update"
                                : "Add"
                        }
                        onClick={this.addOrEdit}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default BaseComponent(AddContact);
