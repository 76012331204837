import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';

import { logOut, removeNullUndefinedKeys } from '../../Utils';


const dispatchRequestComment = data => ({
    type: ACTIONS.REQUEST_CONSULTANT_MONITOR,
    data
});

const dispatchReceivedComment = (data) => ({
    type: ACTIONS.RECEIVED_CONSULTANT_MONITOR,
    data
});

const dispatchErrorComment = err => ({
    type: ACTIONS.ERROR_CONSULTANT_MONITOR,
    err
});

const dispatchClearComment = () => ({
    type: ACTIONS.CLEAR_CONSULTANT_MONITOR
});

const dispatchAddComment = data => ({
    type: ACTIONS.REQUEST_ADD_COMMENT,
    data
});

const dispatchReceivedAddComment = (data) => ({
    type: ACTIONS.RECEIVED_ADD_COMMENT,
    data
});

const dispatchErrorAddComment = err => ({
    type: ACTIONS.ERROR_ADD_COMMENT,
    err
});



export const getComments = (_id, data) => (dispatch, getState) => {
    let _data;
    // debugger
    const currentState = getState().comments;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    const { lazyParams } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")

    dispatch(dispatchRequestComment({ ...data, _id }));

    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/comments/${_id}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        _data = res.data
        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedComment(res.data));
        } else {
            dispatch(dispatchErrorComment({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorComment(e));
    });
}


export const addComment = (data) => (dispatch) => {

    dispatch(dispatchAddComment(data));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/comments/add`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data
    }).then((res) => {
        // debugger

        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedAddComment(res.data?.data));
        } else {
            dispatch(dispatchErrorAddComment({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorAddComment(e));
    });
}




export const clearComments = () => dispatchClearComment();