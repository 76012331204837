import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { CodeGuruReviewer } from 'aws-sdk';
import { logOut, removeNullUndefinedKeys } from '../../Utils';


const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}




const dispatchRequestCopyhelperData = data => ({
    type: ACTIONS.REQUEST_COPYHELPER,
    data
}

);

const dispatchReceivedCopyhelperData = (data) => ({
    type: ACTIONS.RECEIVED_COPYHELPER,
    data
}
);

const dispatchErrorCopyhelperData = err => ({
    type: ACTIONS.ERROR_COPYHELPER,
    err
});

const dispatchClearCopyhelperrData = () => ({
    type: ACTIONS.CLEAR_COPYHELPER
});

const dispatchReceivedAddCopyhelperrData = (data) => ({
    type: ACTIONS.RECEIVED_ADD_COPYHELPER,
    data,
});

const dispatchReceivedUpdateCopyhelperrData = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_COPYHELPER,
    data,
});
const dispatchReceivedDeleteCopyhelperrData = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_COPYHELPER,
    data,
});

const dispatchErrorAddCopyhelperrData = (err) => ({
    type: ACTIONS.ERROR_ADD_COPYHELPER,
    err,
});

export const addCopyHelperData = (data) => (dispatch) => {
    // dispatch(dispatchAddConsultants(data));
    axios({
        method: data?._id ? "put" : "post",
        url: `${SERVERLESS_URL}/consultants/copy-helper${data?._id ? "/update" : "/add"
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data,
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {
                if (data?._id) {
                    dispatch(dispatchReceivedUpdateCopyhelperrData(res.data?.data));
                } else {
                    dispatch(dispatchReceivedAddCopyhelperrData(res.data?.data));
                }
            } else {
                dispatch(
                    dispatchErrorAddCopyhelperrData({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorAddCopyhelperrData(e));
        });
};

export const deleteCopyhelper = (data) => (dispatch) => {
    axios({
        method: "delete",
        url: `${SERVERLESS_URL}/consultants/copy-helper/${data._id}`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {

                dispatch(dispatchReceivedDeleteCopyhelperrData(data));
            } else {
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
        });
};


export const getCopyHelper = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().copyhelper;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")
    dispatch(dispatchRequestCopyhelperData(data));

    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/copy-helper?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: data
    }).then((res) => {
        _data = res.data
        console.log(res.data, "contentwriter action")
        if (res && res.data) {
            dispatch(dispatchReceivedCopyhelperData(res.data));
        } else {
            dispatch(dispatchErrorCopyhelperData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorCopyhelperData(e));
    });
}

export const resetLazyCopyHelper = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestCopyhelperData(intialTableFilter));
    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/copy-helper?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data

        if (res && res.data) {
            dispatch(dispatchReceivedCopyhelperData(res.data));
        } else {
            dispatch(dispatchErrorCopyhelperData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorCopyhelperData(e));
    });
}


export const clearConsltants = () => dispatchClearCopyhelperrData();