import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from './../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { logOut, removeNullUndefinedKeys } from '../../Utils';

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}



const dispatchRequestLearningPlansData = data => ({
    type: ACTIONS.REQUEST_LEARNING_PLANS,
    data
});

const dispatchReceivedLearningPlansData = (data) => ({
    type: ACTIONS.RECEIVED_LEARNING_PLANS,
    data
});

const dispatchErrorLearningPlansData = err => ({
    type: ACTIONS.ERROR_LEARNING_PLANS,
    err
});

const dispatchClearLearningPlansData = () => ({
    type: ACTIONS.CLEAR_ORG_LEARNING_PLANS
});


const dispatchAddLearningPlansData = (data) => ({
    type: ACTIONS.REQUEST_ADD_LEARNING_PLANS,
    data,
});

const dispatchReceivedAddLearningPlansData = (data) => ({
    type: ACTIONS.RECEIVED_ADD_LEARNING_PLANS,
    data,
});

const dispatchReceivedUpdateLearningPlansData = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_LEARNING_PLANS,
    data,
});
const dispatchReceivedDeleteLearningPlansData = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_LEARNING_PLANS,
    data,
});

const dispatchErrorAddLearningPlansData = (err) => ({
    type: ACTIONS.ERROR_ADD_LEARNING_PLANS,
    err,
});

export const addorupdate = (data) => (dispatch) => {
    dispatch(dispatchAddLearningPlansData(data));
    axios({
        method: data?._id ? "put" : "post",
        url: `${SERVERLESS_URL}/consultants/learning-plans${data?._id ? "" : "/add"
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data,
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {
                if (data?._id) {
                    dispatch(dispatchReceivedUpdateLearningPlansData(res.data?.data));
                } else {
                    dispatch(dispatchReceivedAddLearningPlansData(res.data?.data));
                }
            } else {
                dispatch(
                    dispatchErrorAddLearningPlansData({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorAddLearningPlansData(e));
        });
};
export const getLearningPlans = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().learningPlans;
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    dispatch(dispatchRequestLearningPlansData(data));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/learning-plans?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: {}
    }).then((res) => {
        _data = res.data

        if (res && res.data) {

            dispatch(dispatchReceivedLearningPlansData(res.data));
        } else {
            dispatch(dispatchErrorLearningPlansData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorLearningPlansData(e));
    });
}

export const resetLazyLearningPlans = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestLearningPlansData(intialTableFilter));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/learning-plans?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedLearningPlansData(res.data));
        } else {
            dispatch(dispatchErrorLearningPlansData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorLearningPlansData(e));
    });
}

export const clearLearningPlans = () => dispatchClearLearningPlansData();

