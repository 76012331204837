import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import SubscriptionFields from "./subscription.json";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onDropDownChange,
    onNumberChange,
    onTextChange,
    trimAll,
} from "../../../Utils/formHelper";
import { Button } from "primereact/button";
import { logOut, trimObj } from "../../../Utils";
import { Dropdown } from "primereact/dropdown";
import { typeOptions } from "../../../Utils/constants";

class AddSubscription extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            SubscriptionFields,
            this.props?.editSubscription || {}
        );
        this.state = {
            subscription: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    footerContent = () => {
        return (
            <>
                <Button label="Cancel" onClick={this.props.onHide}></Button>
                <Button
                    label={this.props.editSubscription ? "Update" : "Save"}
                    onClick={() => this.addOrUpdate()}
                ></Button>
            </>
        );
    };

    addOrUpdate = () => {
        let subscription = trimObj(this.state.subscription);

        const formStatus = isFormValid(
            SubscriptionFields,
            this.formFields.formValidations,
            subscription
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editSubscription) {
                this.setState({
                    isLoading: true,
                });

                const url = `/subscription/update`;

                this.serverlessService
                    .put(url, this.state.subscription, true)
                    .then((res) => {

                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    subscription: {
                                        name: "",
                                        id: "",
                                        days: "",
                                        price: "",
                                        type: "",
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.onSubscriptionAddandUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/subscription/save`;
                this.serverlessService
                    .post(url, this.state.subscription, true)
                    .then((res) => {

                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    subscription: {
                                        name: "",
                                        id: "",
                                        days: "",
                                        price: "",
                                        type: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onSubscriptionAddandUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };
    render() {
        const { subscription, formValidations } = this.state;
        console.log(this.props, "props");
        console.log(this.state.formFields, "formfields");

        return (
            <div>
                <Dialog
                    header={
                        this.props.editSubscription
                            ? "Edit Subscription"
                            : "Add Subscription"
                    }
                    visible={true}
                    style={{ width: "49vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                >
                    <label className="font-bold block mb-2">Type</label>
                    <Dropdown
                        onChange={(e) => {
                            onDropDownChange(
                                e.value,
                                "type",
                                this,
                                SubscriptionFields,
                                subscription,
                                formValidations,
                                "subscription",
                                "formValidations"
                            );
                        }}
                        placeholder="Select type"
                        value={subscription.type}
                        options={typeOptions}
                        optionValue="id"
                        optionLabel="type"
                    />

                    {formValidations &&
                        !formValidations.fields["type"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["type"].errorMsg}
                            </p>
                        )}
                    <br />

                    <label className="font-bold block mb-2">Name</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "packageName",
                                this,
                                SubscriptionFields,
                                subscription,
                                formValidations,
                                "subscription",
                                "formValidations"
                            );
                        }}
                        value={subscription.packageName}
                    />
                    {formValidations &&
                        !formValidations.fields["packageName"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["packageName"].errorMsg}
                            </p>
                        )}
                    <br />
                    <label className="font-bold block mb-2">Id</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "id",
                                this,
                                SubscriptionFields,
                                subscription,
                                formValidations,
                                "subscription",
                                "formValidations"
                            );
                        }}
                        value={subscription.id}
                    />
                    {formValidations &&
                        !formValidations.fields["id"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["id"].errorMsg}
                            </p>
                        )}
                    <br />

                    <label className="font-bold block mb-2">Price</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "price",
                                this,
                                SubscriptionFields,
                                subscription,
                                formValidations,
                                "subscription",
                                "formValidations"
                            );
                        }}
                        value={subscription.price}
                    />

                    {formValidations &&
                        !formValidations.fields["price"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["price"].errorMsg}
                            </p>
                        )}
                    <br />

                    <label className="font-bold block mb-2">Credits</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "credits",
                                this,
                                SubscriptionFields,
                                subscription,
                                formValidations,
                                "subscription",
                                "formValidations"
                            );
                        }}
                        value={subscription.credits}
                    />

                    {formValidations &&
                        !formValidations.fields["credits"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["credits"].errorMsg}
                            </p>
                        )}
                    <br />

                    {subscription.type == "Subscription" ? (
                        <>
                            <label className="font-bold block mb-2">Days</label>
                            <InputText
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "days",
                                        this,
                                        SubscriptionFields,
                                        subscription,
                                        formValidations,
                                        "subscription",
                                        "formValidations"
                                    );
                                }}
                                value={subscription.days}
                            />
                            :
                            {formValidations &&
                                !formValidations.fields["days"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["days"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </>
                    ) : (
                        <></>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default AddSubscription;
