import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import BasicDetails from "../../mProfiles/common/basicDetails";
import ContactDetails from "../../mProfiles/common/contactDetails";
import { logOut } from "../../../Utils";

class ProfileDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
        };

        this.serverlessService = new ServerlessService();
    }

    getCompleteProfileById = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/mprofiles/byid?id=${this.props.profileId}`;

        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    console.log(res.res.data, "get Profiles data");


                    this.setState({
                        isLoading: false,
                        profile: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onApprove = () => {


        this.setState({
            isLoading: true,
        });
        const url = `/mprofiles/update`;

        if (this.props.profile?.verified) {
            this.serverlessService
                .post(url, { _id: this.state.profile._id, verified: false }, true)
                .then((res) => {

                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.props.onSuccessfullyApproved()
                        });

                        console.log("updated");
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400) {
                        logOut()
                    }
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });

        } else {
            this.serverlessService
                .post(url, { _id: this.state.profile._id, verified: true }, true)
                .then((res) => {

                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.props.onSuccessfullyApproved()
                        });

                        console.log("updated");
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400) {
                        logOut()
                    }
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });

        }


    };

    componentDidMount() {
        this.getCompleteProfileById();
    }

    render() {
        const { profile } = this.state;
        console.log(this.props, "aaaaaaaaaaaaaaaaaaa");

        return (
            <>
                <Dialog
                    header="Profile Details"
                    visible={true}
                    style={{ width: "90%" }}
                    onHide={this.props.onHide}
                >
                    {profile && (
                        <>
                            <BasicDetails profile={profile}></BasicDetails>
                            <h3 className=" ">Contact Details</h3>
                            <ContactDetails profile={profile} /> <br />
                            <Button
                                label={this.props.profile?.verified ? "Inactive" : "Approve"}

                                onClick={() => this.onApprove()}
                            ></Button>
                        </>
                    )}
                </Dialog>

                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

export default ProfileDetails;
