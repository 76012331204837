import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import ServerlessService from "../../../Service/ServerlessService";
import AddUser from "./addUser";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from "../../../Utils";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            isShowAdd: false,
            editUser: null,
            isAddCredits: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }

    onUserAddAndUpdate = () => {
        this.setState({
            isShowAdd: false,
            editUser: null,
        });
        this.getUsers();
    };

    onHide = () => {
        this.setState({
            isShowAdd: false,
            editUser: null,
            isAddCredits: false,
        });
    };

    getUsers = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
        });

        const url = `/user?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    console.log(res.res.data, "get user data");


                    this.setState({
                        isLoading: false,
                        user: res.res.data,
                        totalUsers: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    addCredits = () => {
        const url = `/user/credits`;

        this.serverlessService
            .post(url, this.state.newuser, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isAddCredits: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getUsers);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getUsers);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getUsers);
    };



    componentDidMount() {
        this.getUsers();
    }

    render() {
        console.log(this.state.newuser, "credits");
        const { user } = this.state;

        return (
            <div className="h-screen overflow-auto p-4">
                <span className="text-1000 font-medium text-4xl  mb-3">
                    End Users
                </span>
                <br />
                <Button
                    label="Add User"
                    onClick={() =>
                        this.setState({
                            isShowAdd: true,
                        })
                    }
                ></Button>

                {this.state.isShowAdd && (
                    <AddUser
                        onUserAddAndUpdate={this.onUserAddAndUpdate}
                        onHide={this.onHide}
                        editUser={this.state.editUser}
                    ></AddUser>
                )}

                <div>

                    <DataTable

                        lazy
                        onSort={this.onSort}
                        onPage={this.onPage}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalUsers}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalUsers}
                        paginator
                        rowsPerPageOptions={[5, 10, 25]}
                        id="_id"
                        selection={this.state.selectedCustomer}
                        onSelectionChange={(e) =>
                            this.setState({ selectedCustomer: e.value })
                        }
                        selectionMode="single"
                        stripedRows
                        value={user}
                        tableStyle={{ minWidth: "50rem" }}
                    >
                        <Column field="name" header="Name"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column
                            field="phoneNumber"
                            header="Phone"
                        ></Column>
                        <Column
                            sortabl
                            body={(rowData) => {
                                return (
                                    <>
                                        <Button
                                            icon="pi pi-pencil"
                                            rounded
                                            outlined
                                            onClick={() =>
                                                this.setState({
                                                    editUser: rowData,
                                                    isShowAdd: true,
                                                    isEdit: true,
                                                })
                                            }
                                        />{" "}
                                        <span></span>
                                        <Button
                                            icon="pi pi-trash"
                                            rounded
                                            outlined
                                            onClick={() =>
                                                this.deleteSubscription(
                                                    rowData._id
                                                )
                                            }
                                        ></Button>
                                    </>
                                );
                            }}
                            header="Actions"
                        ></Column>

                        <Column
                            sortabl
                            body={(rowData) => {
                                return (
                                    <>
                                        <Button
                                            label="Add Credits"
                                            rounded
                                            outlined
                                            onClick={() =>
                                                this.setState({
                                                    isAddCredits: true,
                                                    newuser: rowData,
                                                })
                                            }

                                        />{" "}
                                        <span></span>
                                    </>
                                );
                            }}
                            header="Credits"
                        ></Column>
                    </DataTable>

                    {this.state.isAddCredits && (
                        <Dialog
                            header="Add Credits"
                            visible={true}
                            style={{ width: "49vw" }}
                            onHide={this.onHide}
                            footer={
                                <Button
                                    label="Add"
                                    onClick={() => this.addCredits()}
                                ></Button>
                            }
                        >
                            <InputText
                                value={this.state.credits}
                                onChange={(e) => {
                                    this.setState((prevState) => {
                                        return {
                                            newuser: {
                                                ...prevState.newuser,
                                                credits: e.target.value,
                                            },
                                        };
                                    });
                                }}
                            ></InputText>
                        </Dialog>
                    )}
                </div>
            </div>
        );
    }
}

export default Subscription;
