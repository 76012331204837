



import { ACTIONS } from "../actionTypes";
import axios from "axios";
import { SERVERLESS_URL } from "./../../Service/config";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../Utils/constants";
import { logOut, removeNullUndefinedKeys } from "../../Utils";

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
};

const dispatchRequestAllScreeningCalls = (data) => ({
    type: ACTIONS.REQUEST_ADMIN_SCREENINGCALLS,
    data,
});

const dispatchReceivedAllScreeningCalls = (data) => ({
    type: ACTIONS.RECEIVED_ADMIN_SCREENINGCALLS,
    data,
});

const dispatchErrorAllScreeningCalls = (err) => ({
    type: ACTIONS.ERROR_ADMIN_SCREENINGCALLS,
    err,
});

const dispatchClearAllScreeningCalls = () => ({
    type: ACTIONS.CLEAR_ADMIN_SCREENINGCALLS,
});

export const getAllScreeningCalls = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().adminScreeningCalls;
    console.log(currentState, "action consultant");
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams),
        };
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    dispatch(dispatchRequestAllScreeningCalls());

    axios({
        method: "post",
        url:
            `${SERVERLESS_URL}/consultants/screeningcalls/admincalls?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedAllScreeningCalls(res.data));
            } else {
                dispatch(
                    dispatchErrorAllScreeningCalls({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorAllScreeningCalls(e));
        });
};

export const resetLazyAllScreeningCalls = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestAllConsultantsData(intialTableFilter));
    axios({
        method: "post",
        url: `${SERVERLESS_URL}/consultants/screeningcalls/admincalls?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedAllConsultantsData(res.data));
            } else {
                dispatch(
                    dispatchErrorAllScreeningCalls({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorAllScreeningCalls(e));
        });
};

export const clearConsltants = () => dispatchClearAllScreeningCalls();
