import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import NavFooter from "../../navFooter";
import AddProfile from "./addProfile";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { BasicLazyParams } from "../../../Utils/constants";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";
import ViewFullProfie from "../common/viewFullProfile";
import { Toolbar } from "primereact/toolbar";
import { logOut } from "../../../Utils";


export default class MProfiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddProfile: false,
            profiles: [],
            lazyParams: {
                ...BasicLazyParams,
                sortField: "updatedAt",
                sortOrder: 1,
            },
            globalSearch: "",
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    getProfiles = () => {

        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            profiles: [],
        });
        const url = `/mprofiles/my-profiles?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profiles: res.res.data,
                        totalRecords: res.res.totalRecords,
                        disableNext: res.res.data.length == 0 ? true : false
                    });

                    if (!res.res?.data?.length) {
                        this.toast.show({
                            severity: "warn",
                            summary: "No profiles",
                            detail: "",
                            life: 3000,
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getProfiles();
    }

    onAfterProfiledAdded = (isEdit) => {
        this.toast.show({
            severity: "success",
            summary: "Success",
            detail: `Profile ${isEdit ? "Edited" : "Added"} Successfully`,
            life: 3000,
        });

        this.setState(
            {
                isAddProfile: false,
            },
            this.getProfiles
        );
    };

    onProfileClick = (_id) => {

        this.setState({
            isShowProfile: true,
            selectedProfileId: _id,
        });
    };

    onBack = () => {
        this.setState({
            isShowProfile: false,
        });
    };
    onNextOrPrevious = (next) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page:
                        prevState.lazyParams.page +
                        (next ? 1 : prevState.lazyParams.page ? -1 : 0),
                },
            };
        }, this.getProfiles);
    };


    render() {
        const { isAddProfile, lazyParams, disableNext, profiles } = this.state;
        console.log(profiles, "profiles");
        return (
            <NavFooter>
                {this.state.isShowProfile ? (
                    <ViewFullProfie
                        onBack={this.onBack}
                        selectedProfileId={this.state.selectedProfileId}
                    />
                ) : (
                    <>
                        <div className="layout-content">
                            {!isAddProfile ? (
                                <div>
                                    <Button
                                        label="Add Profile"
                                        onClick={() => {
                                            this.setState({
                                                isAddProfile: true,
                                            });
                                        }}
                                    ></Button>

                                    <div>
                                        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                            <div className="font-bold text-900 text-4xl mb-3 ">
                                                Yours Profiles
                                            </div>
                                            {
                                                profiles.length ? <>

                                                    <div className="grid ">
                                                        {profiles.map((profile) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        className="col-12 md:col-6 xl:col-3 p-3"
                                                                        key={
                                                                            profile._id
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="surface-card shadow-3 border-rounded p-4"
                                                                            onClick={() => {
                                                                                this.onProfileClick(profile._id);
                                                                            }}
                                                                        >
                                                                            <div className="flex flex-column align-items-center border-bottom-1 surface-border pb-3">
                                                                                <img
                                                                                    src={
                                                                                        CLOUDFRONT_ENDPOINT +
                                                                                        profile
                                                                                            ?.profileUrl
                                                                                            ?.key
                                                                                    }
                                                                                    alt="avatar-f-1"
                                                                                    className="mb-3 w-full "
                                                                                    height="100"
                                                                                />
                                                                                <span className="text-2xl text-800 block mb-3 font-semibold">
                                                                                    {
                                                                                        profile.firstName
                                                                                    }{" "}
                                                                                </span>
                                                                                <span className="text-lg text-900 font-medium mb-2">
                                                                                    {
                                                                                        profile.lastName
                                                                                    }
                                                                                </span>
                                                                                <span className="text-600 font-medium mb-3">
                                                                                    {
                                                                                        profile.profession
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}




                                                    </div>
                                                    <div className="grid ">
                                                        <div className="col-12">
                                                            <Toolbar
                                                                className="sp-toolbar"
                                                                left={() => {
                                                                    return (
                                                                        <Button
                                                                            disabled={lazyParams?.page < 1}
                                                                            onClick={() =>
                                                                                this.onNextOrPrevious(
                                                                                    false
                                                                                )
                                                                            }
                                                                            className="p-button-outlined p-button-secondary"
                                                                            label="Previous"
                                                                            icon="pi pi-arrow-left"
                                                                        />
                                                                    );
                                                                }}
                                                                right={() => {
                                                                    return (
                                                                        <Button
                                                                            disabled={disableNext}
                                                                            onClick={() =>
                                                                                this.onNextOrPrevious(
                                                                                    true
                                                                                )
                                                                            }
                                                                            className="p-button-outlined p-button-secondary"
                                                                            iconPos="right"
                                                                            label="Next"
                                                                            icon="pi pi-arrow-right"
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </> : <>
                                                    You haven't add any profiles. Please add
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <AddProfile
                                        onCancel={() => {
                                            this.setState({
                                                isAddProfile: false,
                                            });
                                        }}
                                        onAfterProfiledAdded={
                                            this.onAfterProfiledAdded
                                        }
                                    />
                                </>
                            )}
                        </div>
                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                    </>
                )}
            </NavFooter>
        );
    }
}
