import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { CodeGuruReviewer } from 'aws-sdk';
import { logOut, removeNullUndefinedKeys } from '../../Utils';


const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}




const dispatchRequestSubmissions = data => ({
    type: ACTIONS.REQUEST_SUBMISSIONS,
    data
}

);

const dispatchReceivedSubmissions = (data) => ({
    type: ACTIONS.RECEIVED_SUBMISSIONS,
    data
}
);

const dispatchErrorSubmissions = err => ({
    type: ACTIONS.ERROR_SUBMISSIONS,
    err
});

const dispatchClearSubmissions = () => ({
    type: ACTIONS.CLEAR_SUBMISSIONS
});

const dispatchReceivedAddSubmissions = (data) => ({
    type: ACTIONS.RECEIVED_ADD_SUBMISSIONS,
    data,
});

const dispatchReceivedUpdateSubmissions = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_SUBMISSIONS,
    data,
});
const dispatchReceivedDeleteSubmissions = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_SUBMISSIONS,
    data,
});
const dispatchErrorAddSubmissions = (err) => ({
    type: ACTIONS.ERROR_ADD_SUBMISSIONS,
    err,
});

export const addSubmissions = (data) => (dispatch) => {

    axios({
        method: data?._id ? "put" : "post",
        url: `${SERVERLESS_URL}/consultants/submissions${data?._id ? "" : "/add"
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data: data,
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {
                if (data?._id) {
                    dispatch(dispatchReceivedUpdateSubmissions(res.data?.data));
                } else {
                    dispatch(dispatchReceivedAddSubmissions(res.data?.data));
                }
            } else {
                dispatch(
                    dispatchErrorAddSubmissions({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorAddSubmissions(e));
        });
};


export const getSubmissions = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().submissions;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")
    dispatch(dispatchRequestSubmissions(data));
    const { consultant } = data
    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/submissions/${consultant}?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedSubmissions(res.data));
        } else {
            dispatch(dispatchErrorSubmissions({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorSubmissions(e));
    });
}

export const resetLazySubmissions = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestSubmissions(intialTableFilter));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/consultant/contentwriter?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedSubmissions(res.data));
        } else {
            dispatch(dispatchErrorSubmissions({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorSubmissions(e));
    });
}


export const clearConsltants = () => dispatchClearSubmissions();