import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import { Dropdown } from 'primereact/dropdown';
import { logOut } from '../../../Utils';

class ClosureByRecruiterCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recruiterList: [],
      selectedRecruiter: "",
      chartData: null,
      lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
      },
    };
    this.serverlessService = new ServerlessService();
  }

  componentDidMount() {
    this.getRecruiters();
  }

  getRecruiters = () => {
    const urlEnd = "/admin/get-privilege-users";
    const requestBody = {
      privilegeType: "isBenchSales"
    }

    this.serverlessService
      .post(urlEnd, requestBody, true)
      .then((res) => {

        if (res && res.status && res.res.status) {
          this.setState({
            recruiterList: res.res.data,
          });
        }
      })
      .catch((e) => {
        if (e?.response?.status > 400) {
          logOut()
        }
        console.error("Error fetching recruiters:", e);
      });
  };

  handleRecruiterChange = (e) => {
    const selectedRecruiter = e.value;
    this.setState({ selectedRecruiter }, () => {
      // Fetch consultant data for the selected recruiter
      this.fetchConsultantData(selectedRecruiter);
    });
  };

  fetchConsultantData = (selectedRecruiter) => {
    // Replace 'getConsultantApi' with your actual API endpoint to fetch consultant data
    const getConsultantApi = `/consultants/consultant/getconsultant-by-recruiter`;
    const requestBody = {
      id: selectedRecruiter._id,
      status: CONSULTANT_STATUS.COMPLETED
    }

    this.serverlessService.post(getConsultantApi, requestBody, true)
      .then((res) => {


        this.prepareChartData(res.res.data);

      })
      .catch((error) => {
        console.error("Error fetching consultant data:", error);
      });
  };

  prepareChartData(interviews) {
    const today = new Date();
    const timeIntervals = [
      { label: 'Today', days: 1 },
      { label: '3 Days', days: 3 },
      { label: '1 Week', days: 7 },
      { label: '15 Days', days: 15 },
      { label: '1 Month', days: 30 },
      { label: '2 Months', days: 60 },
      { label: '3 Months', days: 90 },
      { label: '6 Months', days: 180 },
      { label: '1 Year', days: 365 },

    ];

    const counts = timeIntervals.map(interval => {
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - interval.days);
      return interviews.filter(submission => new Date(submission.createdAt) >= startDate && new Date(submission.createdAt) <= today).length;
    });

    const chartData = {
      labels: timeIntervals.map(interval => interval.label),
      datasets: [
        {
          label: 'Number of Closures',
          backgroundColor: '#42A5F5',
          data: counts
        }
      ]
    };

    this.setState({ chartData });
  }

  render() {
    const { recruiterList, selectedRecruiter } = this.state;

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <h2>Closure Trends</h2>
          <div className="p-field">
            <label htmlFor="recruiter">Select Recruiter: </label>
            <Dropdown
              id="recruiter"
              value={selectedRecruiter}
              options={recruiterList}
              onChange={this.handleRecruiterChange}
              optionLabel="name"
              placeholder="Select Recruiter"
            />
          </div>
          {this.state.chartData && (
            <Chart type="bar" data={this.state.chartData} />
          )}
        </div>
      </div>
    );
  }
}

export default ClosureByRecruiterCharts;
