
import React, { Component } from "react";
import { Button } from "primereact/button";
import ServerlessService from "../../../../Service/ServerlessService";

import { Tooltip } from 'primereact/tooltip';
import Authorize, { PERMISSIONS } from '../../../../session/authorize';

import BaseComponent from "../../../common/BaseComponent";
import { logOut, showPreviewDoc } from "../../../../Utils";

class ViewSubmission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultant: null,
        };

        this.serverlessService = new ServerlessService();
    }

    getSubmission = (consultantId) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/submissions/id/${consultantId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {

                    this.setState({
                        isLoading: false,
                        consultant: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount = () => {
        if (this.props?.params?.id) {
            this.getSubmission(this.props?.params.id);
        }

    };

    previewBody = (rowData) => {
        return (
            <div className="p-grid row">

                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                        <span
                            onClick={() => {
                                showPreviewDoc(rowData.submissionProofURL)
                            }}
                            data-pr-tooltip="Preview Doc"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>



            </div>
        );
    };


    render() {
        const { consultant } = this.state;

        return (

            <>
                <div >
                    <div className="h-screen overflow-auto ss-data-table">
                        {consultant && (
                            <div className="p-grid p-fluid" >
                                <div className="font-medium text-3xl text-900 mb-3">Submission Details</div>
                                <div className="grid grid-nogutter">
                                    <div className="col-12 md:col-6 p-3">
                                        <label className="font-bold block ">Client</label>
                                        <p className="text-lg">{consultant.client}</p>
                                    </div>
                                    <div className="col-12 md:col-6 p-3">
                                        <label className="font-bold block ">PayScale</label>
                                        <p className="text-lg">{consultant.payScale}$</p>
                                    </div>
                                    <div className="col-12 md:col-6 p-3">
                                        <label className="font-bold block ">Email</label>
                                        <p className="text-lg">{consultant.email}</p>
                                    </div>
                                    <div className="col-12 md:col-6 p-3">
                                        <label className="font-bold block ">Phone</label>
                                        <p className="text-lg">{consultant.phone}</p>
                                    </div>
                                    <div className="col-12 md:col-6 p-3">
                                        <label className="font-bold block ">Submission ProofURL</label>
                                        <p className="text-lg">{this.previewBody(consultant)}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            <Button
                                label="Cancel"
                                className="p-button-text hover:bg-primary"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                            />
                        </div>
                    </div>
                </div>

            </>




        );
    }
}

export default BaseComponent(ViewSubmission);

