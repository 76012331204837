import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from '../../../Utils';

class ScreeningCallsCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screeningcalls: [],
      chartData: null,
      lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
      },
    };
    this.serverlessService = new ServerlessService();
  }

  componentDidMount() {
    this.getscreeningcalls();
  }

  getscreeningcalls = () => {
    const { lazyParams, globalSearch } = this.state;
    const urlEnd = "/consultants/screeningcalls/";

    const url = `${urlEnd}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
      }&search=${globalSearch}${lazyParams.sortField
        ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
        }`
        : ""
      }`;

    this.serverlessService
      .post(urlEnd, { status: CONSULTANT_STATUS.CREATED }, true)
      .then((res) => {
        if (res && res.status && res.res.status) {
          this.setState({
            screeningcalls: res.res.data,
          });
          this.prepareChartData(res.res.data);
        }
      })
      .catch((e) => {
        if (e?.response?.status > 400) {
          logOut()
        }
        console.error("Error fetching screeningcalls:", e);
      });
  };

  getConsultantById = (id) => {
    console.log(id)
    return this.serverlessService.get(`/consultants/consultant/id/${id}`, true)
      .then((res) => {
        if (res && res.status && res.res.data) {
          return res.res.data.name;
        } else {
          return null;
        }
      })
      .catch((e) => {
        if (e?.response?.status > 400) {
          logOut()
        }
        console.error("Error fetching consultant name:", e);
        return null;
      });
  };

  async prepareChartData(screeningcalls) {
    const consultantCounts = {};


    for (const call of screeningcalls) {
      const consultantId = call.consultantId;
      if (!consultantCounts[consultantId]) {
        consultantCounts[consultantId] = 1;
      } else {
        consultantCounts[consultantId]++;
      }
    }

    const chartData = {
      labels: [],
      datasets: [
        {
          label: 'Number of screening calls',
          backgroundColor: '#42A5F5',
          data: []
        }
      ]
    };

    // Fetch consultant names and update chart data
    for (const [consultantId, count] of Object.entries(consultantCounts)) {
      const consultantName = await this.getConsultantById(consultantId);
      if (consultantName) {
        chartData.labels.push(consultantName);
        chartData.datasets[0].data.push(count);
      }
    }

    this.setState({ chartData });
  }

  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <h2>Screening Calls Trends</h2>
          {this.state.chartData && (
            <Chart type="bar" data={this.state.chartData} />
          )}
        </div>
      </div>
    );
  }
}

export default ScreeningCallsCharts;
