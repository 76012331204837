import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from './../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { logOut, removeNullUndefinedKeys } from '../../Utils';


const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}



const dispatchRequestMarketingHistory = data => ({
    type: ACTIONS.REQUEST_MARKETING_HISTORY,
    data
});

const dispatchReceivedMarketingHistory = (data) => ({
    type: ACTIONS.RECEIVED_MARKETING_HISTORY,
    data
});

const dispatchErrorMarketingHistory = err => ({
    type: ACTIONS.ERROR_MARKETING_HISTORY,
    err
});

const dispatchClearMarketingHistory = () => ({
    type: ACTIONS.CLEAR_MARKETING_HISTORY
});

export const getMarketingHistory = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().marketingHistoryReducer;
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    dispatch(dispatchRequestMarketingHistory(data));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/consultant/getrecruiterhistory?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: data
    }).then((res) => {
        _data = res.data

        if (res && res.data) {

            dispatch(dispatchReceivedMarketingHistory(res.data));
        } else {
            dispatch(dispatchErrorMarketingHistory({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorMarketingHistory(e));
    });
}

export const resetLazyMarketingHistory = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestMarketingHistory(intialTableFilter));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/learning-plans?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedMarketingHistory(res.data));
        } else {
            dispatch(dispatchErrorMarketingHistory({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorMarketingHistory(e));
    });
}

export const clearLearningPlans = () => dispatchClearMarketingHistory();

