import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from '../../../Utils';

class InterviewCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviews: [],
      chartData: null,
      lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
      },
    };
    this.serverlessService = new ServerlessService();
  }

  componentDidMount() {
    this.getInterviews();
  }

  getInterviews = () => {
    const { lazyParams, globalSearch } = this.state;
    const urlEnd = "/consultants/interviews/";

    const url = `${urlEnd}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
      }&search=${globalSearch}${lazyParams.sortField
        ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
        }`
        : ""
      }`;

    this.serverlessService
      .post(urlEnd, { status: CONSULTANT_STATUS.CREATED }, true)
      .then((res) => {
        console.log(res)
        if (res && res.status && res.res.status) {
          this.setState({
            interviews: res.res.data,
          });
          this.prepareChartData(res.res.data);
        }
      })
      .catch((e) => {
        if (e?.response?.status > 400) {
          logOut()
        }
        console.error("Error fetching interviews:", e);
      });
  };

  prepareChartData(interviews) {
    const today = new Date();
    const timeIntervals = [
      { label: 'Today', days: 1 },
      { label: '3 Days', days: 3 },
      { label: '1 Week', days: 7 },
      { label: '15 Days', days: 15 },
      { label: '1 Month', days: 30 },
      { label: '2 Months', days: 60 }
    ];

    const counts = timeIntervals.map(interval => {
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - interval.days);
      return interviews.filter(submission => new Date(submission.createdAt) >= startDate && new Date(submission.createdAt) <= today).length;
    });

    const chartData = {
      labels: timeIntervals.map(interval => interval.label),
      datasets: [
        {
          label: 'Number of Interviews',
          backgroundColor: '#42A5F5',
          data: counts
        }
      ]
    };
    console.log(chartData)
    this.setState({ chartData });
  }

  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <h2>Interviews Trends</h2>
          {this.state.chartData && (
            <Chart type="bar" data={this.state.chartData} />
          )}
        </div>
      </div>
    );
  }
}

export default InterviewCharts;
