import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import React, { Component } from "react";
import Service from "../../../Service";
import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import BranchFields from "./branch.json";
import { InputText } from "primereact/inputtext";
import ServerlessService from "../../../Service/ServerlessService";

class AddBranch extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            BranchFields,
            this.props.editBranch || {}
        );
        this.state = {
            branch: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />
            <Button
                label={this.props.editBranch ? "Update" : "Add"}
                onClick={this.addOrEdit}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    addOrEdit = (a) => {
        let branch = trimObj(this.state.branch);

        const formStatus = isFormValid(
            BranchFields,
            this.formFields.formValidations,
            branch
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editBranch) {
                this.setState({
                    isLoading: true,
                });

                const url = `/co-space/branch`;
                this.serverlessService
                    .put(url, this.state.branch, true)
                    .then((res) => {
                        if (res && res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    branch: {
                                        name: "",
                                        code: "",
                                        address: "",
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.onBranchAddorEdit();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/co-space/branch/add`;
                this.serverlessService
                    .post(url, this.state.branch, true)
                    .then((res) => {
                        if (res && res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    branch: {
                                        name: "",
                                        code: "",
                                        address: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onBranchAddorEdit();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    render() {
        const { branch, formValidations } = this.state;
        return (
            <div>
                <Dialog
                    header={
                        this.props.editBranch ? "Edit branch" : "Add branch"
                    }
                    visible={true}
                    style={{ width: "65vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                    draggable={false}
                >
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-4">
                            <label className="font-bold block mb-2">Name</label>

                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        BranchFields,
                                        branch,
                                        formValidations,
                                        "branch",
                                        "formValidations"
                                    );
                                }}
                                value={branch.name}
                            />
                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["name"].errorMsg}
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">Address</label>

                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "address",
                                        this,
                                        BranchFields,
                                        branch,
                                        formValidations,
                                        "branch",
                                        "formValidations"
                                    );
                                }}
                                value={branch.address}
                            />

                            {formValidations &&
                                !formValidations.fields["address"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["address"].errorMsg}
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">Code</label>

                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "code",
                                        this,
                                        BranchFields,
                                        branch,
                                        formValidations,
                                        "branch",
                                        "formValidations"
                                    );
                                }}
                                value={branch.code}
                            />

                            {formValidations &&
                                !formValidations.fields["code"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["code"].errorMsg}
                                    </p>
                                )}
                        </div>
                    </div>

                </Dialog>
            </div>
        );
    }
}

export default AddBranch;
