import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { logOut, removeNullUndefinedKeys } from '../../Utils';


const dispatchRequestFaq = data => ({
    type: ACTIONS.REQUEST_CONSULTANT_FAQ,
    data
});

const dispatchReceivedFaq = (data) => ({
    type: ACTIONS.RECEIVED_CONSULTANT_FAQ,
    data
});

const dispatchErrorFaq = err => ({
    type: ACTIONS.ERROR_CONSULTANT_FAQ,
    err
});

const dispatchClearFaq = () => ({
    type: ACTIONS.CLEAR_CONSULTANT_FAQ
});


export const getConsultantFaqs = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().consultantFAQs;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }


    const { lazyParams } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")
    dispatch(dispatchRequestFaq(data));

    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/faq/consultant?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        _data = res.data
        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedFaq(res.data));
        } else {
            dispatch(dispatchErrorFaq({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorFaq(e));
    });
}








export const clearConsltantsFaqs = () => dispatchClearFaq();