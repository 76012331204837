import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Column } from "primereact/column";
import AddSubscription from "./addSubscription";
import { Button } from "primereact/button";
import ServerlessService from "../../../Service/ServerlessService";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from "../../../Utils";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscription: null,
            isShowAdd: false,
            editSubscription: null,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }

    onSubscriptionAddandUpdate = () => {
        this.setState({
            isShowAdd: false,
            editSubscription: null,
        });
        this.getSubscription();
    };

    onHide = () => {
        this.setState({
            isShowAdd: false,
            editSubscription: null,
        });
    };

    getSubscription = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
        });

        const url = `/subscription?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    console.log(res.res.data, "get subscription data");


                    this.setState({
                        isLoading: false,
                        subscription: res.res.data,
                        totalSubscriptions: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    deleteSubscription = (_id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/subscription/delete/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getSubscription();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getSubscription);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getSubscription);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getSubscription);
    };

    componentDidMount() {
        this.getSubscription();
    }

    render() {
        console.log(this.state.editSubscription, "edit subscription");
        const { subscription } = this.state;

        return (
            <div className="h-screen overflow-auto p-4">
                <div >
                    <span className="text-1000 font-medium text-4xl  mb-3">
                        Packages & Subscriptions
                    </span>
                    <br />
                    <Button
                        label="Add Subscription"
                        onClick={() =>
                            this.setState({
                                isShowAdd: true,
                            })
                        }
                    ></Button><br /><br />
                </div>

                {this.state.isShowAdd && (
                    <AddSubscription
                        onSubscriptionAddandUpdate={
                            this.onSubscriptionAddandUpdate
                        }
                        onHide={this.onHide}
                        editSubscription={this.state.editSubscription}
                    ></AddSubscription>
                )}

                <div>

                    <DataTable
                        lazy
                        onSort={this.onSort}
                        onPage={this.onPage}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalSubscriptions}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalSubscriptions}
                        paginator
                        rowsPerPageOptions={[5, 10, 25]}
                        id="_id"
                        selection={this.state.selectedCustomer}
                        onSelectionChange={(e) =>
                            this.setState({ selectedCustomer: e.value })
                        }
                        selectionMode="single"
                        stripedRows
                        value={subscription}
                        tableStyle={{ minWidth: "50rem" }}
                    >
                        <Column field="id" header="Id"></Column>
                        <Column field="packageName" header="Name"></Column>
                        <Column field="days" header="Days"></Column>
                        <Column field="type" header="Type"></Column>
                        <Column field="price" header="Price"></Column>

                        <Column
                            sortabl
                            body={(rowData) => {
                                return (
                                    <>
                                        <Button
                                            icon="pi pi-pencil"
                                            rounded
                                            outlined
                                            onClick={() =>
                                                this.setState({
                                                    editSubscription: rowData,
                                                    isShowAdd: true,
                                                    isEdit: true,
                                                })
                                            }
                                        />{" "}
                                        <span></span>
                                        <Button
                                            icon="pi pi-trash"
                                            rounded
                                            outlined
                                            onClick={() =>
                                                this.deleteSubscription(
                                                    rowData._id
                                                )
                                            }
                                        ></Button>
                                    </>
                                );
                            }}
                            header="Actions"
                        ></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default Subscription;
