import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut, trimObj } from "../../../Utils";

import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import QuestionFields from "./question.json";
import BaseComponent from "../../common/BaseComponent";
import { addQuestionBankData } from "../../../store/actions";

class AddQuestion extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            QuestionFields,
            this.props.editQuestion || {}
        );
        this.state = {
            question: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = async () => {
        let question = trimObj(this.state.question);

        const formStatus = isFormValid(
            QuestionFields,
            this.formFields.formValidations,
            question
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            await this.props.addQuestionBankData(question);
        }
    };

    footerContent = () => (
        <div>
            <Button
                label={this.props.editQuestion ? "Update" : "Add"}
                onClick={this.addOrEdit}
                autoFocus
                className="p-button-primary "
            />
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />
        </div>
    );

    getQuestionById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/questionbank/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            question: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/question-bank");
        }
    }
    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getQuestionById(this.props?.params.id);
            }
        }
    }

    render() {
        const { question, formValidations } = this.state;

        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="p-grid row pl-6 pr-6">
                    <div className="font-bold block mb-2">
                        <h1>Questions</h1>
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Question</label>
                        <InputTextarea
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "question",
                                    this,
                                    QuestionFields,
                                    question,
                                    formValidations,
                                    "question",
                                    "formValidations"
                                );
                            }}
                            value={question.question}
                        />
                        {formValidations &&
                            !formValidations.fields["question"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["question"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-12">
                        <label className="font-bold block mb-2">Answer</label>
                        <InputTextarea
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "answer",
                                    this,
                                    QuestionFields,
                                    question,
                                    formValidations,
                                    "question",
                                    "formValidations"
                                );
                            }}
                            value={this.state.question.answer}
                        />
                        {formValidations &&
                            !formValidations.fields["answer"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["answer"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-12">
                        <label className="font-bold block mb-2">Tags</label>
                        <Chips
                            className="ass"
                            value={this.state.question.tags}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "tags",
                                    this,
                                    QuestionFields,
                                    question,
                                    formValidations,
                                    "question",
                                    "formValidations"
                                );
                            }}
                            separator=","
                        />

                        {formValidations &&
                            !formValidations.fields["tags"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["tags"].errorMsg}
                                </p>
                            )}
                    </div>
                </div>
                <div className="mt-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <Button
                        label={
                            this.props?.params?.operation == "edit"
                                ? "Update"
                                : "Add"
                        }
                        onClick={this.addOrEdit}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    questions: state?.questionBanks?.questions,
    totalRecords: state?.questionBanks?.totalRecords,
    lazyParams: state?.questionBanks?.lazyParams,
    isLoading: state?.questionBanks?.isLoading,
    consultantAddedOrUpdated: state?.questionBanks?.consultantAddedOrUpdated,
});

export default BaseComponent(AddQuestion, mapStateToProps, {
    addQuestionBankData,
});
