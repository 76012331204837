import { ACTIONS } from "../actionTypes";
import axios from "axios";
import { SERVERLESS_URL } from "../../Service/config";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../Utils/constants";
import { CodeGuruReviewer } from "aws-sdk";
import { logOut, removeNullUndefinedKeys } from "../../Utils";

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
};

const dispatchRequestConsultantLeaningPlans = (data) => ({
    type: ACTIONS.REQUEST_CONSTULTANT_LEARNING_PLANS,
    data,
});

const dispatchReceivedConsultantLeaningPlans = (data) => ({
    type: ACTIONS.RECEIVED_CONSTULTANT_LEARNING_PLANS,
    data,
});

const dispatchErrorConsultantLeaningPlans = (err) => ({
    type: ACTIONS.ERROR_CONSTULTANT_LEARNING_PLANS,
    err,
});

const dispatchClearConsultantLeaningPlans = () => ({
    type: ACTIONS.CLEAR_CONSTULTANT_LEARNING_PLANS,
});

export const getConsultantLeaningPlans = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().consultantLearningPlans;
    console.log(currentState, "currentstate");
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams),
        };
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck");
    dispatch(dispatchRequestConsultantLeaningPlans(data));

    axios({
        method: "get",
        url:
            `${SERVERLESS_URL}/consultants/learning-plans/user-plans?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            console.log(res.data, "documentEditing action");
            if (res && res.data) {

                // const convert = (r) => {

                //     const outputArray = [];
                //     if (r?.planDetails) {
                //         for (const key in r?.planDetails) {
                //             if (r?.planDetails.hasOwnProperty(key) && r?.planDetails[key].length > 0) {
                //                 outputArray.push({
                //                     day: key.substring(1),
                //                     resources: r?.planDetails[key]
                //                 });
                //             }
                //         }
                //     }
                //     r.planDetails = outputArray

                //     return r;
                // }


                dispatch(dispatchReceivedConsultantLeaningPlans(res.data));
            } else {
                dispatch(
                    dispatchErrorConsultantLeaningPlans({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorConsultantLeaningPlans(e));
        });
};

export const resetLazyConsultantLeaningPlans = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestConsultantLeaningPlans(intialTableFilter));
    axios({
        method: "get",
        url: `${SERVERLESS_URL}/consultants/learning-plans/user-plans?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedConsultantLeaningPlans(res.data));
            } else {
                dispatch(
                    dispatchErrorConsultantLeaningPlans({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorConsultantLeaningPlans(e));
        });
};

export const clearConsltants = () => dispatchClearConsultantLeaningPlans();
