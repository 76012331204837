import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import Service from "./../../../../Service";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import adminUserFields from "./adminUser.json";
import LoadingComponent from "./../../../../LoadingComponent";
import {
    getFormFields,
    isFormValid,
    onEmailChange,
    onNumberChange,
    onTextChange,
} from "./../../../../Utils/formHelper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Authentication from "./../../../../session/index";
import { logOut, trimObj } from "./../../../../Utils";
import ServerlessService from "./../../../../Service/ServerlessService";
import Required from "../../../common/required";


class CreateAdminUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            adminUserFields,
            this.props.adminUser
                ? {
                    password: "Test@123",
                    passwordConfirm: "Test@123",
                    ...this.props.adminUser,
                }
                : {
                    appType: process.env.REACT_APP_TARGET
                }
        );
        this.state = {
            adminUser: this.formFields.data,
            formValidations: this.formFields.formValidations,
            rolesList: [],
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    isAdditionalValid = () => {
        let adminUser = JSON.parse(JSON.stringify(this.state.adminUser));
        let { formValidations } = this.state;
        let isValid = true;
        if (adminUser.password != adminUser.passwordConfirm) {
            isValid = false;
            formValidations.fields["passwordConfirm"] = {
                ...formValidations.fields["passwordConfirm"],
                isValid: false,
                errorMsg: `Password and Confirm password is not matched.`,
            };
        }

        this.setState((prevState) => {
            return {
                formValidations,
            };
        });

        return isValid;
    };

    saveAdminUser = () => {
        console.log("this is called");

        let adminUserData = { ...this.state.adminUser };
        adminUserData.isActive = true;
        adminUserData = trimObj(adminUserData);

        console.log(adminUserData, "sdffsdfsf");
        const formStatus = isFormValid(
            adminUserFields,
            this.formFields.formValidations,
            adminUserData
        );
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let url = `/admin/save`;

            if (this.props.adminUser) {
                url = `/admin/update`;
            }

            this.setState({
                isLoading: true,
            });

            this.serverlessService
                .post(url, adminUserData, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                            },
                            () => {
                                this.props.onSave(
                                    this.props.adminUser ? true : false
                                );
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.message,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    getRoles = () => {
        this.setState({
            isLoading: true,
        });

        let url = `/authorize/getRolesNamesids`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            rolesList: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push("/login");
        }
        console.log(this.props)
        this.getRoles();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isSessionChecked != this.props.isSessionChecked &&
            this.props.isSessionChecked &&
            !this.props.authenticated
        ) {
            this.props.history.push("/login");
        }
    }

    footer = () => {
        return (
            <div>
                <Button
                    label={`${this.props.adminUser ? "Update" : "Save"
                        } Admin User`}
                    onClick={this.saveAdminUser}
                />
            </div>
        );
    };

    render() {
        const { adminUser, rolesList, formValidations } = this.state;

        return (
            <>
                <Dialog
                    header={`${this.props.adminUser ? "Edit" : "Create"} User`}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    dismissableMask={true}
                    visible={true}
                    footer={this.footer}
                    closable={true}
                    style={{ width: "90%", height: "90vh" }}
                    onHide={this.props.onHide}
                >
                    <div className="grid formgrid p-fluid">
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="nickname" className="font-medium">
                                Name
                                <Required />
                            </label>
                            <InputText
                                value={adminUser.name}
                                disabled={this.props.adminUser}
                                autoComplete="new-password"
                                className=""
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        adminUserFields,
                                        adminUser,
                                        formValidations,
                                        "adminUser",
                                        "formValidations"
                                    );
                                }}
                            />
                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["name"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="nickname" className="font-medium">
                                Phone
                                <Required />
                            </label>
                            <InputText
                                disabled={this.props.adminUser}
                                value={adminUser.phoneNumber}
                                className=""
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "phoneNumber",
                                        this,
                                        adminUserFields,
                                        adminUser,
                                        formValidations,
                                        "adminUser",
                                        "formValidations"
                                    );
                                }}
                            />
                            {formValidations &&
                                !formValidations.fields["phoneNumber"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "phoneNumber"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        {!this.props.adminUser && (
                            <>
                                <div className="field mb-4 col-12 md:col-6">
                                    <label
                                        htmlFor="nickname"
                                        className="font-medium"
                                    >
                                        Password
                                        <Required />
                                    </label>

                                    <InputText
                                        value={adminUser.password}
                                        type="password"
                                        autoComplete="new-password"
                                        className=""
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "password",
                                                this,
                                                adminUserFields,
                                                adminUser,
                                                formValidations,
                                                "adminUser",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["password"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "password"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="field mb-4 col-12 md:col-6">
                                    <label
                                        htmlFor="nickname"
                                        className="font-medium"
                                    >
                                        Confirm Password
                                        <Required />
                                    </label>
                                    <InputText
                                        value={adminUser.passwordConfirm}
                                        type="password"
                                        autoComplete="new-password"
                                        className=""
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "passwordConfirm",
                                                this,
                                                adminUserFields,
                                                adminUser,
                                                formValidations,
                                                "adminUser",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields[
                                            "passwordConfirm"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "passwordConfirm"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                            </>
                        )}
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="nickname" className="font-medium">
                                Email
                                <Required />
                            </label>
                            <InputText
                                disabled={this.props.adminUser}
                                value={adminUser.email}
                                className=""
                                onChange={(e) => {
                                    onEmailChange(
                                        e.target.value,
                                        "email",
                                        this,
                                        adminUserFields,
                                        adminUser,
                                        formValidations,
                                        "adminUser",
                                        "formValidations"
                                    );
                                }}
                            />
                            {formValidations &&
                                !formValidations.fields["email"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["email"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="nickname" className="font-medium">
                                Role
                                <Required />
                            </label>
                            <Dropdown
                                value={adminUser.roles}
                                options={rolesList}
                                optionLabel="roleName"
                                optionValue="_id"
                                onChange={(e) => {
                                    onTextChange(
                                        e.value,
                                        "roles",
                                        this,
                                        adminUserFields,
                                        adminUser,
                                        formValidations,
                                        "adminUser",
                                        "formValidations"
                                    );
                                }}
                                placeholder="Select role"
                            />
                            {formValidations &&
                                !formValidations.fields["roles"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["roles"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});

export default connect(
    mapStateToProps,
    {}
)(Authentication(withRouter(CreateAdminUser)));
