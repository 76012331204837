import React, { Component } from "react";
import { connect } from "react-redux";
import Authentication from "./../../../session/index";
import { getConsltantTags } from "../../../store/actions";
import { Mention } from "primereact/mention";

class NoteEntryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multipleSuggestions: [],
        };
    }

    componentDidMount = () => {
        if (
            !this.props.consulantTagPeoples ||
            !this.props.consulantTagPeoples.length
        ) {
            this.props.getConsltantTags();
        }
    };

    itemTemplate = (suggestion) => {
        return (
            <div className="flex align-items-center">
                <span className="flex flex-column ml-2">
                    {suggestion}
                    <small
                        style={{
                            fontSize: ".75rem",
                            color: "var(--text-secondary-color)",
                        }}
                    >
                        @{suggestion}
                    </small>
                </span>
            </div>
        );
    };

    onMultipleSearch = (event) => {
        const trigger = event.trigger;

        if (trigger === "@") {
            const query = event.query;
            let suggestions;

            if (!query.trim().length) {
                suggestions = [...this.props.consulantTagPeoples];
            } else {
                suggestions = this.props.consulantTagPeoples.filter(
                    (customer) => {
                        return customer
                            .toLowerCase()
                            .startsWith(query.toLowerCase());
                    }
                );
            }

            this.setState({ multipleSuggestions: suggestions });
        } else if (trigger === "#") {
            const query = event.query;
            let suggestions;

            if (!query.trim().length) {
                suggestions = [...this.props.consulantTagKeywords];
            } else {
                suggestions = this.props.consulantTagKeywords.filter((tag) => {
                    return tag.toLowerCase().startsWith(query.toLowerCase());
                });
            }

            this.setState({ multipleSuggestions: suggestions });
        }
    };
    multipleItemTemplate = (suggestion, options) => {
        const trigger = options.trigger;

        if (trigger === "@" && suggestion) {
            return this.itemTemplate(suggestion);
        } else if (trigger === "#" && suggestion) {
            return <span>{suggestion}</span>;
        }

        return null;
    };

    render() {
        return (
            <Mention
                trigger={["@", "#"]}
                suggestions={this.state.multipleSuggestions}
                onSearch={this.onMultipleSearch}
                placeholder="Please enter @ to mention people, # to mention tag"
                itemTemplate={this.multipleItemTemplate}
                rows={5}
                cols={40}
                onChange={this.props.onChange}
                className="w-full"
                value={this.props.value}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
    consulantTagPeoples: state?.consultantsTagging?.consultants,
    consulantTagKeywords: state?.consultantsTagging?.tags,
});

export default connect(mapStateToProps, {
    getConsltantTags,
})(Authentication(NoteEntryComponent));
