import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import ServerlessService from "../../../Service/ServerlessService";
import Service from "../../../Service";
import LoadingComponent from "../../../LoadingComponent";
import { InputNumber } from "primereact/inputnumber";

import { BasicLazyParams, FeeDays } from "../../../Utils/constants";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Tooltip } from "primereact/tooltip";
import { InputTextarea } from "primereact/inputtextarea";
import { logOut } from "../../../Utils";

class FeeDetailsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            isEdit: false,
            visible: false,
            checked: true,
            selectedCustomer: null,
            isShowConfirmationDialog: false,
            feeDetailsData: [],
            viewData: [],

            newFeeDetailsData: {
                type: "",
                fees: [...FeeDays].map((a) => a),
            },

            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addFeeDetailsHandler = (a) => {
        let { newFeeDetailsData } = this.state;

        if (!newFeeDetailsData.type || newFeeDetailsData.type == "") {
            return this.toast.show({
                severity: "error",
                summary: "Invalid",
                detail: "please enter all the fee detail.",
                life: 3000,
            });
        }

        let isValid = true;

        newFeeDetailsData.fees.forEach((f) => {
            if (!f.fee || f.fee == 0 || f.fee == "") {
                isValid = false;
            }
        });

        if (!isValid) {
            return this.toast.show({
                severity: "error",
                summary: "Invalid",
                detail: "please enter all the fee detail.",
                life: 3000,
            });
        }

        if (this.state.isEdit) {
            this.setState({
                isLoading: true,
            });

            const url = `/co-space/feeDetails`;
            this.serverlessService
                .put(url, this.state.newFeeDetailsData, true)
                .then((res) => {

                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                newFeeDetailsData: {
                                    type: "",
                                    fees: [...FeeDays].map((a) => a),
                                    code: "",
                                    description: "",
                                },
                                visible: false,
                                isLoading: false,
                            },
                            () => {
                                this.getFeeDetails();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        } else {
            this.setState({
                isLoading: true,
            });

            const url = `/co-space/feeDetails/add`;
            this.serverlessService
                .post(url, this.state.newFeeDetailsData, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                newFeeDetailsData: {
                                    type: "",
                                    fees: [...FeeDays].map((a) => a),
                                    code: "",
                                    description: "",
                                },
                                visible: false,
                                isLoading: false,
                            },
                            () => {
                                this.getFeeDetails();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    footerContent1 = () => (
        <div>
            <Button
                label="Cancel"
                className="p-button-text hover:bg-primary"
                onClick={() => this.setState({ visible: false })}
            />
            <Button
                label={this.state.isEdit ? "Update" : "Add"}
                onClick={this.addFeeDetailsHandler}
                autoFocus
            />
        </div>
    );

    deleteFeeDetails = (_id) => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/co-space/feeDetails/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getFeeDetails();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getFeeDetails = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            feeDetailsData: [],
        });

        const url = `/co-space/feeDetails?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        feeDetailsData: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showDeleteFeeDetailsDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getFeeDetails);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getFeeDetails);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getFeeDetails);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getFeeDetails);
    };

    componentDidMount() {
        this.getFeeDetails();
    }

    onFeeChange = (id, value) => {
        this.setState((prevState) => {
            return {
                newFeeDetailsData: {
                    ...prevState.newFeeDetailsData,
                    fees: prevState.newFeeDetailsData.fees.map((day) => {
                        if (day.id == id) {
                            day.fee = value;
                        }
                        return day;
                    }),
                },
            };
        });
    };

    viewFeeDetails = (_id) => {
        console.log(typeof _id, "id");

        this.setState({
            isLoading: true,
            viewData: [],
        });

        const url = `/co-space/feeDetails/view/${_id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                console.log(res, "response");

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        viewData: res.res.data,
                        isShowView: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteFeeDetails}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.FEE_M_EDIT}>
                        <span
                            onClick={() =>
                                this.setState({
                                    newFeeDetailsData: JSON.parse(
                                        JSON.stringify(rowData)
                                    ),
                                    visible: true,
                                    isEdit: true,
                                })
                            }
                            data-pr-tooltip="Edit Fee"
                            data-pr-position="top"
                            className={`p-2 feeEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.feeEdit${rowData._id}`} />
                    </Authorize>
                </div>

                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.FEE_M_VIEW}>
                        <span
                            onClick={() => this.viewFeeDetails(rowData._id)}
                            data-pr-tooltip="View Fee"
                            data-pr-position="top"
                            className={`p-2 feeView${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.feeView${rowData._id}`} />
                    </Authorize>
                </div>

                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.FEE_M_DELETE}>
                        <span
                            onClick={() =>
                                this.showDeleteFeeDetailsDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Fee"
                            data-pr-position="top"
                            className={`p-2 feeDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.feeDelete${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };
    render() {
        console.log(this.state.feeDetailsData, "feed");
        return (
            <>
                <div className="p-4">
                    <span className="text-1000 font-medium text-2xl  mb-3">
                        Fee Management
                    </span>
                    <br />
                    <Authorize permId={PERMISSIONS.FEE_M_ADD}>
                        <Button
                            className=" p-button-primary "
                            label="Add Fee Details"
                            severity="warning"
                            onClick={() => this.setState({ visible: true })}
                        />
                    </Authorize>
                    <br></br>
                    <br></br>

                    <Dialog
                        closable={true}
                        dismissableMask={false}
                        draggable={false}
                        header="Add Fee"
                        blockScroll={true}
                        visible={this.state.visible}
                        style={{ width: "65vw" }}
                        onHide={() =>
                            this.setState({
                                visible: false,
                                newFeeDetailsData: {
                                    type: "",
                                    fees: [...FeeDays].map((a) => a),
                                },
                            })
                        }
                        footer={this.footerContent1}
                    >
                        <div className="p-grid row pl-6 pr-6">
                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    {" "}
                                    Fee Type
                                </label>
                                <InputText
                                    onChange={(e) => {
                                        this.setState((prevState) => {
                                            return {
                                                newFeeDetailsData: {
                                                    ...prevState.newFeeDetailsData,
                                                    type: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    value={this.state.newFeeDetailsData.type}
                                />
                            </div>

                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    {" "}
                                    Code
                                </label>
                                <InputText
                                    onChange={(e) => {
                                        this.setState((prevState) => {
                                            return {
                                                newFeeDetailsData: {
                                                    ...prevState.newFeeDetailsData,
                                                    code: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    value={this.state.newFeeDetailsData.code}
                                />
                            </div>
                            <div className="col-8">
                                <label className="font-bold block mb-2">
                                    Description
                                </label>
                                <InputTextarea
                                    rows={3}
                                    onChange={(e) => {
                                        this.setState((prevState) => {
                                            return {
                                                newFeeDetailsData: {
                                                    ...prevState.newFeeDetailsData,
                                                    description: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    value={
                                        this.state.newFeeDetailsData.description
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
                            <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
                            <div className="px-2 z-1 surface-0 flex align-items-center"></div>
                        </div>
                        <div className="pl-6 pr-6">
                            <label className="font-bold block mb-2">
                                Fee Details
                            </label>
                            <div className="p-grid row ">
                                {this.state.newFeeDetailsData.fees.map(
                                    (day) => (
                                        <div className="col-4">
                                            <div>
                                                <label>{day.days}</label>
                                                <div>
                                                    <InputNumber
                                                        onChange={(e) => {
                                                            this.onFeeChange(
                                                                day.id,
                                                                e.value
                                                            );
                                                        }}
                                                        value={day.fee}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Dialog>
                    <Authorize permId={PERMISSIONS.FEE_M_DATA}>
                        <div className="P-4">
                            <DataTable
                                value={this.state.feeDetailsData}
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                scrollHeight="400px"
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column field="type" header="Fee Type"></Column>
                                <Column field="code" header="Code"></Column>
                                <Column
                                    field="description"
                                    header="Description"
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>

                            {this.state.isShowView && (
                                <Dialog
                                    scrollable={false}
                                    closable={true}
                                    dismissableMask={false}
                                    draggable={false}
                                    header="View Fee Details"
                                    visible={this.state.isShowView}
                                    style={{ width: "65vw" }}
                                    onHide={() =>
                                        this.setState({ isShowView: false })
                                    }
                                >
                                    <div className="p-grid row pl-6 pr-6">
                                        <div className="col-4">
                                            <p>
                                                <b>Fee Type : </b>{" "}
                                                {this.state.viewData.type}
                                            </p>
                                        </div>

                                        <div className="col-4">
                                            <p>
                                                <b>Code : </b>{" "}
                                                {this.state.viewData.code}
                                            </p>
                                        </div>

                                        <div className="col-4">
                                            <p>
                                                <b>Description : </b>{" "}
                                                {
                                                    this.state.viewData
                                                        .description
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
                                        <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
                                        <div className="px-2 z-1 surface-0 flex align-items-center"></div>
                                    </div>
                                    <label className="font-bold block mb-2 pl-5 text-1000 text-xl">
                                        Fee Details
                                    </label>
                                    <div className="p-grid row pl-6 pr-6">
                                        {this.state.viewData.fees.map((day) => (
                                            <div className="col-4">
                                                <div>
                                                    <p>
                                                        <b>{day.days} :</b> Rs.{" "}
                                                        {day.fee}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Dialog>
                            )}

                            <Toast
                                ref={(el) => (this.toast = el)}
                                position="bottom-right"
                            />
                            {(this.state.isLoading || this.props.isLoading) && (
                                <LoadingComponent />
                            )}
                        </div>
                    </Authorize>
                </div>

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent}
                    >
                        <p>Deleted Fee Type can't be restore.</p>
                    </Dialog>
                )}
            </>
        );
    }
}

export default FeeDetailsList;
