import React, { Component } from "react";
import ServerlessService from "../../../../Service/ServerlessService";
import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BasicLazyParams } from "../../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Authorize, { PERMISSIONS } from "../../../../session/authorize";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import InterviewStatus from "./interviewStatus";
import BaseComponent from "../../../common/BaseComponent";
import {
    getInterviewsDropdown,
    resetLazyInterviewsDropdown,
} from "../../../../store/actions";
import { Card } from "primereact/card";
import { logOut, showPreviewDoc } from "../../../../Utils";
class InterviewsDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowSendRequest: false,
            globalSearch: "",
            editData: null,
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            consultantInfo: "",
            selectedConsultant: null,
            displayDialog: false,
        };
        this.serverlessService = new ServerlessService();
    }
    onGlobalSearch = (e) => {
        this.props.getInterviewsDropdown({
            globalSearch: e.target.value,
            consultant: this.props.params.id,
        });
    };

    onPage = (event) => {
        this.props.getInterviewsDropdown({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    onSort = (event) => {
        this.props.getInterviewsDropdown({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getInterviewsDropdown({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    getInterviewsDropdown = (consultant) => {
        const _consultant = consultant || this.props.consultant;
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/interviews/${_consultant._id}?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.INTERVIEWS_TRACKER}>
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/interviews/tracker`
                                );
                            }}
                            data-pr-tooltip="Interview Tracker"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </Authorize>
                </div>
                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.INTERVIEWS_VIEW}>
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/interviews/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Interview View"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </Authorize>
                </div>
            </div>
        );
    };

    onCloseAcceptDocumentConfirmation = () => {
        this.setState({
            isShowAcceptDialog: false,
        });
    };

    footerAcceptDocumentAcceptDialog = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowAcceptDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.changeStatus}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    previewBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.INTERVIEWS_VIEW}>
                        <span
                            onClick={() => {
                                showPreviewDoc(rowData.interviewProofURL);
                            }}
                            data-pr-tooltip="Screenshot"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    onFilterChange = (e) => {
        this.setState({ selectedStatus: e.value });
        this.props.getInterviewsDropdown({ status: e.value });
    };

    handleCustomDateChange = (name, value) => {
        this.setState({ [name]: value });
    };

    handleApplyCustomDate = () => {
        const { customStartDate, customEndDate } = this.state;
        if (customStartDate && customEndDate) {
            this.props.getInterviewsDropdown({
                startDate: customStartDate,
                endDate: customEndDate,
            });
        }
    };
    showConsultant = (rowData) => {
        this.setState({
            selectedConsultant: rowData.consultant,
            displayDialog: true,
        });
    };

    consultantDialogFooter = () => (
        <div>
            <Button
                label="Close"
                icon="pi pi-times"
                onClick={() => this.setState({ displayDialog: false })}
            />
        </div>
    );
    consultantBody = (rowData) => {
        return (
            <span onClick={() => this.showConsultant(rowData)}>
                {rowData?.consultant?.name}
            </span>
        );
    };
    render() {
        const { selectedStatus, customStartDate, customEndDate } = this.state;
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Interviews</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={PERMISSIONS.INTERVIEWS_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.INTERVIEWS_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.INTERVIEWS_DOWNLOAD}
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const statusOptions = [
            { label: "Today", value: "today" },
            { label: "Tomorrow", value: "tomorrow" },
            { label: "Next Two Days", value: "next_two_days" },
            { label: "Next Three Days", value: "next_three_days" },
            { label: "Next Seven Days", value: "next_seven_days" },
            { label: "Next Fifteen Days", value: "next_fifteen_days" },
            { label: "Custom Date", value: "custom" },
        ];

        const items = [{ label: "Admin" }, { label: "Interviews" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        const { id } = this.props?.params;
        return (
            <div className="h-screen overflow-auto ss-data-table">
                <Card>
                    <BreadCrumb model={items} home={home} />
                    <Toolbar
                        className="sp-toolbar sp-mt20 sp-py3 sp-px3"
                        right={() => {
                            return (
                                <>
                                    <div className="p-col m-3">
                                        <Dropdown
                                            value={this.props.selectedStatus}
                                            options={statusOptions}
                                            onChange={this.onFilterChange}
                                            placeholder="Select a status"
                                        />
                                    </div>
                                    {selectedStatus === "custom" && (
                                        <div className="p-grid p-dir-col p-m-3">
                                            <label
                                                className="p-mb-2"
                                                htmlFor="startDate"
                                            >
                                                Start Date
                                            </label>
                                            <Calendar
                                                id="startDate"
                                                value={customStartDate}
                                                onChange={(e) =>
                                                    this.handleCustomDateChange(
                                                        "customStartDate",
                                                        e.value
                                                    )
                                                }
                                                dateFormat="mm/dd/yy"
                                                showIcon
                                                className="p-mb-3 m-3 "
                                            />

                                            <label
                                                className="p-mb-2 m-3"
                                                htmlFor="endDate"
                                            >
                                                End Date
                                            </label>
                                            <Calendar
                                                id="endDate"
                                                value={customEndDate}
                                                onChange={(e) =>
                                                    this.handleCustomDateChange(
                                                        "customEndDate",
                                                        e.value
                                                    )
                                                }
                                                dateFormat="mm/dd/yy"
                                                showIcon
                                                className="p-mb-3"
                                            />

                                            <button
                                                onClick={
                                                    this.handleApplyCustomDate
                                                }
                                                className="p-button  mb-3 ml-3 "
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                        left={() => <></>}
                    ></Toolbar>
                    <Authorize permId={PERMISSIONS.INTERVIEWS_LIST}>
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.props.interviewDropdown}
                            lazy
                            scrollHeight="400px"
                            header={header}
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.props.lazyParams.first}
                            last={this.props.totalRecords}
                            rows={this.props.lazyParams.rows}
                            totalRecords={this.props.totalRecords}
                            paginator
                            paginatorLeft
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[
                                5, 10, 25, 50, 100, 200, 300, 500,
                            ]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            dataKey="_id"
                            selectionMode="single"
                        >
                            <Column
                                field="interviewDate"
                                header="Interview"
                                body={(rowData) =>
                                    moment(rowData.interviewFrom).format("LLL")
                                }
                            ></Column>
                            <Column
                                field="inteviewTimeFrom"
                                header="Interview To"
                                body={(rowData) =>
                                    moment(rowData.interviewTo).format("LLL")
                                }
                            ></Column>
                            <Column
                                field="interviewRound"
                                header="Interview Round"
                            ></Column>
                            <Column
                                field="screemshotURL"
                                header="Preview Doc"
                                body={(rowData) => this.previewBody(rowData)}
                            ></Column>

                            <Column
                                header="Consultant"
                                body={this.consultantBody}
                            ></Column>
                        </DataTable>
                    </Authorize>

                    <Dialog
                        visible={this.state.displayDialog}
                        draggable={false}
                        header="Consultant Details"
                        modal={true}
                        style={{ width: "50vw", height: "20vw" }}
                        onHide={() => this.setState({ displayDialog: false })}
                        footer={this.consultantDialogFooter}
                    >
                        {this.state.selectedConsultant && (
                            <>
                                <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                    <div className="col-12 md:col-6 p-3">
                                        <div className="text-500 font-medium mb-2">
                                            Name
                                        </div>
                                        <div className="text-900">
                                            {this.state.selectedConsultant.name}
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-6 p-3">
                                        <div className="text-500 font-medium mb-2">
                                            Email
                                        </div>
                                        <div className="text-900">
                                            {
                                                this.state.selectedConsultant
                                                    .email
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-6 p-3">
                                        <div className="text-500 font-medium mb-2">
                                            Mobile
                                        </div>
                                        <div className="text-900">
                                            {
                                                this.state.selectedConsultant
                                                    .mobile
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-6 p-3">
                                        <div className="text-500 font-medium mb-2">
                                            Status
                                        </div>
                                        <div className="text-900">
                                            {
                                                this.state.selectedConsultant
                                                    .status
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-6 p-3">
                                        <div className="text-500 font-medium mb-2">
                                            Role Preferred
                                        </div>
                                        <div className="text-900">
                                            {
                                                this.state.selectedConsultant
                                                    .rolePrefered
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Dialog>

                    {this.state.isShowInteviewStatus && (
                        <InterviewStatus
                            interview={this.state.editData}
                            onHide={() => {
                                this.setState({
                                    isShowInteviewStatus: false,
                                    editData: null,
                                });
                            }}
                        />
                    )}
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    interviewDropdown: state?.interviewDropdown?.interviewDropdown,
    totalRecords: state?.interviewDropdown?.totalRecords,
    lazyParams: state?.interviewDropdown?.lazyParams,
    isLoading: state?.interviewDropdown?.isLoading,
    selectedStatus: state?.interviewDropdown?.selectedStatus,
});

export default BaseComponent(InterviewsDropdown, mapStateToProps, {
    getInterviewsDropdown,
    resetLazyInterviewsDropdown,
});
