import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";

import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import BaseComponent from "../../common/BaseComponent";
import { InputTextarea } from "primereact/inputtextarea";
import {
    getApprovedQuestions,
    resetLazyApprovedQuestions,
    deleteApprovedQuestion,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class ApprovedQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editQuestion: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedQuestion: "",
            questions: [],
            rejectionReason: "",
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    ApprovedQuestions = (search) => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            search: search,
        });
        const url = `/consultants/approvedquestion?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(
                url,
                {
                    search,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        questions: res.res.data,
                        isLoading: false,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/approved-questions");
        }
    }
    componentDidMount() {
        if (!this.props.approvedQuestions?.length) {
            this.props.getApprovedQuestions();
        }
    }
    onGlobalSearch = (e) => {
        this.props.getApprovedQuestions({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getApprovedQuestions({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getApprovedQuestions({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getApprovedQuestions({ lazyParams: event });
    };

    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editQuestion: null,
            visible: false,
        });
    };

    onQuestionAddOrUpdate = () => {
        this.setState(
            {
                visible: false,
                editQuestion: null,
            },
            this.getQuestions
        );
    };
    deleteTable = () => {
        if (!this.state.rejectionReason) {
            this.setState({ showRejectionReasonAlert: true });
            return;
        }

        this.setState({
            isShowConfirmationDialog: false,
        });

        const _id = this.state.selectedId;
        const reason = this.state.rejectionReason;
        this.props.deleteApprovedQuestion({ reason: reason, id: _id });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3"
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                        rejectionReason: "",
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );
    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
            rejectionReason: "",
        });
    };
    showDeleteQuestionDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
            rejectionReason: "",
        });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteQuestion}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );
    actionsBody = (rowData) => {
        return (
            <Authorize permId={PERMISSIONS.APPROVED_QUESTION_BANK_DELETE}>
                <div className="p-grid row">
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() =>
                                this.showDeleteTableDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Question"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </div>
            </Authorize>
        );
    };
    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                Approved Question Bank
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.APPROVED_QUESTION_BANK_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.APPROVED_QUESTION_BANK_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.APPROVED_QUESTION_BANK_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const items = [
            { label: "Admin" },
            { label: "Question Bank" },
            { label: "Approved Questions" },
        ];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Authorize
                            permId={PERMISSIONS.APPROVED_QUESTION_BANK_LIST}
                        >
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                header={header}
                                value={this.props.approvedQuestions}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedQuestion}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedQuestion: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="question"
                                    header="Question"
                                ></Column>
                                <Column field="answer" header="Answer"></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>
                </div>
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                        draggable={false}
                    >
                        <div>
                            <p>Deleted Question can't be restored.</p>
                            <InputTextarea
                                value={this.state.rejectionReason}
                                onChange={(e) =>
                                    this.setState({
                                        rejectionReason: e.target.value,
                                    })
                                }
                                rows={3}
                            />
                            {this.state.showRejectionReasonAlert && (
                                <div style={{ color: "red" }}>
                                    Deletion reason is required.
                                </div>
                            )}
                        </div>
                    </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isLoading ||
                    (this.props.isLoading && <LoadingComponent />)}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    approvedQuestions: state?.approvedQuestions?.approvedQuestions,
    totalRecords: state?.approvedQuestions?.totalRecords,
    lazyParams: state?.approvedQuestions?.lazyParams,
    isLoading: state?.approvedQuestions?.isLoading,
    consultantAddedOrUpdated:
        state?.approvedQuestions?.consultantAddedOrUpdated,
});

export default BaseComponent(ApprovedQuestions, mapStateToProps, {
    getApprovedQuestions,
    resetLazyApprovedQuestions,
    deleteApprovedQuestion,
});
