import { ACTIONS } from "../actionTypes";
import axios from "axios";
import { SERVERLESS_URL } from "./../../Service/config";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../Utils/constants";
import { logOut, removeNullUndefinedKeys } from "../../Utils";

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "-createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
};

const dispatchRequestApprovedQuestions = (data) => ({
    type: ACTIONS.REQUEST_APPROVED_QUESTIONS,
    data,
});

const dispatchReceivedApprovedQuestions = (data) => ({
    type: ACTIONS.RECEIVED_APPROVED_QUESTIONS,
    data,
});

const dispatchErrorApprovedQuestions = (err) => ({
    type: ACTIONS.ERROR_APPROVED_QUESTIONS,
    err,
});

const dispatchClearApprovedQuestions = () => ({
    type: ACTIONS.CLEAR_ORG_APPROVED_QUESTIONS,
});


const dispatchReceivedDeleteApprovedQuestions = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_APPROVED_QUESTIONS,
    data,
});

export const getApprovedQuestions = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().approvedQuestions;
    console.log(currentState, "action consultant");
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams),
        };
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    dispatch(dispatchRequestApprovedQuestions(data));

    axios({
        method: "post",
        url:
            `${SERVERLESS_URL}/consultants/approvedquestion?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` + // Include globalSearch if it exists
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedApprovedQuestions(res.data));
            } else {
                dispatch(
                    dispatchErrorApprovedQuestions({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorApprovedQuestions(e));
        });
};
export const deleteApprovedQuestion = (data) => (dispatch) => {
    axios({
        method: "post",
        url: `${SERVERLESS_URL}/consultants/questionbank/delete`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data: data
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {

                dispatch(dispatchReceivedDeleteApprovedQuestions(data));
            } else {
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
        });
};
export const resetLazyApprovedQuestions = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestApprovedQuestions(intialTableFilter));
    axios({
        method: "post",
        url: `${SERVERLESS_URL}/consultants/approvedquestion?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedApprovedQuestions(res.data));
            } else {
                dispatch(
                    dispatchErrorApprovedQuestions({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorApprovedQuestions(e));
        });
};

export const clearConsltants = () => dispatchClearApprovedQuestions();
