import React, { Component } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import { BasicLazyParams, } from "../../../Utils/constants";
import BaseComponent from "../../common/BaseComponent";
import ResourceSelection from "./resourceSelection";
import { logOut } from "../../../Utils";

class AddPlanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planDetails: {},
            updatedDetails: [],

            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }

    formPlanDetails = (planDetailsData, days) => {
        let _days = parseInt(days);
        let obj = {};
        if (
            planDetailsData &&
            Object.keys(planDetailsData).length &&
            _days > 0
        ) {
            obj = { ...planDetailsData };
        } else {
            for (let i = 1; i <= _days; i++) {
                obj[`a${i}`] = [];
            }
        }
        return obj;
    };

    getLearningPlanById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/learning-plans/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    // debugger
                    const { planDetails, days } = res.res.data;
                    const updatedPlanDetails = this.formPlanDetails(
                        planDetails,
                        days
                    );
                    this.setState(
                        {
                            planDetails: updatedPlanDetails,
                            isLoading: false,
                        },
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    updateResource = () => {
        let { id } = this.props?.params;
        const requestBody = {
            planDetails: this.state.planDetails,
            _id: id,
        };
        let urlEnd = "/consultants/learning-plans";
        const url = `${urlEnd}`;
        this.serverlessService
            .put(url, requestBody, true)
            .then((res) => {
                this.setState(() => {

                    this.props.history.goBack()
                })
                if (res) {
                } else {
                    this.setState({
                        isLoading: false,
                    },);
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.id) {
            this.getLearningPlanById(this.props?.params.id);
        }
    }

    handleSelectedResourcesChange = (updatedResources) => {
        this.setState(
            (prevState) => ({
                planDetails: {
                    ...prevState.planDetails,
                    ...updatedResources,
                },
            }),
            () => {
            }
        );
    };
    handleDeletedResourcesChange = (updatedResources) => {
        this.setState(
            (prevState) => ({
                planDetails: {
                    ...prevState.planDetails,
                    ...updatedResources,
                },
            })
        );
    };

    render() {
        const { planDetails } = this.state;

        return (
            <div className="h-screen overflow-auto ss-data-table">
                {Object.keys(planDetails).map((day) => (
                    <ResourceSelection
                        key={day}
                        day={day}
                        resources={planDetails[day]}
                        onSelectedResourcesChange={
                            this.handleSelectedResourcesChange
                        }
                        onDeletedResourcesChange={
                            this.handleDeletedResourcesChange
                        }
                        updateResource={this.updateResource}
                    />
                ))}

                <div className="mt-3 mb-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.push("/admin/learning-plans");
                        }}
                    />
                    <Button
                        label="Save"
                        onClick={this.updateResource}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default BaseComponent(AddPlanDetails);
