import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut, trimObj } from "../../../Utils";
import { FeeDays } from "../../../Utils/constants";

import {
    getFormFields,
    isFormValid,
    onDropDownChange,
    onEmailChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
import FeeDetailsList from "../feeDetails";
// import AssignTable from "../Tables/assignTable";
import CustomerFields from "./customers.json";

class AddCustomer extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            CustomerFields,
            this.props.editCustomer || {
                // "name": "erewrew",
                // "fatherName": "rerewre",
                // "permanentAddress": "rerewrew",
                // "adhar": "332423443232",
                // "mobile": "3243443433",
                // "email": "dfdfd@gmail.com",
                // "currentAddress": "rwerrewewe"
            }
        );
        this.state = {
            customer: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
            feeDetailsList: [],
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let customer = trimObj(this.state.customer);

        const formStatus = isFormValid(
            CustomerFields,
            this.formFields.formValidations,
            customer
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editCustomer) {
                this.setState({
                    isLoading: true,
                });

                const url = `/co-space/customers`;
                this.serverlessService
                    .put(url, this.state.customer, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    customer: {
                                        name: "",
                                        fatherName: "",
                                        permanentAddress: "",
                                        adhar: "",
                                        mobileNumber: "",
                                        emailId: "",
                                        currentAddress: "",
                                        assigned: false,
                                        assignedTable: null,
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.onCustomerAddOrUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/co-space/customers/add`;
                this.serverlessService
                    .post(url, this.state.customer, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    customer: {
                                        name: "",
                                        fatherName: "",
                                        permanentAddress: "",
                                        adhar: "",
                                        mobileNumber: "",
                                        emailId: "",
                                        currentAddress: "",
                                        assigned: false,
                                        assignedTable: null,
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onCustomerAddOrUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    showAssign = () => {
        this.setState({
            isShowAssign: true,
            data: this.state.customer,
        });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />
            {/* <Button
                label="Assign"
                className=" p-button-primary "
                onClick={() => this.setState}
            /> */}
            <Button
                label={this.props.editCustomer ? "Update" : "Add"}
                onClick={this.addOrEdit}
                autoFocus
                className="p-button-primary "
            />
        </div>
    );

    onDaysSelect = (e) => {
        let fee = this.state.customer?.feeType?.fees?.find(
            (a) => a.id === e.value
        )?.fee;

        this.setState((prevState) => {
            return {
                assignData: {
                    ...prevState.assignData,
                    days: e.value,
                    fee: fee,
                },
            };
        });
    };

    componentDidMount() {
        this.getFeeDetails();
    }

    getFeeDetails = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            feeDetailsList: [],
        });

        const url = `/co-space/feeDetails`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        feeDetailsList: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    render() {
        const { customer, formValidations } = this.state;
        console.log(FeeDays, "Feedays");
        console.log(this.state.feeDetailsList, "fee details list");

        return (
            <div>
                <Dialog
                    header={
                        this.props.editCustomer
                            ? "Edit Customer"
                            : "Add Customer"
                    }
                    visible={true}
                    style={{ width: "65vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                    draggable={false}
                >
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                {" "}
                                Name
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={customer.name}
                            />

                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["name"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Father Name
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "fatherName",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.customer.fatherName}
                            />
                            {formValidations &&
                                !formValidations.fields["fatherName"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["fatherName"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Permanent Address
                            </label>
                            <InputTextarea
                                rows={1}
                                cols={30}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "permanentAddress",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.customer.permanentAddress}
                            />
                            {formValidations &&
                                !formValidations.fields["permanentAddress"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "permanentAddress"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Aadhar
                            </label>

                            <InputTextarea
                                rows={1}
                                cols={30}
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "adhar",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.customer.adhar}
                            />
                            {formValidations &&
                                !formValidations.fields["adhar"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["adhar"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Mobile Number
                            </label>
                            <InputTextarea
                                rows={1}
                                cols={30}
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "mobile",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.customer.mobile}
                            />
                            {formValidations &&
                                !formValidations.fields["mobile"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["mobile"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Email
                            </label>
                            <InputTextarea
                                rows={1}
                                cols={30}
                                onChange={(e) => {
                                    onEmailChange(
                                        e.target.value,
                                        "email",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.customer.email}
                            />
                            {formValidations &&
                                !formValidations.fields["email"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["email"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Current Address
                            </label>
                            <InputTextarea
                                rows={1}
                                cols={30}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "currentAddress",
                                        this,
                                        CustomerFields,
                                        customer,
                                        formValidations,
                                        "customer",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.customer.currentAddress}
                            />
                            {formValidations &&
                                !formValidations.fields["currentAddress"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "currentAddress"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                    </div>

                    {this.state.isShowAssign && (
                        <div>
                            <label className="font-bold block mb-2">Days</label>
                            <Dropdown
                                onChange={this.onDaysSelect}
                                value={this.state.assignData.days}
                                options={FeeDays}
                                optionValue="id"
                                optionLabel="days"
                                placeholder="Select a day"
                                className="w-full md:w-14rem"
                            />
                            <br></br>
                            <br></br>
                        </div>
                    )}
                </Dialog>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default AddCustomer;
