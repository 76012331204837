import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Service from './../../../Service';
import { saveUserSession } from './../../../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import LoadingComponent from './../../../LoadingComponent';
import ServerlessService from './../../../Service/ServerlessService';
import { logOut } from '../../../Utils';




class VerifyOTP extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowVerifyOtp: false,
            otp: null,
            otpErrMsg: null,
            otpResentCount: 0
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    resendOTP = () => {

        if (this.state.otpResentCount < 3) {



            this.setState({
                isLoading: true
            });

            const url = `/admin/resend-otp`;

            this.serverlessService.post(url, { _id: this.props.userId }, true).then((res) => {
                if (res && res.status && res.res.status) {

                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            otpResentCount: prevState.otpResentCount + 1
                        };
                    });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'OTP sent successfully.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'OTP resent limit exceeded, please try later.', life: 3000 });
        }
    }


    onVerify = () => {
        let { otp } = this.state;

        if (otp && otp.length == 6) {
            this.setState({
                isLoading: true
            });
            const url = `/admin/verify`;

            this.serverlessService.post(url, { otp, _id: this.props.userId }, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.saveUserSession(res.res);
                        this.props.history.push('/')
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })



        } else {
            this.setState({
                otpErrMsg: 'Invalid OTP.'
            })


        }
    }


    render() {
        const { otp, otpErrMsg } = this.state;
        return (<>
            <div className=''>
                <div>
                    <div className="flex align-items-center justify-content-between mb-7">
                        <span className="text-2xl font-medium text-900">Login to {this.props.orgName}</span>
                    </div>

                    <p>Please enter OTP recieved on your registered Phone.</p>
                    <InputText value={otp}
                        type="text"
                        min={6}
                        max={6}
                        maxLength={6}
                        minLength={6}
                        autoComplete="new-password"
                        className='w-full '
                        onChange={(e) => {
                            this.setState({
                                otp: e.target.value
                            })
                        }} />
                    {otpErrMsg && <p className="p-error">{otpErrMsg}</p>}
                    <div>
                        <p className='font-medium my-3 cursor-pointer transition-colors transition-duration-150'>OTP not recieved? <span className='text-primary' onClick={this.resendOTP} >Resend</span></p>
                    </div>
                    <Button label="Verify & Login" onClick={this.onVerify} className="w-full mt-4 py-3 font-medium" />


                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>)
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(VerifyOTP)));
