import { Button } from "primereact/button";
import React, { Component } from "react";
import ServerlessService from "../../../../Service/ServerlessService";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import BaseComponent from "../../../common/BaseComponent";
import moment from "moment-timezone";
import { timeZones } from "../../../../Utils/constants";
import './styles.scss'
import { logOut } from "../../../../Utils";

class ViewConsultant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultant: null,
        };

        this.serverlessService = new ServerlessService();
    }


    getScreeningCall = (_id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/screeningcalls/id/${_id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            consultant: res.res.data,
                        },
                        () => {
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount = () => {

        if (this.props.params) this.getScreeningCall(this.props.params.id);
    };

    getTimeZoneFullForm = (abbreviation) => {
        const timeZone = timeZones.find((zone) => zone.value === abbreviation);

        const fullForm = timeZone ? timeZone.label : abbreviation;

        return fullForm;
    };
    getTimeInDifferentTimeZone(time, sourceTimeZone, targetTimeZone) {
        const targetTime = moment.tz(time, sourceTimeZone).tz(targetTimeZone);
        return targetTime.format("hh:mm A");
    }
    mapTimeZoneAbbreviationToIdentifier(abbreviation) {
        switch (abbreviation) {
            case "IST":
                return "Asia/Kolkata";
            case "PST":
                return "America/Los_Angeles";
            case "CST":
                return "America/Chicago";
            case "EST":
                return "America/New_York";
            default:
                return abbreviation;
        }
    }

    render() {
        const consultant = this.state?.consultant?.[0];


        return (
            <>
                <div className="p-grid p-fluid">
                    <div className="h-screen overflow-auto ss-data-table">
                        <Button
                            icon="pi pi-arrow-left"
                            className="p-button-rounded   back-arrow"
                            onClick={() => this.props.history.goBack()}
                        />
                        {consultant && (
                            <div className="m-6">
                                <div className="font-medium text-3xl text-900 mb-3">
                                    Screening calls
                                </div>
                                <div className="p-grid row">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block ">
                                            Consultant Name
                                        </label>
                                        <p className="text-lg">
                                            {consultant.createdBy?.name}
                                        </p>
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block ">
                                            Technology
                                        </label>
                                        <p className="text-lg">
                                            {consultant.technology}
                                        </p>
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block ">
                                            Consultant TimeZone
                                        </label>
                                        <p className="text-lg">
                                            {this.getTimeZoneFullForm(
                                                consultant.inteviewTimeZone
                                            )}
                                        </p>
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block ">
                                            Time
                                        </label>
                                        <p className="text-lg">
                                            {moment(consultant.time).format(
                                                "LT"
                                            )}
                                        </p>
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block ">
                                            Vendor
                                        </label>
                                        <p className="text-lg">
                                            {consultant.vendor}
                                        </p>
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block ">
                                            Recruiter Location
                                        </label>
                                        <p className="text-lg">
                                            {consultant.location}
                                        </p>
                                    </div>

                                    {timeZones.map((zone) => (
                                        <div
                                            key={zone.value}
                                            className="col-12 md:col-4 lg:col-4"
                                        >
                                            <label className="font-bold block">
                                                {zone.label}
                                            </label>
                                            <p className="text-lg">
                                                {this.getTimeInDifferentTimeZone(
                                                    consultant.time,
                                                    this.mapTimeZoneAbbreviationToIdentifier(
                                                        consultant.inteviewTimeZone
                                                    ),
                                                    this.mapTimeZoneAbbreviationToIdentifier(
                                                        zone.value
                                                    )
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>

                                <br />
                            </div>
                        )}
                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                        {(this.state.isLoading || this.props.isLoading) && (
                            <LoadingComponent />
                        )}
                    </div>
                </div>

            </>
        );
    }
}

export default BaseComponent(ViewConsultant);
