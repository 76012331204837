import { ACTIONS } from "../actionTypes";
import axios from "axios";
import { SERVERLESS_URL } from "../../Service/config";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../Utils/constants";
import { CodeGuruReviewer } from "aws-sdk";
import { logOut, removeNullUndefinedKeys } from "../../Utils";

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
};

const dispatchRequestInterviewsDropdown = (data) => ({
    type: ACTIONS.REQUEST_INTERVIEWS_DROPDOWN,
    data,
});

const dispatchReceivedInterviewsDropdown = (data) => ({
    type: ACTIONS.RECEIVED_INTERVIEWS_Dropdown,
    data,
});

const dispatchErrorInterviewsDropdown = (err) => ({
    type: ACTIONS.ERROR_INTERVIEWS_Dropdown,
    err,
});

const dispatchClearInterviewsDropdown = () => ({
    type: ACTIONS.CLEAR_INTERVIEWS_Dropdown,
});

export const getInterviewsDropdown = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().interviews;
    console.log(currentState, "currentstate");
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams),
        };
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck");
    const { status, startDate, endDate } = data;
    dispatch(dispatchRequestInterviewsDropdown(data));

    axios({
        method: "post",
        url:
            `${SERVERLESS_URL}/consultants/interviews/status/?` +
            `${status ? `status=${status}&` : ""}` +
            `${startDate ? `startDate=${startDate}&` : ""}` +
            `${endDate ? `endDate=${endDate}&` : ""}` +
            `limit=${lazyParams.rows}&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data: data
    })
        .then((res) => {
            _data = res.data;
            console.log(res.data, "documentEditing action");
            if (res && res.data) {
                dispatch(dispatchReceivedInterviewsDropdown(res.data));
            } else {
                dispatch(
                    dispatchErrorInterviewsDropdown({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorInterviewsDropdown(e));
        });
};

export const resetLazyInterviewsDropdown = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestInterviewsDropdown(intialTableFilter));
    axios({
        method: "post",
        url:
            `${SERVERLESS_URL}/consultants/interviews/status/?` +
            `${status ? `status=${status}&` : ""}` +
            `${startDate ? `startDate=${startDate}&` : ""}` +
            `${endDate ? `endDate=${endDate}&` : ""}` +
            `limit=${lazyParams.rows}&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedInterviewsDropdown(res.data));
            } else {
                dispatch(
                    dispatchErrorInterviewsDropdown({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorInterviewsDropdown(e));
        });
};

export const clearConsltants = () => dispatchClearInterviewsDropdown();
