import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { CodeGuruReviewer } from 'aws-sdk';
import { logOut, removeNullUndefinedKeys } from '../../Utils';



const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}




const dispatchRequestInterviews = data => ({
    type: ACTIONS.REQUEST_INTERVIEWS,
    data
}

);

const dispatchReceivedInterviews = (data) => ({
    type: ACTIONS.RECEIVED_INTERVIEWS,
    data
}
);

const dispatchErrorInterviews = err => ({
    type: ACTIONS.ERROR_INTERVIEWS,
    err
});

const dispatchClearInterviews = () => ({
    type: ACTIONS.CLEAR_INTERVIEWS
});


const dispatchReceivedAddInterviews = (data) => ({
    type: ACTIONS.RECEIVED_ADD_INTERVIEWS,
    data,
});

const dispatchReceivedUpdateInterviews = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_CONSULTANTS,
    data,
});
const dispatchReceivedDeleteInterviews = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_CONSULTANTS,
    data,
});
const dispatchErrorAddInterviews = (err) => ({
    type: ACTIONS.ERROR_ADD_INTERVIEWS,
    err,
});

export const addInterviews = (data) => (dispatch) => {

    axios({
        method: data?._id ? "put" : "post",
        url: `${SERVERLESS_URL}/consultants/interviews${data?._id ? "" : "/add"
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data,
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {
                if (data?._id) {
                    dispatch(dispatchReceivedUpdateInterviews(res.data?.data));
                } else {
                    dispatch(dispatchReceivedAddInterviews(res.data?.data));
                }
            } else {
                dispatch(
                    dispatchErrorAddInterviews({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400) {
                logOut()
            }
            dispatch(dispatchErrorAddInterviews(e));
        });
};


export const getInterviews = (data) => (dispatch, getState) => {

    let _data;

    const currentState = getState().interviews;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")
    const { consultant } = data
    dispatch(dispatchRequestInterviews(data));

    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/interviews/${consultant}?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedInterviews(res.data));
        } else {
            dispatch(dispatchErrorInterviews({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorInterviews(e));
    });
}

export const resetLazyInterviews = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestInterviews(intialTableFilter));
    axios({
        method: 'post',
        url: `/consultants/interviews/${_consultant._id}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedInterviews(res.data));
        } else {
            dispatch(dispatchErrorInterviews({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorInterviews(e));
    });
}


export const clearConsltants = () => dispatchClearInterviews();