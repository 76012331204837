import React, { Component } from "react";
import { connect } from "react-redux";
import Authentication from "./../../../session/index";
import ServerlessService from "../../../Service/ServerlessService";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

import { countWords } from "../../../Utils";
import moment from "moment";
import {  getNotes,addNote,  getConsltantTags } from "../../../store/actions";
import NoteEntryComponent from "./noteEntryComponent";
import BaseComponent from "../../common/BaseComponent";
import { DataView } from "primereact/dataview";
class ConsultantNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            multipleSuggestions: [],
            allTags: ["resume", "keys"],
            allNames: [
                {
                    name: "shashi",
                },
                {
                    name: "shekar",
                },

                {
                    name: "shekar",
                },
            ],
            note: "",
            isStopQuery: false,
            currentPage: 1,
            totalRecords: 0,
            visible: true,
        };

        this.serverlessService = new ServerlessService();
    }

    getConsultantNotes = (e) => {

       
        this.props.getNotes(e);
    };

    componentDidMount = () => {
       
            this.getConsultantNotes(this.props.consultant._id);
    };

    componentDidUpdate(prevProps) {

        if (prevProps.uuid !== this.props.uuid) {
            this.setState({
                note: ''
            });
        }
    }

    commentTemplate = (data) => {
        return (
            <div className="mb-5 flex">
                <div
                    className="flex flex-column align-items-center"
                    style={{ width: "2rem" }}
                >
                    <span
                        className=" text-0 flex align-items-center justify-content-center border-circle"
                        style={{ minWidth: "2.5rem", minHeight: "2.5rem" }}
                    >
                        <img
                            src="assets/images/avatar.jpeg"
                            className="w-2rem"
                        />
                    </span>
                    <div
                        className="h-full bg-blue-500"
                        style={{ width: "2px", minHeight: "4rem" }}
                    ></div>
                </div>
                <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                    <div className="mb-3">
                        <span className="text-900 font-medium inline-block mr-3">
                            {data?.createdBy?.name}
                        </span>
                        <span className="text-500 text-sm">
                            {moment(data.createdAt).fromNow()}
                        </span>
                    </div>
                    <div className="line-height-3 text-700 mb-3">
                        {data.note}
                    </div>
                   
                </div>
            </div>
        );
    };
    
    onPage = (event) => {
        this.props.getNotes(this.props.consultant._id, { lazyParams: event });
    };
    saveComment = () => {
        let thisObj = this;


        if (!this.state.note || this.state.note == "") {
            return thisObj.toast.show({
                severity: "error",
                summary: "Enter some messagge",
                detail: "Please type some message you want to send",
                life: 3000,
            });
        }

        if (countWords(this.state.note) > 200) {
            return thisObj.toast.show({
                severity: "error",
                summary: "Invalid length",
                detail: "More than 200 words can not be allowed ",
                life: 3000,
            });
        }

       

        this.props.addNote({
            note: thisObj.state.note,
            consultant: this.props?.consultant?._id,
        })
        return;
       
    };

    loadNext = () => {
        return (
            <div className="ml-6">
                <div className="grid">
                    <div className="col-12">
                        <div className="grid formgrid p-fluid">
                            <div className="field col-12">
                                <div className="p-d-flex p-flex-column p-ai-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-d-flex p-flex-column p-ai-center">
                    <Skeleton width="450px" height="170px" />
                    <Skeleton width="70px" height="40px" className="mt-2" />
                </div>
                <div className="mt-5">
                    <div className="overflow-y-auto surface-overlay p-3 border-round" style={{ height: "500px" }}>
                        <div className="p-d-flex p-flex-column p-ai-center">
                            <div className="flex flex-row flex-wrap">
                                <Skeleton shape="circle" size="50px" className="mr-5" />
                                <Skeleton width="300px" height="150px" className="mb-5" />
                            </div>
                            <div className="p-d-flex p-flex-row p-ai-center mt-3">
                                <div className="flex flex-row flex-wrap">
                                    <Skeleton shape="circle" size="50px" className="mr-5" />
                                    <Skeleton width="300px" height="150px" />
                                </div>
                            </div>
                            <div className="p-d-flex p-flex-row p-ai-center mt-3">
                                <div className="flex flex-row flex-wrap">
                                    <Skeleton shape="circle" size="50px" className="mr-5" />
                                    <Skeleton width="300px" height="150px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        const { visible } = this.state;
       

       
        return (
            <Sidebar
                visible={visible}
                position="right"
                style={{ width: "40em" }}
                onHide={this.props.onHide}
            >
              
         {this.state.isLoading ? (
                    this.loadNext()
                ) : (
                    <div className="ml-6">
                        <div className="grid ">
                            <div className="col-12">
                                <div className="grid formgrid p-fluid">
                                    <div className="field col-12">
                                        <label
                                            htmlFor="bio1"
                                            className="font-medium text-900"
                                        >
                                            Note
                                        </label>

                                        <NoteEntryComponent
                                            onChange={(e) => {
                                                this.setState({
                                                    note: e.target.value,
                                                });
                                            }}
                                            value={this.state.note}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            label="Save"
                            onClick={this.saveComment}
                            className="w-auto"
                        />
                        <div className="mt-5">
                            <h3 className="mb-2"> Previous Notes</h3>
                            <div
                                className="overflow-y-auto surface-overlay p-3  border-round"
                                style={{ height: "500px" }}
                            >

                                <DataView value={this.props.notes} layout={'list'}
                                    itemTemplate={this.commentTemplate} paginator
                                    gutter={false}
                                    lazy
                                    first={this.props.lazyParams.first}
                                    last={this.props.totalRecords}
                                    rows={this.props.lazyParams.rows}
                                    onPage={this.onPage}
                                    totalRecords={this.props.totalRecords}
                                    className="consultant-faqs"

                                />


                                {this.state.totalRecords >
                                    this.state.notes.length && (
                                        <Button
                                            type="text"
                                            label="Load"
                                            onClick={this.getConsultantNotes}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>)}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
               
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
    consulantTagPeoples: state?.consultantsTagging?.consultants,
    consulantTagKeywords: state?.consultantsTagging?.tags,
    notes: state?.notes?.notes,
    totalRecords: state?.notes?.totalRecords,
    lazyParams: state?.notes?.lazyParams,
    isLoading: state?.notes?.isLoading,
    isAddLoading: state?.notes?.isAddLoading,
    uuid: state?.notes?.uuid,
});


export default BaseComponent(
    connect(mapStateToProps, {
        getConsltantTags, getNotes,addNote
    })(Authentication(ConsultantNotes))
);
