import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut } from "../../../Utils";

class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.serverlessService = new ServerlessService();
    }

    orderDetails = () => {

        this.setState({
            isLoading: true,
            order: null,
        });

        const url = `/orders/${this.props.id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                console.log(res, "response");

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        order: res.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footer = () => {
        return (
            <Button label="Cancel" onClick={() => this.props.onHide()}></Button>
        );
    };

    componentDidMount() {
        this.orderDetails();
    }
    render() {
        console.log(this.state.order, "order details");
        const { order } = this.state;
        return (
            <div>
                <Dialog
                    visible={true}
                    header="Order Details"
                    style={{ width: "50vw" }}
                    onHide={this.props.onHide}
                    footer={this.footer}
                >
                    <span className="text-1000 font-medium text-3xl  mb-3">
                        Billing Details{" "}
                    </span>
                    <br />
                    <div className="row surface-card p-4  border-round">
                        <label className="col-6 ">
                            <b>Name - </b>
                            {order?.billingDetails?.name}
                        </label>
                        <label className="col-6 ">
                            <b>Email - </b>
                            {order?.billingDetails?.email}
                        </label>
                        <label className="col-6">
                            <b>Country - </b>
                            {order?.billingDetails?.country}
                        </label>
                        <label className="col-6">
                            <b>Surname - </b>
                            {order?.billingDetails?.surName}
                        </label>
                        <label className="col-6">
                            <b>Apartment - </b>
                            {order?.billingDetails?.apartment}
                        </label>
                        <label className="col-6">
                            <b>Postalcode - </b>
                            {order?.billingDetails?.postalCode}
                        </label>
                        <label className="col-6">
                            <b>City - </b>
                            {order?.billingDetails?.city}
                        </label>
                        <label className="col-6">
                            <b>Phone - </b>
                            {order?.billingDetails?.phone}
                        </label>
                    </div>


































                    <span className="text-1000 font-medium text-3xl  mb-3">
                        Package Details{" "}
                    </span>
                    <br />
                    <div className="row surface-card p-4  border-round">
                        <label className="col-6 ">
                            <b>Id - </b>
                            {order?.selectedPackage?.id}
                        </label>
                        <label className="col-6 ">
                            <b>Pakage Name - </b>
                            {order?.selectedPackage?.packageName}
                        </label>
                        <label className="col-6">
                            <b>Type - </b>
                            {order?.selectedPackage?.type}
                        </label>
                        <label className="col-6">
                            <b>Credits - </b>
                            {order?.selectedPackage?.credits}
                        </label>
                        <label className="col-6">
                            <b>Days - </b>
                            {order?.selectedPackage?.days}
                        </label>
                        <label className="col-6">
                            <b>Price - </b>
                            {order?.selectedPackage?.price}
                        </label>
                    </div>




                </Dialog>
            </div>
        );
    }
}

export default OrderDetails;
