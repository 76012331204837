import React, { Component } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import { Chip } from "primereact/chip";
import { Dialog } from "primereact/dialog";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getCopyHelper,
    resetLazyCopyHelper,
    deleteCopyhelper,
} from "../../../store/actions/copy-helperActions";
import { logOut } from "../../../Utils";

class CopyHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            copiedIndex: null,
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            consultantId: "",
            consultant: "",
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    onTableAddOrUpdate = () => {
        this.setState({
            visible: false,
            editData: null,
        });
    };

    deleteTable = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        this.props.deleteCopyhelper({ _id });
    };
    getCopyHelper = (_id) => {
        let urlEnd = `/consultants/consultant/id/${_id}`;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `${urlEnd}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultant: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                    // this.toast.show({
                    //     severity: "success",
                    //     summary: "Consultant Info Fetched Successfully",
                    //     detail: res.errMessage,
                    //     life: 3000,
                    // });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidMount() {
        if (this.props?.params?.id) {
            this.setState({
                consultantId: this.props.params.id,
            });
        }

        this.props.getCopyHelper({ consultant: this.props.params.id });
        this.getCopyHelper(this.props.params.id);
    }

    footerContentfotDelete = () => {
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onCloseConfirmation}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable()}
                autoFocus
                className=" p-button-primary "
            />
        </div>;
    };

    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row m-2">
                <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                    <div className="col-1 ml-2 mr-2">
                        <span
                            onClick={() => {
                                const _rowData = { ...rowData };
                                this.props.history.push(
                                    `/admin/copy-helper/edit/${rowData._id}`,
                                    { fromOperation: "edit" }
                                );
                            }}
                            data-pr-tooltip="Edit Info"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                    <div className="col-1 ml-2 mr-2">
                        <span
                            onClick={() =>
                                this.showDeleteTableDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Info"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };
    handleCopy = (text, index) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.setState({ copiedIndex: index });
                setTimeout(() => {
                    this.setState({ copiedIndex: null });
                }, 2000);
            })
            .catch((err) => {
                console.error("Error copying text: ", err);
            });
    };

    handleMouseEnter = (index) => {
        this.setState({ hoveredIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoveredIndex: null });
    };
    render() {
        const { copiedIndex, consultantId } = this.state;
        const { copyHelper } = this.props;

        const consultantElements = copyHelper?.map((consultant, index) => (
            <li
                key={index}
                className={
                    index % 2 === 0
                        ? "flex align-items-center py-3 px-2 flex-wrap surface-ground"
                        : "flex align-items-center py-3 px-2 flex-wrap"
                }
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={() => this.handleMouseLeave(index)}
                style={{
                    backgroundColor:
                        this.state.hoveredIndex === index
                            ? "#f0f0f0"
                            : "transparent",
                }}
            >
                <div className="flex w-full">
                    <div className="text-500 w-full md:w-6 font-medium">
                        {consultant.question}
                    </div>
                    <div
                        className="text-900 w-full md:w-2 line-height-3"
                        style={{ position: "relative" }}
                    >
                        <div
                            className={`tooltip-target-${index}`}
                            onClick={() =>
                                this.handleCopy(consultant.answer, index)
                            }
                        >
                            {consultant.answer}
                        </div>

                        <Tooltip
                            target={`.tooltip-target-${index}`}
                            content="Click To Copy"
                            position="top"
                        />

                        {copiedIndex === index && (
                            <Chip
                                className="ml-2 p-chip-success"
                                icon="pi pi-copy"
                                label="Copied"
                                style={{
                                    backgroundColor: "#4CAF50",
                                    color: "white",
                                }}
                            />
                        )}
                    </div>
                    <div className="text-900 w-full md:w-2 line-height-3">
                        {this.actionsBody(consultant)}
                    </div>
                </div>
            </li>
        ));

        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <div className="font-bold block mb-2">
                        <h1>{`${this.state.consultant.name} Details`}</h1>
                    </div>

                    <Card>
                        <Authorize permId={PERMISSIONS.CONSULTANTS_CREATE}>
                            <Button
                                label="New"
                                icon="pi pi-plus"
                                className="m-3"
                                outlined
                                aria-label="Create"
                                onClick={() =>
                                    this.props.history.push(
                                        `/admin/copy-helper/add/${consultantId}`
                                    )
                                }
                            />
                        </Authorize>
                        <ul>{consultantElements}</ul>
                    </Card>
                </div>

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                        draggable={false}
                    >
                        <p>Deleted ConsultantInfo can't be restored.</p>
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.props.isLoading ||
                    (this.state.isLoading && <LoadingComponent />)}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    copyHelper: state?.copyhelper?.copyHelper,
    totalRecords: state?.copyhelper?.totalRecords,
    lazyParams: state?.copyhelper?.lazyParams,
    isLoading: state?.copyhelper?.isLoading,
    consultantAddedOrUpdated: state?.copyhelper?.consultantAddedOrUpdated,
});

export default BaseComponent(CopyHelper, mapStateToProps, {
    getCopyHelper,
    resetLazyCopyHelper,
    deleteCopyhelper,
});
