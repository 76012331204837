import React, { Component } from "react";
import { Button } from "primereact/button";
import ServerlessService from "../../../../Service/ServerlessService";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import moment from "moment-timezone";
import BaseComponent from "../../../common/BaseComponent";
import { timeZones } from "../../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../../session/authorize";
import { logOut } from "../../../../Utils";
class ViewConsultant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultant: null,
        };

        this.serverlessService = new ServerlessService();
    }

    getInterview = (consultantId) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/interviews/id/${consultantId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultant: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount = () => {
        if (this.props?.params?.id) {
            this.getInterview(this.props?.params.id);
        }
    };
    previewBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                        <span
                            onClick={() => {
                                const urlToOpen = rowData.submissionProofURL;

                                window.open(urlToOpen, "_blank");
                            }}
                            data-pr-tooltip="Preview Doc"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    getTimeZoneFullForm = (abbreviation) => {
        const timeZone = timeZones.find((zone) => zone.value === abbreviation);

        const fullForm = timeZone ? timeZone.label : abbreviation;

        return fullForm;
    };

    getDateTimeInDifferentTimeZone = (
        date,
        time,
        sourceTimeZoneAbbreviation,
        targetTimeZoneIdentifier
    ) => {
        const interviewDateTime = moment.tz(
            `${date} ${time}`,
            "YYYY-MM-DD hh:mm A",
            sourceTimeZoneAbbreviation
        );

        if (!interviewDateTime.isValid()) {
            return "Invalid Date";
        }

        const targetDateTime = interviewDateTime
            .clone()
            .tz(targetTimeZoneIdentifier);

        return targetDateTime.format("DD-MM-YYYY hh:mm A");
    };

    mapTimeZoneAbbreviationToIdentifier(abbreviation) {
        switch (abbreviation) {
            case "IST":
                return "Asia/Kolkata";
            case "PST":
                return "America/Los_Angeles";
            case "CST":
                return "America/Chicago";
            case "EST":
                return "America/New_York";

            default:
                return abbreviation;
        }
    }

    render() {
        const { consultant } = this.state;

        return (
            <div className="h-screen overflow-auto ss-data-table">
                {" "}
                {consultant && (
                    <div className="p-grid p-fluid">
                        <div className="font-medium text-3xl text-900 mb-3">
                            Interview Details
                        </div>
                        <div className="grid grid-nogutter">
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Client
                                </label>
                                <p className="text-lg">{consultant.client}</p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview Date
                                </label>
                                <p className="text-lg">
                                    {" "}
                                    {moment(consultant.interviewDate).format(
                                        "MMMM Do YYYY"
                                    )}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview From
                                </label>
                                <p className="text-lg">
                                    {moment(consultant.interviewFrom).format(
                                        "LT"
                                    )}
                                </p>
                            </div>

                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview To
                                </label>
                                <p className="text-lg">
                                    {moment(consultant.inteviewTimeTo).format(
                                        "LT"
                                    )}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview Mode
                                </label>
                                <p className="text-lg">
                                    {consultant.interviewMode}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview ProofURL
                                </label>
                                <p className="text-lg">
                                    {this.previewBody(consultant)}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview Round
                                </label>
                                <p className="text-lg">
                                    {consultant.interviewRound}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Interview Type
                                </label>
                                <p className="text-lg">
                                    {consultant.interviewType}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Inteview Time From
                                </label>
                                <p className="text-lg">
                                    {moment(
                                        consultant.interviewTimeFrom
                                    ).format("LT")}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Inteview Time To
                                </label>
                                <p className="text-lg">
                                    {moment(consultant.inteviewTimeTo).format(
                                        "LT"
                                    )}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Inteview TimeZone
                                </label>
                                <p className="text-lg">
                                    {this.getTimeZoneFullForm(
                                        consultant.inteviewTimeZone
                                    )}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    JobRole
                                </label>
                                <p className="text-lg">{consultant.jobRole}</p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Recruiter Email
                                </label>
                                <p className="text-lg">
                                    {consultant.recruiterEmail}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Recruiter Name
                                </label>
                                <p className="text-lg">
                                    {consultant.recruiterName}
                                </p>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <label className="font-bold block ">
                                    Recruiter Phone
                                </label>
                                <p className="text-lg">
                                    {consultant.recruiterPhone}
                                </p>
                            </div>
                            {timeZones.map((zone) => (
                                <div
                                    key={zone.value}
                                    className="col-12 md:col-6 p-3"
                                >
                                    <label className="font-bold block">
                                        {zone.label}
                                    </label>
                                    <p>
                                        {this.getDateTimeInDifferentTimeZone(
                                            consultant.interviewDate,
                                            consultant.interviewFrom,

                                            this.mapTimeZoneAbbreviationToIdentifier(
                                                consultant.inteviewTimeZone
                                            ),
                                            this.mapTimeZoneAbbreviationToIdentifier(
                                                zone.value
                                            )
                                        )}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                <div className="mb-3 ">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default BaseComponent(ViewConsultant);
