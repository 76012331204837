import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from './../../Service/config';
import { logOut, removeNullUndefinedKeys } from '../../Utils';
const dispatchRequestOrgData = data => ({
    type: ACTIONS.REQUEST_CONSULTANT_TAGS,
    data
});

const dispatchReceivedOrgData = (data) => ({
    type: ACTIONS.RECEIVED_CONSULTANT_TAGS,
    data
});

const dispatchErrorOrgData = err => ({
    type: ACTIONS.ERROR_CONSULTANT_TAGS,
    err
});

const dispatchClearOrgData = () => ({
    type: ACTIONS.CLEAR_ORG_CONSULTANT_TAGS
});







export const getConsltantTags = (withoutMeluhaBoard) => dispatch => {
    let _data;

    dispatch(dispatchRequestOrgData(withoutMeluhaBoard));
    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/consultant/tags`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        _data = res.data

        if (res && res.data && res.data.data && res.data.data) {
            const {
                tags,
                consultants
            } = res.data.data;

            dispatch(dispatchReceivedOrgData({
                tags,
                consultants
            }));
        } else {
            dispatch(dispatchErrorOrgData({
                message: 'Some error occurred'
            }));
        }



    }).catch(e => {
        if (e?.response?.status > 400) {
            logOut()
        }
        dispatch(dispatchErrorOrgData(e));
    });
}


export const clearConsltantTags = () => dispatchClearOrgData();

