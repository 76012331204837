import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Chart } from "primereact/chart";
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from "../../../Utils";

class CreatedByListCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            chartData: null,
            isLoading: false,
            UsersList: [],
            SelectedUser: "",
        };
        this.serverlessService = new ServerlessService();
    }
    getUsers = () => {
        const urlEnd = "/admin/all";
        const requestBody = {

        }
        this.serverlessService
            .post(urlEnd, requestBody, true)
            .then((res) => {
                console.log(res);
                if (res && res.status && res.res.status) {
                    this.setState({
                        UsersList: res.res.data,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.error("Error fetching recruiters:", e);
            });
    };
    componentDidMount() {
        this.getUsers();
        // this.getConsultants();
    }

    getConsultants = (id) => {
        let urlEnd = "/consultants/consultant/createdby";

        this.setState({
            isLoading: true,
        });

        this.serverlessService
            .post(urlEnd, { userId: id }, true)
            .then((res) => {
                console.log(res)
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                    });
                    this.prepareChartData(res.res.data);
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    // Handle error
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                // Handle error
            });
    };

    handleUserChange = (e) => {
        const SelectedUser = e.value;
        console.log(SelectedUser._id)
        this.setState({ SelectedUser }, () => {
            // Fetch consultant data for the selected recruiter
            this.getConsultants(SelectedUser._id);
        });
    };
    prepareChartData(consultants) {
        const statusCounts = {};

        // Count profiles for each status category
        consultants.forEach((consultant) => {
            const status = consultant.status;
            if (statusCounts[status]) {
                statusCounts[status]++;
            } else {
                statusCounts[status] = 1;
            }
        });

        // Prepare chart data
        const chartData = {
            labels: Object.keys(CONSULTANT_STATUS).map(
                (status) => CONSULTANT_STATUS[status]
            ),
            datasets: [
                {
                    label: "Total Profiles",
                    backgroundColor: "#42A5F5",
                    data: Object.values(statusCounts),
                },
            ],
        };

        this.setState({ chartData });
    }

    render() {
        const { UsersList, SelectedUser } = this.state;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <h2>Total Profiles by Status Category</h2>
                    <div className="p-field">
                        <label htmlFor="Usre">Select User: </label>
                        <Dropdown
                            id="_id"
                            value={SelectedUser}
                            options={UsersList}
                            onChange={this.handleUserChange}
                            optionLabel="name"
                            placeholder="Select Recruiter"
                        />
                    </div>
                    {this.state.chartData && (
                        <Chart type="bar" data={this.state.chartData} />
                    )}
                </div>
            </div>
        );
    }
}

export default CreatedByListCharts;
