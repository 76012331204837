import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LoadingComponent from "../../../LoadingComponent";

import { Dialog } from "primereact/dialog";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { Dropdown } from "primereact/dropdown";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import { BasicLazyParams } from "../../../Utils/constants";
import AssignBackToContentPre from "./assignContentWriter";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getSalesTeamHead,
    resetLazySalesTeamHead,
} from "../../../store/actions";

import HistoryRecruiter from "./historyRecruiter";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class SalesTeamHead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            statusFilter: CONSULTANT_STATUS.MARKETING,
            selectedStatus: CONSULTANT_STATUS.READY_TO_MARKET,
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    getReadyToMarket = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/consultant/get-ready-to-market?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: this.state.statusFilter }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.salesTeamHeadConsultants?.length) {
            this.props.getSalesTeamHead({ status: this.props.selectedStatus });
        }
    }

    onGlobalSearch = (e) => {
        this.props.getSalesTeamHead({
            globalSearch: e.target.value,
            status: this.props.selectedStatus,
        });
    };

    onPage = (event) => {
        this.props.getSalesTeamHead({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onSort = (event) => {
        this.props.getSalesTeamHead({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getSalesTeamHead({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    showConsultantNotes = (rowData) => {
        this.setState({
            isShowConsultantNotes: true,
            editData: rowData,
        });
    };

    showConsultantMonitor = (rowData) => {
        this.setState({
            isShowConsultantMonitor: true,
            editData: rowData,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize
                    permId={PERMISSIONS.READY_MARKET_ASSIGN_TO_BENCH_SALES}
                >
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowAssignToContentWriter: true,
                                    selectedCustomer: rowData,
                                })
                            }
                            data-pr-tooltip={
                                rowData.marketingAssignedTo
                                    ? "Reassign To Bench Sale Recruiter"
                                    : "Assign To Bench Sale Recruiter"
                            }
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-directions" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.READY_MARKET_SHOW_CONSULTANT}>
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id.substr(4)}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.READY_MARKET_MONITOR}>
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip
                            target={`.tableHistory${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                {this.props.salesTeamHeadConsultants.some(
                    (consultant) => consultant.marketingAssignedTo
                ) && (
                        <Authorize permId={PERMISSIONS.READY_MARKET_SUBMISSIONS}>
                            <div className="col-2 ml-2 mr-2">
                                <span
                                    onClick={() => {
                                        this.props.history.push(
                                            `/admin/submission/${rowData._id}`
                                        );
                                    }}
                                    data-pr-tooltip="Submissions"
                                    data-pr-position="top"
                                    className={`p-text tableHistory${rowData._id.substr(
                                        4
                                    )}`}
                                >
                                    <i className="pi pi-images" />
                                </span>
                                <Tooltip
                                    target={`.tableHistory${rowData._id.substr(4)}`}
                                />
                            </div>
                        </Authorize>
                    )}
                {this.props.salesTeamHeadConsultants.some(
                    (consultant) => consultant.marketingAssignedTo
                ) && (
                        <Authorize permId={PERMISSIONS.READY_MARKET_INTERVIEWS}>
                            <div className="col-2 ml-2 mr-2">
                                <span
                                    onClick={() => {
                                        this.props.history.push(
                                            `/admin/interview/${rowData._id}`
                                        );
                                    }}
                                    data-pr-tooltip="Interviews"
                                    data-pr-position="top"
                                    className={`p-text tableHistory${rowData._id.substr(
                                        4
                                    )}`}
                                >
                                    <i className="pi pi-users" />
                                </span>
                                <Tooltip
                                    target={`.tableHistory${rowData._id.substr(4)}`}
                                />
                            </div>
                        </Authorize>
                    )}

                {this.props.salesTeamHeadConsultants.some(
                    (consultant) => consultant.marketingAssignedTo
                ) && (
                        <Authorize permId={PERMISSIONS.READY_MARKET_TRACKER}>
                            <div className="col-2 ml-2 mr-2">
                                <span
                                    onClick={() => {
                                        this.props.history.push(
                                            `/admin/marketinghistory/${rowData._id}`
                                        );
                                    }}
                                    data-pr-tooltip="Assigned Recruiters History"
                                    data-pr-position="top"
                                    className={`p-text tableHistory${rowData._id.substr(
                                        4
                                    )}`}
                                >
                                    <i className="pi pi-building" />
                                </span>
                                <Tooltip
                                    target={`.tableHistory${rowData._id.substr(4)}`}
                                />
                            </div>
                        </Authorize>
                    )}
                <Authorize permId={PERMISSIONS.READY_MARKET_NOTES}>
                    <div className="col-2 ml-2 mr-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip
                            target={`.tableHistory${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
            </div>
        );
    };

    showNotes = (rowData) => {
        this.setState({
            isShowNotes: true,
            editData: rowData,
        });
    };

    onDocumentAccepted = (files) => {
        this.setState((prevState) => {
            return {
                consultants: prevState.consultants.map((a) => {
                    if (prevState.editData._id == a._id) {
                        a.files = files;
                    }
                    return a;
                }),
                editData: {
                    ...prevState.editData,
                    files: files,
                },
            };
        });
    };

    onFilterChange = (e) => {
        this.setState({ selectedStatus: e.value });
        this.props.getSalesTeamHead({ status: e.value });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.READY_MARKET_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.READY_MARKET_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.READY_MARKET_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );

        const statusOptions = [
            { label: "In Marketing", value: CONSULTANT_STATUS.READY_TO_MARKET },
            { label: "Assigned", value: CONSULTANT_STATUS.MARKETING },
        ];

        const items = [{ label: "Admin" }, { label: "Sales head" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Toolbar
                            className="sp-toolbar sp-mt20"
                            right={() => {
                                return (
                                    <Authorize
                                        permId={PERMISSIONS.READY_MARKET_FILTER}
                                    >
                                        <div className="p-col">
                                            <Dropdown
                                                value={
                                                    this.props.selectedStatus
                                                }
                                                options={statusOptions}
                                                onChange={this.onFilterChange}
                                                placeholder="Select a status"
                                            />
                                        </div>
                                        <div className="p-col"></div>
                                    </Authorize>
                                );
                            }}
                            left={() => {
                                return <></>;
                            }}
                        ></Toolbar>

                        <Authorize permId={PERMISSIONS.READY_MARKET_LIST}>
                            <div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.props.salesTeamHeadConsultants}
                                    lazy
                                    header={header}
                                    scrollHeight="400px"
                                    onSort={this.onSort}
                                    onPage={this.onPage}
                                    onFilter={this.onFilter}
                                    first={this.props.lazyParams.first}
                                    last={this.props.totalRecords}
                                    rows={this.props.lazyParams.rows}
                                    totalRecords={this.props.totalRecords}
                                    paginator
                                    paginatorLeft
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50, 100, 200, 300, 500,
                                    ]}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    scrollable
                                    responsiveLayout="scroll"
                                    rowHover
                                    dataKey="_id"
                                    selection={this.state.selectedCustomer}
                                    onSelectionChange={(e) =>
                                        this.setState({
                                            selectedCustomer: e.value,
                                        })
                                    }
                                    selectionMode="single"
                                >
                                    <Column
                                        field="name"
                                        header="Full Name"
                                    ></Column>
                                    <Column
                                        field="mobile"
                                        header="Phone"
                                    ></Column>
                                    <Column
                                        field="email"
                                        header="Email"
                                    ></Column>
                                    <Column
                                        field="rolePrefered"
                                        header="Role"
                                    ></Column>
                                    <Column
                                        field="marketingAssignedTo.name"
                                        header="Recruiter"
                                        body={({ marketingAssignedTo }) =>
                                            marketingAssignedTo
                                                ? marketingAssignedTo?.name
                                                : "NA"
                                        }
                                    ></Column>
                                    <Column
                                        header="Actions"
                                        body={(rowData) =>
                                            this.actionsBody(rowData)
                                        }
                                    ></Column>
                                </DataTable>

                                {this.state.isShowAssignToContentWriter && (
                                    <AssignBackToContentPre
                                        isAssingToPreperationTeam={
                                            this.state.isAssingToPreperationTeam
                                        }
                                        selectedCustomer={
                                            this.state.selectedCustomer
                                        }
                                        onCancel={() => {
                                            this.setState({
                                                isShowAssignToContentWriter: false,
                                                selectedCustomer: null,
                                            });
                                        }}
                                        onHide={() => {
                                            let thisObj = this;

                                            this.setState(
                                                {
                                                    isShowAssignToContentWriter: false,
                                                    selectedCustomer: null,
                                                },
                                                () => {
                                                    thisObj.toast.show({
                                                        severity: "success",
                                                        summary: this.state
                                                            .selectedCustomer
                                                            ?.marketingAssignedTo
                                                            ? "User Reassigned To Recruiter"
                                                            : "User Assigned To Recruiter",
                                                        detail: "User Assigned Successfully",
                                                        life: 3000,
                                                    });
                                                    thisObj.props.getSalesTeamHead(
                                                        {
                                                            status: this.state
                                                                .selectedStatus,
                                                        }
                                                    );
                                                }
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </Authorize>
                    </Card>
                </div>

                {this.state.isShowHistory && (
                    <HistoryRecruiter
                        consultantId={this.state.editData?._id}
                        onDocumentAccepted={this.onDocumentAccepted}
                        consultant={this.state.selectedCustomer}
                        isDocVerifier={true}
                        onUploaded={this.proonDocumentUploaded}
                        onChangeStatusToInProgress={
                            this.onChangeStatusToInProgress
                        }
                        onHide={() => {
                            this.setState({
                                isShowHistory: false,
                            });
                        }}
                    />
                )}

                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        visible={true}
                        style={{ width: "49vw" }}
                        draggable={false}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToDocumentPreparator}</p>
                    </Dialog>
                )}

                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    salesTeamHeadConsultants: state?.salesTeamHead?.salesTeamHeadConsultants,
    totalRecords: state?.salesTeamHead?.totalRecords,
    lazyParams: state?.salesTeamHead?.lazyParams,
    isLoading: state?.salesTeamHead?.isLoading,
    selectedStatus: state?.salesTeamHead?.selectedStatus,
});

export default BaseComponent(SalesTeamHead, mapStateToProps, {
    getSalesTeamHead,
    resetLazySalesTeamHead,
});
