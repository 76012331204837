import React, { Component } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Service from "./../../../Service";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import Authentication from "./../../../session/index";
import { BasicLazyParams } from "./../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { logOut, warningDailogInit } from "./../../../Utils";
import CreateAdminUser from "./createAdminUser";
import Authorize, { PERMISSIONS } from "./../../../session/authorize";
import ServerlessService from "./../../../Service/ServerlessService";
import LoadingComponent from "./../../../LoadingComponent";
import { Card } from "primereact/card";
import { BreadCrumb } from "primereact/breadcrumb";

class AdminUserPrivileges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddAdminUser: false,
            appointments: [],
            lazyParams: {
                ...BasicLazyParams,
                sortField: "-createdAt",
                sortOrder: 1,
            },
            globalSearch: "",
            isShowAdminUser: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            assignedMails: [],
            removeAssignEmail: null,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    showAdminUser = () => {
        this.setState({ isShowAddAdminUser: true, editAdminUser: false });
    };

    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {
                currentFilter: currentFilter,
                lazyParams: BasicLazyParams,
            };
        }, this.getAppointments);
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getAppointments);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getAppointments);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getAppointments);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getAppointments);
    };

    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: [],
        });
        const url = `/admin/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: this.state.currentFilter }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        appointments: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    editAdminUser = (adminUser) => {
        this.setState({
            isLoading: true,
        });
        const url = `/admin/get-user-privileges?id=${adminUser._id}`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isShowAddAdminUser: true,
                        editAdminUser: {
                            ...adminUser,
                            ...res.res.data
                        },
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    deleteAdminUser = (rowData) => {
        let data = { ...rowData };

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You want delete the adminUser, it can't be undone once deleted`,
            },
            editAdminUser: data,
        });
    };

    getUserAssignedMails = (userId) => {
        this.setState({
            isLoading: true,
            assignedMails: [],
        });
        const url = `/admin/assigned-mails/${userId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        assignedMails: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    assignEmail = (editAdminUser) => {
        this.setState(
            {
                isShowAssignEmail: true,
                editAdminUser: editAdminUser,
            },
            () => {
                this.getUserAssignedMails(editAdminUser._id);
            }
        );
    };

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true,
        });

        const url = `/admin/delete`;
        this.serverlessService
            .post(url, { _id: this.state.editAdminUser._id }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            editAdminUser: null,
                            isLoading: false,
                        },
                        () => {
                            this.getAppointments();
                        }
                    );

                    this.toast.show({
                        severity: "success",
                        summary: "Success",
                        detail: "AdminUsers deleted successfully.",
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    actionsTemplate = (rowData) => {
        return (
            <>

                <Authorize permId={PERMISSIONS.USER_PRIVILEGE_EDIT}>
                    <span
                        data-pr-tooltip="Edit Privileges"
                        data-pr-position="bottom"
                        className={` cursor-pointer edit${rowData._id.substr(2)}`}
                        onClick={() => {
                            this.editAdminUser(rowData);
                        }}
                    >
                        <i className="pi pi-pencil" />
                    </span>
                    <Tooltip
                        className=""
                        autoHide={false}
                        target={`.edit${rowData._id.substr(2)}`}
                    />
                </Authorize>

            </>
        );
    };

    onSaveAdminUser = (isEdit) => {
        this.toast.show({
            severity: "success",
            summary: `${isEdit ? "Added" : "Modified"}`,
            detail: `AdminUser ${isEdit ? "Edited" : "Created"} Succesfully.`,
            life: 3000,
        });

        this.setState(
            {
                isShowAddAdminUser: false,
                lazyParams: BasicLazyParams,
            },
            () => {
                this.getAppointments();
            }
        );
    };

    componentDidMount() {
        this.getAppointments();
    }

    removeassign = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You want remove assigned  ${rowData.email} email. Please confirm`,
            },
            removeAssignEmail: rowData,
        });
    };

    onConfirmChange2 = () => {
        this.setState({
            isLoading: true,
            warningDialog2: warningDailogInit,
        });
        const url = `/admin/removeAssign-mail`;
        this.serverlessService
            .post(
                url,
                {
                    userId: this.state.editAdminUser._id,
                    emailId: this.state.removeAssignEmail._id,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        this.getUserAssignedMails(this.state.editAdminUser._id)
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header" >Admin User Privileges</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize permId={PERMISSIONS.USER_PRIVILEGE_GENERAL_SEARCH} >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && <InputText placeholder="Search" className=""
                                                value={this.state.globalSearch}
                                                onChange={this.onGlobalSearch} />}
                                        </li>
                                    </Authorize>
                                    <Authorize permId={PERMISSIONS.USER_PRIVILEGE_GENERAL_SEARCH} >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                                        </li>
                                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ss-tbl-srch" />
                                    </Authorize>
                                    <Authorize permId={PERMISSIONS.USER_PRIVILEGE_GENERAL_DOWNLOAD} >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld" onClick={this.exportCSV}></i>
                                        </li>
                                        <Tooltip className="table-li-tooltip" target=".ss-tbl-dwnld" />
                                    </Authorize>



                                </ul>


                            </ul>
                        </li>
                    </ul>
                </div >
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4">

                    </div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 ">

                    </div>

                </div>
            </div >
        );

        const items = [
            { label: 'Admin' },
            { label: 'Admin' },
            { label: 'Admin Users Privileges' },

        ];

        const home = { icon: 'pi pi-home', url: 'http://localhost:3000/#/admin' }

        return (
            <>
                <div className='h-screen overflow-auto ss-data-table'>
                    <Card >

                        <BreadCrumb model={items} home={home} />
                        <Authorize permId={PERMISSIONS.USER_PRIVILEGE_LIST}>
                            <div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    scrollable
                                    responsiveLayout="scroll"
                                    rowHover
                                    header={header}
                                    value={this.state.appointments}
                                    dataKey="_id"
                                    lazy
                                    paginatorLeft
                                    onSort={this.onSort}
                                    onPage={this.onPage}
                                    onFilter={this.onFilter}
                                    first={this.state.lazyParams.first}
                                    last={this.state.totalRecords}
                                    rows={this.state.lazyParams.rows}
                                    totalRecords={this.state.totalRecords}
                                    paginator
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50, 100, 200, 300, 500,
                                    ]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    globalFilter={this.state.globalFilter}
                                >
                                    <Column field="name" header="Name" />
                                    <Column field="email" header="Email" />
                                    <Column field="phoneNumber" header="Phone" />
                                    <Column
                                        sortabl
                                        body={this.actionsTemplate}
                                        header="Actions"
                                    ></Column>
                                </DataTable>
                            </div>
                        </Authorize>
                    </Card>



                </div>
                {this.state.isShowAddAdminUser && (
                    <CreateAdminUser
                        adminUser={this.state.editAdminUser}
                        onHide={() => {
                            this.setState({
                                isShowAddAdminUser: false,
                            });
                        }}
                        onSave={this.onSaveAdminUser}
                    />
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});

export default connect(mapStateToProps, {})(Authentication(AdminUserPrivileges));
