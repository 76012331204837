import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import NavFooter from '../../navFooter'
import Service from '../../../Service';
import { StyleClass } from 'primereact/styleclass';
import ServerlessService from '../../../Service/ServerlessService';
import { BasicLazyParams } from '../../../Utils/constants';
import Checkout from './orderDetails';
import { Divider } from 'primereact/divider';
import { Ripple } from 'primereact/ripple';
import { logOut } from '../../../Utils';

export default class Orders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddProfile: false,
            profiles: [],
            lazyParams: { ...BasicLazyParams, sortField: 'updatedAt', sortOrder: 1 },
            globalSearch: '',
            isCheckout: false
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    getProfiles = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            profiles: []
        });
        const url = `/mvisited/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, {}, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    profiles: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            if (e?.response?.status > 400) {
                logOut()
            }
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
        })
    }


    onPaymentSuccuess(obj) {
        console.log(obj);

    }

    openPaymentWindow = (data) => {
        var thisObj = this;
        let options = {
            "key": data.key,
            "amount": data.paymentInit.amount,
            "name": "Q GROUP MEDIA",
            "currency": "INR",
            "order_id": data.paymentInit.id,
            "description": "Please complete the payment.",
            "handler": function (response) {
                thisObj.onPaymentSuccuess(response);
            },

            "theme": {
                "color": "#ca1c1c"
            }
        };
        var thisWindow = window;
        let rzp = new thisWindow.Razorpay(options);
        rzp.open();
    }


    _submitForm() {
        if (this.isFormValid()) {
            let requestObj = this.state.donationForm;
            requestObj._id = Date().toString();
            if (requestObj._id == '') {
                requestObj._id = null;
            }
            this.setState({
                isLoading: true
            });
            this.service.postDataOrError(URLs.raiseDonationRequest, requestObj, false, false).then((res) => {
                if (res.status && res.statuscode === 23) {
                    this.setState({
                        response: res.data,
                        isLoading: false,
                        showModel: false,
                        donationForm: {
                            _id: this.props.User && this.props.User.User && this.props.User.User._id ? this.props.User.User._id : '',
                            firstName: this.props.User && this.props.User.User && this.props.User.User.firstName ? this.props.User.User.firstName : '',
                            phoneNumber: this.props.User && this.props.User.User && this.props.User.User.phoneNumber ? this.props.User.User.phoneNumber : '',
                            email: this.props.User && this.props.User.User && this.props.User.User.email ? this.props.User.User.email : '',
                            amount: ''
                        }
                    });
                    let data = res.data;

                }
                else {
                    this.setState({
                        isLoading: false,
                        errormsg: res.statusmsg
                    });

                    setTimeout(() => {
                        this.setState({
                            errormsg: ''
                        });
                    }, 5000);
                }
            });
        }
    }


    componentDidMount() {

        let data = {
            "paymentInit": {
                "id": "order_KqpTKdUVB6wTBN",
                "entity": "order",
                "amount": 10000,
                "amount_paid": 0,
                "amount_due": 10000,
                "currency": "INR",
                "receipt": "Matrimony Package",
                "offer_id": null,
                "status": "created",
                "attempts": 0,
                "notes": [],
                "created_at": 1670811084
            },
            "key": "rzp_test_0culdOcTScw84I"
        };


    }



    render() {
        return (
            <NavFooter>
                {this.state.isCheckout ? <Checkout /> :
                    <>
                        <div className="surface-0 px-4 py-8 md:px-6 lg:px-8">
                            <div className="flex flex-column md:flex-row justify-content-between align-items-center mb-4">
                                <div className="flex flex-column text-center md:text-left">
                                    <span className="text-900 text-3xl mb-2">My Orders</span>
                                    <span className="text-600 text-xl">Dignissim diam quis enim lobortis.</span>
                                </div>
                                <span className="p-input-icon-right mt-5 mb-2 md:mt-0 md:mb-0 w-full lg:w-25rem">
                                    <i className="pi pi-search text-gray-400"></i>
                                    <input type="text" className="p-inputtext w-full lg:w-25rem surface-100 surface-border" placeholder="Search" />
                                </span>
                            </div>
                            <div className="surface-card grid grid-nogutter border-round shadow-2">
                                <div className="col-12 flex p-2 surface-100 border-round-top">
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Order Number</span>
                                        <span className="text-900 font-medium block mt-2">45123</span>
                                    </div>
                                    <Divider align="center" layout="vertical" className="h-full mx-0 lg:mx-3" />
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Order Date</span>
                                        <span className="text-900 font-medium block mt-2">7 February 2023</span>
                                    </div>
                                    <Divider align="center" layout="vertical" className="h-full mx-0 lg:mx-3" />
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Total Amount</span>
                                        <span className="text-900 font-medium block mt-2">$123.00</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                            <img src="assets/images/blocks/ecommerce/orderhistory/orderhistory-1-1.png" alt="product" className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                            <div className="flex flex-column my-auto text-center md:text-left">
                                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">Product Name Placeholder A Little Bit Long One</span>
                                                <span className="text-600 text-sm mb-3">White | Small</span>
                                                <a tabIndex="0" className="p-ripple p-2 cursor-pointer w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">Buy Again <span className="font-light">| $12</span>
                                                    <Ripple />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>
                                            <span className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <i className="pi pi-check"></i>
                                            </span>
                                            <span className="text-green-500">Delivered on 7 February 2023</span>
                                        </div>
                                    </div>
                                    <Divider className="w-full block lg:hidden surface-border" />
                                    <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                            <img src="assets/images/blocks/ecommerce/orderhistory/orderhistory-1-2.png" alt="product" className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                            <div className="flex flex-column my-auto text-center md:text-left">
                                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">Product Name Placeholder A Little Bit Long One</span>
                                                <span className="text-600 text-sm mb-3">White | Small</span>
                                                <a tabIndex="0" className="p-ripple p-2 cursor-pointer w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">Buy Again <span className="font-light">| $12</span>
                                                    <Ripple />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>
                                            <span className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <i className="pi pi-check"></i>
                                            </span>
                                            <span className="text-green-500">Delivered on 7 February 2023</span>
                                        </div>
                                    </div>
                                    <Divider className="w-full block lg:hidden surface-border" />
                                    <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                            <img src="assets/images/blocks/ecommerce/orderhistory/orderhistory-1-3.png" alt="product" className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                            <div className="flex flex-column my-auto text-center md:text-left">
                                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">Product Name Placeholder A Little Bit Long One</span>
                                                <span className="text-600 text-sm mb-3">White | Small</span>
                                                <a tabIndex="0" className="p-ripple p-2 cursor-pointer w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">Buy Again <span className="font-light">| $12</span>
                                                    <Ripple />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>
                                            <span className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <i className="pi pi-check"></i>
                                            </span>
                                            <span className="text-green-500">Delivered on 7 February 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0 flex border-top-1 surface-border">
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" style={{ borderBottomLeftRadius: '6px' }}><i className="pi pi-folder mr-2 mb-2 md:mb-0"></i>Archive Order</a>
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"><i className="pi pi-refresh mr-2 mb-2 md:mb-0"></i>Return</a>
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"><i className="pi pi-file mr-2 mb-2 md:mb-0"></i>View Invoice</a>
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" style={{ borderBottomRightRadius: '6px' }}><i className="pi pi-comment mr-2 mb-2 md:mb-0"></i>Write a Review</a>
                                </div>
                            </div>
                            <div className="surface-card grid grid-nogutter mt-5 border-round shadow-2">
                                <div className="col-12 flex p-2 surface-100 border-round-top">
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Order Number</span>
                                        <span className="text-900 font-medium block mt-2">45123</span>
                                    </div>
                                    <Divider align="center" layout="vertical" className="h-full mx-0 lg:mx-3" />
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Order Date</span>
                                        <span className="text-900 font-medium block mt-2">7 February 2023</span>
                                    </div>
                                    <Divider align="center" layout="vertical" className="h-full mx-0 lg:mx-3" />
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Total Amount</span>
                                        <span className="text-900 font-medium block mt-2">$123.00</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                            <img src="assets/images/blocks/ecommerce/orderhistory/orderhistory-1-4.png" alt="product" className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                            <div className="flex flex-column my-auto text-center md:text-left">
                                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">Product Name Placeholder A Little Bit Long One</span>
                                                <span className="text-600 text-sm mb-3">White | Small</span>
                                                <a tabIndex="0" className="p-ripple p-2 cursor-pointer w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">Buy Again <span className="font-light">| $12</span>
                                                    <Ripple />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>
                                            <span className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <i className="pi pi-check"></i>
                                            </span>
                                            <span className="text-green-500">Delivered on 7 February 2023</span>
                                        </div>
                                    </div>
                                    <p-divider className="w-full block lg:hidden surface-border"></p-divider>
                                    <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                            <img src="assets/images/blocks/ecommerce/orderhistory/orderhistory-1-5.png" alt="product" className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                            <div className="flex flex-column my-auto text-center md:text-left">
                                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">Product Name Placeholder A Little Bit Long One</span>
                                                <span className="text-600 text-sm mb-3">White | Small</span>
                                                <a tabIndex="0" className="p-ripple p-2 cursor-pointer w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">Buy Again <span className="font-light">| $12</span>
                                                    <Ripple />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>
                                            <span className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <i className="pi pi-check"></i>
                                            </span>
                                            <span className="text-green-500">Delivered on 7 February 2023</span>
                                        </div>
                                    </div>
                                    <p-divider className="w-full block lg:hidden surface-border"></p-divider>
                                    <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                            <img src="assets/images/blocks/ecommerce/orderhistory/orderhistory-1-6.png" alt="product" className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                            <div className="flex flex-column my-auto text-center md:text-left">
                                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">Product Name Placeholder A Little Bit Long One</span>
                                                <span className="text-600 text-sm mb-3">White | Small</span>
                                                <a tabIndex="0" className="p-ripple p-2 cursor-pointer w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">Buy Again <span className="font-light">| $12</span>
                                                    <Ripple />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>
                                            <span className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <i className="pi pi-check"></i>
                                            </span>
                                            <span className="text-green-500">Delivered on 7 February 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0 flex border-top-1 surface-border">
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" style={{ borderBottomLeftRadius: '6px' }}><i className="pi pi-folder  mr-2 mb-2 md:mb-0"></i>Archive Order</a>
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"><i className="pi pi-refresh  mr-2 mb-2 md:mb-0"></i>Return</a>
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"><i className="pi pi-file  mr-2 mb-2 md:mb-0"></i>View Invoice</a>
                                    <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" style={{ borderBottomRightRadius: '6px' }}><i className="pi pi-comment  mr-2 mb-2 md:mb-0"></i>Write a Review</a>
                                </div>
                            </div>
                        </div>

                    </>

                }



            </NavFooter>
        )
    }
}
