import moment from "moment";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import AssignTable from "../tables/assignTable";

import TableDetails from "./tableDetails";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { logOut } from "../../../Utils";

class Tables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAssigned: false,
        };
        this.serverlessService = new ServerlessService();
    }

    getTables = () => {
        this.setState({
            isLoading: true,
            tableData: [],
        });

        const url = `/co-space/tables`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        tableData: res.res.data,
                        table: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                // console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onTableClick = (table) => {
        if (table.assignedTo) {
            this.setState({
                isShowTableDetails: true,
                isShowTableDetails: true,
                selectedTable: table,
            });
        } else {
            this.setState({
                isShowTableDetails: false,
                isShowAssignDialog: true,
                selectedTable: table,
            });
        }
        // this.setState({
        //   id: _id,
        //   isAssigned: true,
        // });
    };

    onHide = () => {
        this.setState({
            selectedTable: null,
            isShowTableDetails: false,
            isShowAssignDialog: false,
        });
    };

    onSuccess = () => {
        this.setState({
            selectedTable: null,
            isShowTableDetails: false,
            isShowAssignDialog: false,
        });
        this.getTables();
    };

    componentDidMount() {
        this.getTables();
    }

    getClassName = (validTill) => {
        let _validTill = moment(validTill).format("MM/DD/YYYY");
        let currentDay = moment().startOf("day");
        let _currentDay = moment(currentDay).format("MM/DD/YYYY");
        const daysDifference = Math.floor(
            moment(_validTill).diff(_currentDay, "days")
        );

        console.log(daysDifference);
        if (daysDifference > 7) {
            return "green"; // Greater than 1 week, show in green
        } else if (daysDifference >= 3 && daysDifference <= 7) {
            return "blue"; // Greater than 3 days to 1 week, show in blue
        } else if (daysDifference >= 1 && daysDifference < 3) {
            return "orange"; // Greater than 1 day to 3 days, show in yellow
        } else {
            return "red"; // Expired with the current date, show in red
        }
    };

    getColor = (data) => { };

    render() {
        return (
            <>
                <div className="min-h-screen flex relative lg:static ">
                    <div className="min-h-screen flex flex-column relative flex-auto">
                        <div className="flex flex-column flex-auto">
                            <div className="p-5">
                                <div className="grid">
                                    {this.state.tableData?.map((data) => (
                                        <div
                                            className="col-12 lg:col-6 xl:col-3"
                                            key={data._id}
                                            onClick={() =>
                                                this.onTableClick(data)
                                            }
                                        >
                                            <div className="surface-card shadow-3 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div className="pl-8 pt-4">
                                                        <span className="block text-600 font-medium mb-3">
                                                            {data?.name}
                                                        </span>
                                                        <div className="text-1500 font-medium text-xl">
                                                            {data.assignedTo
                                                                ?.name ? (
                                                                data.assignedTo
                                                                    .name
                                                            ) : (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize:
                                                                            "14px",
                                                                    }}
                                                                >
                                                                    Not assigned
                                                                    yet
                                                                </p>
                                                            )}
                                                        </div>
                                                        <span
                                                            style={{
                                                                color: this.getClassName(
                                                                    data
                                                                        .transaction
                                                                        ?.validTill
                                                                ),
                                                            }}
                                                        >
                                                            {data.transaction
                                                                ?.validTill &&
                                                                moment(
                                                                    data
                                                                        .transaction
                                                                        ?.validTill
                                                                ).format(
                                                                    "MM/DD/YYYY"
                                                                )}
                                                        </span>
                                                    </div>
                                                    {/* <div
                                                        className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                        style={{
                                                            width: "2.5rem",
                                                            height: "2.5rem",
                                                        }}
                                                    >
                                                        <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {this.state.isShowTableDetails && (
                                        <Authorize
                                            permId={
                                                PERMISSIONS.DASHBOARD_TABLES_DETAILS
                                            }
                                        >
                                            <TableDetails
                                                onSuccess={this.onSuccess}
                                                onHide={this.onHide}
                                                table={this.state.selectedTable}
                                            ></TableDetails>
                                        </Authorize>
                                    )}

                                    {this.state.isShowAssignDialog && (
                                        <Authorize
                                            permId={
                                                PERMISSIONS.DASHBOARD_ASSIGN
                                            }
                                        >
                                            <AssignTable
                                                editData={
                                                    this.state.selectedTable
                                                }
                                                onHide={() => {
                                                    this.setState({
                                                        isShowAssignDialog: false,
                                                        selectedTable: null,
                                                    });
                                                }}
                                                onSuccessFullyAssigned={() => {
                                                    this.setState(
                                                        {
                                                            isShowAssignDialog: false,
                                                            selectedTable: null,
                                                        },
                                                        this.getTables
                                                    );
                                                }}
                                            />
                                        </Authorize>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Tables;
