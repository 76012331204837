import { Button } from "primereact/button";

import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut, trimObj } from "../../../Utils";

import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import QuestionFields from "./question.json";
import BaseComponent from "../../common/BaseComponent";

class AddQuestion extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            QuestionFields,
            this.props.editQuestion || {}
        );
        this.state = {
            question: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let question = trimObj(this.state.question);

        const formStatus = isFormValid(
            QuestionFields,
            this.formFields.formValidations,
            question
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.params.id) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/faq/update`;
                this.serverlessService
                    .put(url, this.state.question, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    question: {
                                        name: "",
                                        fatherName: "",
                                        permanentAddress: "",
                                        adhar: "",
                                        mobileNumber: "",
                                        emailId: "",
                                        currentAddress: "",
                                        assigned: false,
                                        assignedTable: null,
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.goBack();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/faq/add`;
                this.serverlessService
                    .post(url, this.state.question, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.goBack();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    getFaq = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/faq/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        question: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showAssign = () => {
        this.setState({
            isShowAssign: true,
            data: this.state.question,
        });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getFaq(this.props?.params.id);
            }
        }
    }

    render() {
        const { question, formValidations } = this.state;

        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="font-bold block mb-2">
                    <h1>FAQ</h1>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-12">
                        <label className="font-bold block mb-2">Question</label>
                        <InputTextarea
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "question",
                                    this,
                                    QuestionFields,
                                    question,
                                    formValidations,
                                    "question",
                                    "formValidations"
                                );
                            }}
                            value={question.question}
                        />
                        {formValidations &&
                            !formValidations.fields["question"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["question"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-12">
                        <label className="font-bold block mb-2">Answer</label>
                        <InputTextarea
                            rows={10}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "answer",
                                    this,
                                    QuestionFields,
                                    question,
                                    formValidations,
                                    "question",
                                    "formValidations"
                                );
                            }}
                            value={question.answer}
                        />
                        {formValidations &&
                            !formValidations.fields["answer"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["answer"].errorMsg}
                                </p>
                            )}
                    </div>
                </div>
                <div className="mt-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <Button
                        label={
                            this.props?.params?.operation == "edit"
                                ? "Update"
                                : "Add"
                        }
                        onClick={this.addOrEdit}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default BaseComponent(AddQuestion);
