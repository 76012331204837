import React, { Component } from "react";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";
import moment from "moment";
import { Ripple } from "primereact/ripple";
import AddProfile from "../myaccount/addProfile";
import ProfileAttachment from "./profileAttachment";
import { Button } from "primereact/button";
import { logOut } from "../../../Utils";

export default class ViewFullProfie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            isEdit: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }
    componentDidMount() {
        this.getProfileById();
    }

    getProfileById = () => {

        this.setState({
            isLoading: true,
            profile: {},
        });
        const url = `/mprofiles/${this.props.isVisited ? 'visitedbyid' : 'byid'}?id=${this.props.selectedProfileId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profile: res.res.data,
                    });

                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    editProile = () => {
        this.setState({
            isEdit: true,
        });
    };

    onAfterProfiledAdded = () => {
        this.setState({
            isEdit: false,
        },
            this.getProfileById
        );
    };

    seeContactDetails = () => {
        this.setState({
            isLoading: true,
        });
        const url = `/mprofiles/contact-details?id=${this.props.profile._id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profile: res.res.data,
                        isShowContactDetails: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        console.log(this.state, "state-viewprofile");
        const { profile } = this.state;
        return (
            <div className="layout-content w-80">
                <div className="grid ">
                    <div className="col-12">
                        <Button
                            onClick={this.props.onBack}
                            icon="pi pi-arrow-left"
                            label="Back"
                        />
                    </div>
                </div>

                {this.state.isEdit ? (
                    <AddProfile
                        onCancel={() => {
                            this.setState({
                                isEdit: false,
                            });
                        }}
                        editProfile={this.state.profile}
                        onAfterProfiledAdded={this.onAfterProfiledAdded}
                    />
                ) : (
                    <div className="surface-card p-4 shadow-2 border-round">
                        <div className="grid grid-nogutter surface-border pt-2">
                            <div className="col-12 md:col-6 p-3">
                                <div className="flex align-items-center">
                                    <img
                                        src={
                                            CLOUDFRONT_ENDPOINT +
                                            (profile.profileUrl?.key
                                                ? profile.profileUrl?.key
                                                : "")
                                        }
                                        style={{ height: "300px" }}
                                        alt="avatar-f-4"
                                        className="mr-4"
                                    />
                                </div>
                            </div>
                            <div className="col-12 md:col-6 ">
                                <div className="text-500 pt-3 font-medium mb-2">
                                    Name
                                    {!this.props.hideEdit &&
                                        !this.props.isEditHide && (
                                            <button
                                                type="text"
                                                onClick={() => {
                                                    this.editProile();
                                                }}
                                                className="p-ripple p-link w-3rem h-3rem surface-0 hover:surface-200 border-circle shadow-2 inline-flex align-items-center justify-content-center  transition-colors transition-duration-300"
                                                style={{
                                                    top: "1rem",
                                                    right: "1rem",
                                                    float: "right",
                                                }}
                                            >
                                                <i className="pi pi-pencil text-2xl text-500"></i>
                                                <Ripple />
                                            </button>
                                        )}
                                </div>
                                <div className="text-900 ">
                                    {profile.firstName} {profile.lastName}
                                </div>
                                <div className="text-500 pt-3  font-medium mb-2">
                                    Age
                                </div>
                                <div className="text-900">{profile.age}</div>
                                <div className="text-500 pt-3 font-medium mb-2">
                                    Profession
                                </div>
                                <div className="text-900">
                                    {profile.profession}
                                </div>
                                <div className="text-500 pt-3 font-medium mb-2">
                                    Salary (Month)
                                </div>
                                <div className="text-900">{profile.salary}</div>
                            </div>
                        </div>
                        <div className="grid grid-nogutter  pt-2">
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 pt-3 font-medium mb-2">
                                    Higher Education
                                </div>
                                <div className="text-900">
                                    {profile.education}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Date of Birth
                                </div>
                                <div className="text-900">
                                    {moment(profile.dateOfBirth).format("LLL")}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Gender
                                </div>
                                <div className="text-900">{profile.gender}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Language
                                </div>
                                <div className="text-900">
                                    {profile.language}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Religion
                                </div>
                                <div className="text-900">
                                    {profile.religion}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Caste
                                </div>
                                <div className="text-900">{profile.caste}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Sub Caste
                                </div>
                                <div className="text-900">
                                    {profile.subCaste}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Caste
                                </div>
                                <div className="text-900">{profile.color}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Sub Caste
                                </div>
                                <div className="text-900">{profile.height}</div>
                            </div>
                            {profile.additionalInfo && (
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Additional Info
                                    </div>
                                    <div className="text-900">
                                        {profile.additionalInfo}
                                    </div>
                                </div>
                            )}
                        </div>
                        <h3 className=" ">Contact Details</h3>
                        <div className="grid grid-nogutter border-top-1  pt-2">
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    State
                                </div>
                                <div className="text-900">{profile.state}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Country
                                </div>
                                <div className="text-900">{profile.gender}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    City
                                </div>
                                <div className="text-900">{profile.city}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Address
                                </div>
                                <div className="text-900">
                                    {profile.address}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Phone
                                </div>
                                <div className="text-900">
                                    {profile.phoneNumber}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                                <div className="text-500 font-medium mb-2">
                                    Email
                                </div>
                                <div className="text-900">{profile.email}</div>
                            </div>
                        </div>
                        <div>
                            <div className="col-12 sp-mt2">
                                <p className="text-label">
                                    Attachments
                                    <span className="sp-required">*</span>
                                </p>
                                <div className="attachment-wrapper">
                                    <div className="grid  -mt-3 -ml-3 -mr-3">
                                        {profile?.attachments?.length ? profile?.attachments?.map(
                                            (fileInfo, ind) => {
                                                return (
                                                    <>
                                                        <ProfileAttachment
                                                            _removefile={
                                                                this._removefile
                                                            }
                                                            isShowRemoveBtn={
                                                                false
                                                            }
                                                            key={"t" + ind}
                                                            imageSrc={
                                                                CLOUDFRONT_ENDPOINT +
                                                                fileInfo.key
                                                            }
                                                            isBlob={false}
                                                        />
                                                    </>
                                                );
                                            }
                                        ) : <p>No attachmentss</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
